<template>
    <div class="login w-100 h-100 d-flex">
        <section class="bg-blur login auth col-12 mx-auto align-self-md-center col-md-7 col-xl-5 col-xxl-4">
            <div class="half-design row">
                <div class="col-12 form-block">
                    <div class="logo col-auto p-0">
                        <img :src="locale === 'en-US' ? require('@/assets/logo_full_en.png')  : require('@/assets/logo_full_he.png')" alt="logo" height="120px" class="mx-auto" />
                    </div>
                    <p class="text-center mt-3">
                        {{ $t('title.welcomeMessage') }}
                    </p>

                    <div class="col-12 col-md-9 mx-auto">
                        <div class="mb-3 text-center" v-if="!submitted" dir="ltr">
                            <label for="Phone" :class="computedState"
                            >{{ $t('title.enterPhoneNumber') }}</label
                            >
                            <vue-tel-input
                                :class="formErrors.has('phone') ? `error-input` : ``"
                                v-model="formFields.phone"
                                mode="national"
                                v-bind="phone.options"
                                :autoFormat="false"
                                validCharactersOnly
                                @focus="resetError"
                                @input="onInput"
                                @keydown.native="checkEnterToSendOtp"
                            />
                            <b-form-invalid-feedback
                                :state="!formErrors.has('phone')">
                                {{ formErrors.first('phone') }}
                            </b-form-invalid-feedback>
                             <b-button type="button" :disabled="isSubmitLogin" @click="sendOtp"
                                      variant="primary"
                                      class="mt-3 mb-3 w-100">
                                {{ $t('title.getCode') }}
                            </b-button>
                        </div>
                        <div v-else-if="!otpVerified" class="mb-3 text-center" dir="ltr">
                            <label>{{ $t('title.enterVerificationCode') }}</label>
                            <div class="d-flex gap-3 text-center">
                                <b-form-input
                                    type="text"
                                    class="form-control base-input text-code"
                                    :class="formErrors.has('otp') ? `error-input` : ``"
                                    v-model="formFields.otp.code1"
                                    :state="otpVerified"
                                    @input.native="e => handleInput('code1', e)"
                                    @keydown.native="e => handleKeyDown('code1', e)"
                                    maxlength="1"
                                />
                                <b-form-input
                                    type="text"
                                    class="form-control base-input text-code"
                                    :class="formErrors.has('otp') ? `error-input` : ``"
                                    v-model="formFields.otp.code2"
                                    :state="otpVerified"
                                    @input.native="e => handleInput('code2', e)"
                                    @keydown.native="e => handleKeyDown('code2', e)"
                                    maxlength="1"
                                />
                                <b-form-input
                                    type="text"
                                    class="form-control base-input text-code"
                                    :class="formErrors.has('otp') ? `error-input` : ``"
                                    v-model="formFields.otp.code3"
                                    :state="otpVerified"
                                    @input.native="e => handleInput('code3', e)"
                                    @keydown.native="e => handleKeyDown('code3', e)"
                                    maxlength="1"
                                />
                                <b-form-input
                                    type="text"
                                    class="form-control base-input text-code"
                                    :class="formErrors.has('otp') ? `error-input` : ``"
                                    v-model="formFields.otp.code4"
                                    :state="otpVerified"
                                    @input.native="e => handleInput('code4', e)"
                                    @keydown.native="e => handleKeyDown('code4', e)"
                                    maxlength="1"
                                />
                            </div>
                            <b-form-invalid-feedback
                                :state="!formErrors.has('otp')">
                                {{ formErrors.first('otp') }}
                            </b-form-invalid-feedback>
                            <b-button type="button" @click.prevent="(isRegister) ? onSubmitRegister() : onSubmit()" :disabled="isSubmitLogin"
                                      ref="submitBtn" variant="primary" class="mt-3 w-100">
                                {{ $t('title.verifyCode') }}
                            </b-button>
                        </div>
                        <div v-else-if="isRegister">
                            <b-form-group :label="$t('title.fullName')" label-for="full-name">
                                <b-form-input v-model="formFields.full_name" id="full-name"
                                              :class="formErrors.has('full_name') ? `error-input` : ``"
                                              required
                                              @input="removeError('full_name')"></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('full_name')">
                                    {{ formErrors.first('full_name') }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-group :label="$t('title.email')" label-for="email">
                                <b-form-input type="email" v-model="formFields.email" id="email"
                                              :class="formErrors.has('email') ? `error-input` : ``"
                                              @input="removeError('email')"></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('email')">
                                    {{ formErrors.first('email') }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-group :label="$t('title.residentialAddress')" label-for="address">
                                <input
                                    type="text"
                                    v-model="formFields.residential_address"
                                    @input="debouncedSearch('residential_address');removeError('residential_address')"
                                    @focus="onFocus('residential_address')"
                                    @blur="handleBlur"
                                    :placeholder="$t('title.searchLocation')"
                                    class="autocomplete-input form-control"
                                />
                                <ul class="autocomplete-list">
                                    <li
                                        v-for="(suggestion, index) in dropdowns.addresses.residential_address"
                                        :key="index"
                                        @click="selectSuggestion(suggestion,'residential_address')"
                                    >
                                        <span v-if="suggestion.id == 'default'">
                                             <b-spinner small variant="primary" type="grow"
                                                        label="Loading..."></b-spinner>
                                        </span>
                                        {{ suggestion.label }}
                                    </li>
                                </ul>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('residential_address')">
                                    {{ formErrors.first('residential_address') }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-button type="button" @click="onFinalSubmit" :disabled="isSubmitLogin" variant="primary"
                                      class="mb-3 w-100">
                                {{ $t('title.send') }}
                            </b-button>
                        </div>
                        <!-- <b-button type="submit" variant="primary" :disabled="isSubmitLogin" class="mb-3 w-100"
                                  @click="submitted ? onSubmit() : sendOtp()" ref="submitBtn">
                            <div class="spinner-border spinner-border-sm text-light" role="status" v-if="loader"></div>
                            {{ submitted ? $t('title.verifyCode') : $t('title.getCode') }}
                        </b-button> -->
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import {getAuthUser, setStorage} from "@/Util/auth";
import {mapGetters} from "vuex";
import {request} from "@/Util/Request";
import Error from "@/Util/Error";
import commonMixin from "@/Util/commonMixin";
import {reactive} from "vue";
import destinationMixin from "@/Util/destinationMixin";

const phone = reactive({
    value: "",
    isBlurred: false,
    inputValue: {
        formatted: "",
        valid: false,
    },
    options: {
        autoFormat: true,
        inputOptions: {
            showDialCode: false,
            showDialCodeInList: true,
        },
        mode: "international",
        validCharactersOnly: true,
    },
});

const FORM_STATE = {
    phone: null,
    disability_id: null,
    full_name: null,
    email: null,
    role_id: null,
    residential_address: null,
    otp: {
        code1: null,
        code2: null,
        code3: null,
        code4: null,
    },
    number: null
};

export default {
    mixins: [commonMixin, destinationMixin],
    data() {
        return {
            isValidP: false,
            submitted: false,
            showError: false,
            otpVerified: null,
            inputOptions: {
                placeholder: 'Enter your phone number',
                autofocus: true,
            },
            formFields: {...FORM_STATE},
            formErrors: new Error({}),
            number: null,
            phone: phone,
            isSubmitLogin: false,
            isRegister: true,
            dropdowns: {
                disabilities: [],
                roles: [],
            },
        };
    },
    mounted() {
        this.checkIpStatus();
        this.getDropdowns();
    },
    computed: {
        computedState() {
            if ( !this.showError ) {
                return 'no-result';
            }
            return this.isValidP ? 'correct' : 'wrong-number';
        },
        ...mapGetters(['user', 'loader', 'locale'])
    },

    methods: {
        async removeError() {
            let removeError = true;
            _.forEach(this.formFields.otp, (otp, key) => {
                if ( otp ) {
                    removeError = false;
                }
            });

            if ( removeError ) {
                this.formErrors = new Error({});
            }
        },
        onInput(formattedNumber, input) {
            this.phone.inputValue = input;
            if ( input && input.nationalNumber ) {
                setTimeout(() => {
                    this.formFields.phone = input.nationalNumber;
                }, 0);
            }
        },
        resetError() {
            this.formErrors = new Error({});
        },
        async checkEnterToSendOtp(event) {
            if ( event.keyCode == 13 || event.key === "Enter" ) {
                this.sendOtp();
            }
        },
        async sendOtp() {
            if ( !this.phone.inputValue.valid ) {
                this.formErrors = new Error({
                    phone: [this.$t('title.enterValidPhoneNumber')]
                });
            } else {
                try {
                    this.isSubmitLogin = true;
                    const response = await request({
                        method: 'post',
                        url: `/volunteer/auth/login`,
                        data: {
                            phone: ( this.phone.inputValue || {} ).number
                        }
                    });

                    let {data} = response;
                    this.isRegister = data.is_register;
                    this.submitted = true;

                    this.notifySuccessWithMsg(this.$t('title.otpSentSuccessMsg'));
                    this.isSubmitLogin = false;

                    this.$nextTick(() => {
                        const firstOtpInput = this.$el.querySelector('.text-code');
                        if ( firstOtpInput ) {
                            firstOtpInput.focus();
                        }
                    });

                    _.map(this.formFields.otp, (value, key) => {
                        this.formFields.otp[key] = null;
                    });

                } catch (error) {
                    this.isSubmitLogin = false;
                    if ( error.request && error.request.status && error.request.status === 422 ) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                        return false;
                    } else {
                        this.notifyError();
                    }
                }
            }
        },
        async onSubmitRegister() {
            try {
                this.isSubmitLogin = true;
                const response = await request({
                    method: 'post',
                    url: `/auth/verify-register-otp`,
                    data: {
                        phone: ( this.phone.inputValue || {} ).number,
                        otp: _.map(this.formFields.otp).join('')
                    }
                });

                this.otpVerified = true;
                console.log(this.isRegister);

                this.notifySuccessWithMsg(this.$t('title.otpVerifiedMsg'));
                this.isSubmitLogin = false;
            } catch (error) {
                this.isSubmitLogin = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        async getDropdowns() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/register`
                });

                const { roles, disabilities } = response;
                this.dropdowns.roles = roles;
                this.dropdowns.disabilities = disabilities;
                this.formFields.role_id = this.getRoleIdByName('Volunteer');
            } catch (error) {
            }
        },
        async onSubmit() {
            try {
                this.isSubmitLogin = true;
                const response = await request({
                    method: 'post',
                    url: `/auth/verify-otp`,
                    data: {
                        phone: ( this.phone.inputValue || {} ).number,
                        otp: _.map(this.formFields.otp).join(''),
                        is_volunteer: true
                    }
                });

                const {data} = response;

                await this.redirectingUserPage(data);

                this.notifySuccessWithMsg(this.$t('title.successLogin'));
                this.isSubmitLogin = false;

            } catch (error) {
                this.isSubmitLogin = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        async onFinalSubmit() {
            try {
                this.isSubmitLogin = true;
                const response = await request({
                    method: 'post',
                    url: `/auth/register`,
                    data: {
                        ...this.formFields,
                        phone: ( this.phone.inputValue || {} ).number,
                        role_id: this.getRoleIdByName('Volunteer'),
                        slug: this.slug
                    }
                });

                const { data } = response;

                await this.$router.push({ name: 'Login' });
                this.notifySuccessWithMsg(this.$t('title.volunteerRegisterMessage'));
                this.isSubmitLogin = false;
            } catch (error) {
                this.isSubmitLogin = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        getRoleIdByName(roleName) {
            const role = this.dropdowns.roles.find((role) => role.label === roleName);
            return role ? role.id : null; // Return the ID if found, otherwise null
        },
        handleInput(codeField, event) {
            const target = event.target;

            if ( !target ) {
                return;
            }
            let value = target.value;

            if ( !/^\d$/.test(value) ) {
                this.formFields.otp[codeField] = '';
                return;
            }

            this.formFields.otp[codeField] = value;

            const nextInput = target.nextElementSibling;
            if ( nextInput && nextInput.tagName === 'INPUT' ) {
                nextInput.focus();
            }
        },
        handleKeyDown(codeField, event) {
            const target = event.target;

            if ( event.key == "Enter" || event.keyCode == 13 ) {
                this.$refs.submitBtn.focus();
                this.$refs.submitBtn.click();
                return;
            }

            // Handle backspace to focus previous field if empty
            if ( event.key === 'Backspace' && !this.formFields.otp[codeField] ) {
                const previousInput = target.previousElementSibling;
                if ( previousInput && previousInput.tagName === 'INPUT' ) {
                    previousInput.focus();
                }
            }
        },
        async checkIpStatus() {
            try {
                const response = await request({
                    method: 'get',
                    url: `/check-ip-status`,
                });

            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                } else {
                    this.notifyError();
                }
            }
        }
    },
    watch: {
        'formFields.otp': {
            handler(newOtpValues) {
                this.removeError();
            },
            deep: true
        }
    },
};
</script>
