<template>
    <div>
        <b-modal v-model="isOpenModal" id="sendMessageModal"  :hide-header="true"
                 :hide-footer="true">
            <div class="col-12 m-auto">
                <h4>{{ $t('title.sendMessage') }}</h4>
                <b-form class="mt-3">
                    <b-row>
                        <div class="col-12 col-md-12">
                            <b-row>
                                <div class="col-12 col-md-12 mt-2">
                                    <label for="message">{{ $t('title.message') }}</label>
                                    <b-form-textarea
                                        :placeholder="$t('title.typeMessage')"
                                        @input="resetError"
                                        id="textarea"
                                        v-model="formFields.message"
                                        rows="8" max-rows="8"></b-form-textarea>
                                    <b-form-invalid-feedback
                                        :state="!formErrors.has('message')">
                                        {{ formErrors.first('message') }}
                                    </b-form-invalid-feedback>
                                </div>
                            </b-row>
                            <b-row>
                                <div class="col-12 col-md-12 mt-2">
                                    <label for="message">{{ $t('title.type') }}</label>
                                    <b-form-radio-group v-model="formFields.type" name="type">
                                        <b-form-radio value="email">
                                            {{ $t('title.email') }}
                                        </b-form-radio>
                                        <b-form-radio value="sms">
                                            {{ $t('title.sms') }}
                                        </b-form-radio>
                                    </b-form-radio-group>
                                    <b-form-invalid-feedback
                                        :state="!formErrors.has('type')">
                                        {{ formErrors.first('type') }}
                                    </b-form-invalid-feedback>
                                </div>
                            </b-row>
                        </div>
                    </b-row>
                </b-form>
            </div>
            <div class="modal-footer d-flex mt-3 mr-2 gap-2">
                <button type="button" :disabled="!formFields.message" class="btn main-btn col m-0" @click="handleSendMessage">
                    {{ $t('title.send') }}
                </button>
                <button type="button" class="btn sec-btn col m-0" @click="cancelModal">
                    {{ $t('title.cancel') }}
                </button>
            </div>
        </b-modal>
        <SendMessageConfirmationModal
            ref="sendMessageConfirmationModal"
            @openSendMessage="handleToggleModal"
            @handleSendMessage="handleSubmitRequest"
        />
    </div>
</template>

<script>
import {request} from "@/Util/Request";
import Error from "@/Util/Error";
import SendMessageConfirmationModal from "@/view/pages/manager/users/modal/SendMessageConfirmationModal";

const FORM_STATE = {
    message: null,
    type: 'email',
    user_ids: []
};

export default {
    data() {
        return {
            isOpenModal: false,
            formFields: { ...FORM_STATE },
            formErrors: new Error({})
        };
    },
    components : {
        SendMessageConfirmationModal
    },
    methods: {
        async handleToggleModal(userIds) {
            this.formFields.message = null;
            try {
                this.formFields.user_ids = userIds;
            } catch (error) {
                this.notifyError();
            }

            this.isOpenModal = !this.isOpenModal;
        },
        async handleSendMessage() {
          this.isOpenModal = false;
          this.$refs['sendMessageConfirmationModal'].handleToggleModal(this.formFields.user_ids);
        },
        async handleSubmitRequest() {
            try {
                this.resetError();
                const response = await request({
                    method: 'post',
                    url: `/admin/users/send-message`,
                    data: this.formFields
                });
                this.notifySuccessWithMsg(this.$t('title.sendSuccessMessageToUser'));
                this.cancelModal();
            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }

        },
        resetError() {
            this.formErrors = new Error({});
        },
        cancelModal() {
            this.refreshList();
            this.isOpenModal = false;
            this.formErrors = new Error({});
            this.formFields = { ...FORM_STATE };
        },
        refreshList() {
            this.$emit('refreshTable');
        }
    }
};
</script>
