<template>
    <div>
        <b-modal v-model="isOpenModal" id="applicationDetail" :hide-header="true" size="xl"
                 :hide-footer="true">
            <div class="col-12">
                <h5>{{ $t('title.detail') }}</h5>
                <div class="table-responsive">
                    <table class="table" v-if="item">
                        <thead>
                            <tr>
                                <th>{{ $t('title.description') }}</th>
                                <th>{{ $t('title.destinationAddress') }}</th>
                                <th>{{ $t('title.originAddress') }}</th>
                                <th>{{ $t('title.systemNotes') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    <div class="three-lines" v-b-popover.hover.top="item.description">
                                        {{ item.description }}
                                    </div>
                                </td>
                                <td>
                                    {{
                                        ( item.destination_address ) ?? ( item.user || {} ).residential_address
                                    }}
                                </td>
                                <td>
                                    {{
                                        ( item.origin_address ) ?? ( item.user || {} ).arrival_address
                                    }}
                                </td>
                                <td>
                                    {{ ( item || {} ).system_notes }}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot v-if="!item">
                            <tr>
                                <td colspan="6">{{ $t('title.noResultFound') }}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="col-12 modal-footer d-flex mr-2 gap-2">
                <button type="button" class="btn sec-btn m-0" @click="cancelModal">
                    {{ $t('title.close') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>

export default {
    data() {
        return {
            isOpenModal: false,
            item: null
        };
    },
    methods: {
        setItemData(item) {
            this.item = item;
            this.isOpenModal = true;
        },
        cancelModal() {
            this.isOpenModal = false;
        },
    }
};
</script>
