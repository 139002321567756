<template>
    <div class="main-block open-volunteering">
        <div class="div col-12 p-0 d-flex align-items-start">
            <h4 class="">{{ $t('title.openVolunteering') }}</h4>
            <b-button
                variant="outline-primary"
                class="gap-1 d-flex align-items-center fs-14 ms-auto position-relative"
                @click="handleOpenFilter">{{ $t('title.filtering') }}
                <b-icon icon="filter" class="icon-style"></b-icon
                >
                <b-badge pill variant="primary" class="position-absolute">{{ cards.length }}<span
                    class="sr-only">{{ $t('title.appliedFilters') }}</span></b-badge>
            </b-button>
        </div>
        <div class="col-12 text-center" v-if="loader">
            <div class="spinner-border spinner-border-sm" role="status"></div>
        </div>
        <div class="scrollable-block">
            <div class="col-12 row mt-3 m-0 p-0" v-if="user">
                <b-card-group columns>
                    <RequestCard v-for="(card, index) in cards" :key="index" :index="index + 1" :item="card"
                                 :userId="user.id" v-on:refresh="getList" />
                </b-card-group>
            </div>
            <div v-else>
                <div class="col-12 text-center row mt-3 m-0 p-0">
                    <h4>
                        {{ $t('title.thereAreNoMoreHelpRequests') }}
                    </h4>
                </div>
            </div>
        </div>
        <b-modal
            id="modal-1"
            :title="$t('title.filtering')"
            :centered="true"
            :ok-title="$t('title.show')"
            :cancel-title="$t('title.cancel')"
            cancel-variant="outline-primary"
            @hidden="resetFilters"
            @ok="getList"
        >
            <b-form @submit.stop.prevent="getList" class="col-12 mx-auto p-0">
                <div class="mb-3">
                    <b-form-group label-for="filter-input" class="mb-0">
                        <b-input-group>
                            <b-form-input
                                id="filter-input"
                                v-model="query.search"
                                type="search"
                                :placeholder="`${$t('title.enterToSearch')}`"
                            ></b-form-input>
                        </b-input-group>
                    </b-form-group>
                </div>

                <div class="mb-3">
                    <label for="filtering">{{ $t('title.region') }}:</label>
                    <treeselect :multiple="false" v-model="filters.state" :options="dropdowns.states"
                                :placeholder="$t('title.select')"
                                id="filtering"></treeselect>
                </div>
                <div class="mb-3">
                    <label for="filtering">{{ $t('title.byCategory') }}:</label>
                    <treeselect :multiple="false" v-model="filters.categories" :options="dropdowns.categories"
                                :placeholder="$t('title.select')"
                                id="filtering"></treeselect>
                </div>
                <!--                <div class="mb-3">-->
                <!--                    <label for="town">{{ $t('title.byTown') }}:</label>-->
                <!--                    <v-select v-model="filters.city" :options="town_options" id="town"></v-select>-->
                <!--                </div>-->
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import RequestCard from '@/components/RequestCard.vue';
import {request} from "@/Util/Request";
import qs from "qs";
import {mapState} from "vuex";

const QUERY = {
    search: ''
};

const FILTER_STATE = {
    categories: null,
    city: null,
    state: null
};

export default {
    components: {
        RequestCard,
    },
    data() {
        return {
            filtering: null,
            query: {...QUERY},
            cards: [],
            dropdowns: {
                categories: [],
                states: []
            },
            filters: {...FILTER_STATE}
        };
    },
    mounted() {
        this.getList();
        this.getDropdowns();
        this.applicationStates();
    },
    methods: {
        resetFilters() {
            this.query.search = ''; // Clear search value when modal closes
            this.filters = {...FILTER_STATE};
        },
        handleOpenFilter() {
            this.filters = {...FILTER_STATE};
            this.$bvModal.show('modal-1');
        },
        async applicationStates() {
            try {
                const response = await request({
                    method: 'get',
                    url: `/volunteer/application/states`,
                });

                const {data} = response;

                this.dropdowns.states = data;

            } catch (error) {
            }
        },
        async getDropdowns() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/application`,
                });

                const {categories} = response;

                this.dropdowns.categories = categories;

            } catch (error) {
            }
        },
        async getList() {
            try {
                const params = {
                    "filters": {
                        ...this.filters,
                    },
                    'search': this.query.search
                };

                const response = await request({
                    method: 'get',
                    url: `/volunteer/application-open-requests`,
                    params: params,
                    paramsSerializer: ( (params) => qs.stringify(params) ),
                });

                const {data} = response;
                this.cards = _.map(data, (i) => {
                    const {location} = i
                    i.destination_address = `${location.street}, ${location.country}, ${location.state}, ${location.city}, ${location.lat}, ${location.lon}`
                    return {
                        ...i,
                    }
                });


            } catch (error) {
                console.log(error)

            }
        },
    },
    computed: {
        ...mapState(['user', 'loader'])
    }
};
</script>
