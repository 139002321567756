<template>
    <div class="w-100 d-flex mt-3" v-if="$global.hasRole('manager')">
        <div class="col-12">
            <div class="col-12 mt-3 text-right">
                <router-link :to="{name:'managerEvent'}" class="btn btn-primary">{{ $t('title.back') }}</router-link>
                <hr />
            </div>
            <b-form @submit.prevent="handleSubmit">
                <b-row>
                    <div class="col-12 col-md-8">
                        <b-row>
                            <div class="col-12 col-md-6">
                                <label for="name">{{ $t('title.name') }}</label>
                                <b-form-input @input="removeError('name')" name="name" v-model="formFields.name"
                                              type="text"></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('name')">
                                    {{ formErrors.first('name') }}
                                </b-form-invalid-feedback>
                            </div>
                            <div class="col-12 col-md-6">
                                <label for="radius">{{ $t('title.radius') }}</label>
                                <b-form-input step="0.1"
                                              type="number" @keypress="allowOnlyNumbers" @input="removeError('radius')"
                                              name="radius" v-model="formFields.radius"
                                ></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('radius')">
                                    {{ formErrors.first('radius') }}
                                </b-form-invalid-feedback>
                            </div>
                        </b-row>
                        <b-row class="mt-3">
                            <div class="col-12">
                                <label for="address">{{ $t('title.address') }}</label>
                                <input
                                    :disabled="formFields.id"
                                    type="text"
                                    v-model="formFields.address"
                                    @input="debouncedSearch('address');removeError('address')"
                                    @focus="onFocus('address')"
                                    @blur="handleBlur"
                                    :placeholder="$t('title.searchAddress')"
                                    class="autocomplete-input form-control"
                                />
                                <ul class="autocomplete-list">
                                    <li
                                        v-for="(suggestion, index) in dropdowns.addresses.address"
                                        :key="index"
                                        @click="selectSuggestion(suggestion,'address')"
                                    >
                                        <span v-if="suggestion.id == 'default'">
                                             <b-spinner small variant="primary" type="grow"
                                                        label="Loading..."></b-spinner>
                                        </span>
                                        {{ suggestion.label }}
                                    </li>
                                </ul>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('address')">
                                    {{ formErrors.first('address') }}
                                </b-form-invalid-feedback>
                            </div>
                        </b-row>
                        <b-row class="mt-3">
                            <div class="col-6 col-xs-12">
                                <label for="is_active" class="fw-medium mb-2 text-grey-input">{{
                                        $t('title.isActive')
                                    }}</label>
                                <b-form-checkbox v-model="formFields.status" id="is_active" />
                            </div>
                            <div class="col-6 col-xs-12">
                                <label for="auto_assign" class="fw-medium mb-2 text-grey-input">{{
                                        $t('title.isAutoAssign')
                                    }}</label>
                                <b-form-checkbox v-model="formFields.is_auto_assign" id="auto_assign" />
                            </div>
                        </b-row>
                        <b-row>
                            <div class="col-12">
                                <hr class="hr-basic mb-0" />
                            </div>
                            <div class="col-12">
                                <div
                                    class="mb-5 mt-2 gap-3 justify-content-start align-items-center px-2 px-lg-0 flex-column flex-md-row"
                                >
                                    <button :disabled="loader" type="submit"
                                            class="btn main-btn fw-semibold col col-lg-auto mw-220">
                                        <span>{{ $t('title.saveAndClose') }}</span>
                                    </button>
                                </div>
                            </div>
                        </b-row>
                    </div>
                </b-row>
            </b-form>
        </div>
    </div>
</template>

<script>
import Error from "@/Util/Error";
import {mapState} from "vuex";
import destinationMixin from "@/Util/destinationMixin";
import {request} from "@/Util/Request";

const DEFAULT_FORM = {
    radius: null,
    name: null,
    address: null,
    status: true,
    is_auto_assign: false
};

export default {
    mixins: [destinationMixin],
    data() {
        return {
            formFields: {...DEFAULT_FORM},
            formErrors: new Error({})
        };
    },
    mounted() {
        if ( this.$route.params && this.$route.params.id ) {
            this.getDetail(this.$route.params.id);
        }
    },
    methods: {
        allowOnlyNumbers(event) {
            // console.log($event.keyCode); //keyCodes value
            let keyCode = ( event.keyCode ? event.keyCode : event.which );

            // only allow number and one dot
            if ( ( keyCode < 48 || keyCode > 57 ) && ( keyCode !== 46 || ( String(this.formFields.radius).indexOf('.') != -1 ) ) ) { // 46 is dot
                event.preventDefault();
            }
        },
        validateInput() {
            const value = this.formFields.radius.trim();
            // Regular expression to check for integers
            this.isValid = /^-?\d+$/.test(value);
            console.log(this.isValid);
        },
        async removeError(key) {
            if ( typeof key === `object` ) {
                for (let i = 0; i < key.length; i++) {
                    this.formErrors.remove(key[i]);
                }
            } else {
                this.formErrors.remove(key);
            }
        },
        async handleSubmit() {
            this.resetError();
            try {
                const response = await request({
                    method: this.formFields.id ? 'put' : 'post',
                    url: this.formFields.id ? `/manager/events/${this.formFields.id}` : `/manager/events`,
                    data: {
                        ...this.formFields,
                    }
                });

                if ( this.formFields.id ) {
                    this.notifySuccessWithMsg(`${this.$t('title.event')} ${this.$t('title.updateSuccessfully')}`);
                } else {
                    this.notifySuccessWithMsg(`${this.$t('title.event')} ${this.$t('title.addSuccessfully')}`);
                }
                this.$router.push({name: 'managerEvent'});

            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        async getDetail(id) {
            try {
                const response = await request({
                    method: 'get',
                    url: `/manager/events/${id}`
                });

                const {data} = response;

                this.formFields = data;

            } catch (error) {
                this.notifyError();
            }
        },
        resetError() {
            this.formErrors = new Error({});
        }
    },
    computed: {
        ...mapState(['loader'])
    }
};
</script>
