<template>
    <div class="col-12 emergency-history">
        <div class="div col-12 p-0 d-flex align-items-start">
            <h4 class="">{{ $t('title.helpRequests') }}</h4>
        </div>
        <div class="scrollable-block">
            <div class="col-12 row mt-3 m-0 p-0" style="min-height: 90%">
                <crud-table :columns="columns" :list-url="listUrl" ref="my-help-requests-table"
                            v-on:refresh="resetFilter"
                            v-on:rowClick="onRowClicked"
                            :filters="filters">
                    <template #cell(volunteer)="{item}">
                        {{ ( ( item.volunteer || {} ).full_name ) ?? 'N/A' }}
                    </template>
                    <template #cell(category)="{item}">
                        {{ ( ( item.category || {} ).name ) ?? 'N/A' }}
                    </template>
                    <template #cell(created_at)="{item}">
                        {{ item.created_at ? $global.utcDateToLocalDate(item.created_at) : null }}
                    </template>
                    <template #cell(arrival_date)="{item}">
                        {{ item.arrival_date }} {{ item.arrival_time }}
                    </template>
                    <template #cell(status)="{item}">
                        {{ $t('title.' + item.status) }}
                    </template>
                </crud-table>
            </div>
        </div>
        <application-detail ref="applicationDetail"></application-detail>
    </div>
</template>

<script>
import ApplicationDetail from "@/view/auth/dashboard/user/modal/applicationDetail";

const FILTER_FORM = {};

const COLUMN_STATE = (self) => [
    {key: 'status', label: self.$t('title.status'), sortable: true},
    {key: 'category', label: self.$t('title.category'), sortable: true},
    {key: 'volunteer', label: self.$t('title.volunteer'), sortable: true},
    {key: 'arrival_date', label: self.$t('title.arrivalTime'), sortable: true},
    {key: 'expiration_date', label: self.$t('title.validity'), sortable: true},
    {key: 'created_at', label: self.$t('title.createdAt'), sortable: true},
];

export default {
    components: {
        ApplicationDetail,
    },
    data() {
        return {
            operation: '',
            listUrl: '/applications',
            filters: {
                ...FILTER_FORM
            },
            userRequestId: ''
        };
    },
    methods: {
        onRowClicked(item) {
            this.$refs.applicationDetail.setItemData(item)
        },
        handleFilter() {
            this.$refs['my-help-requests-table'].handleFilter(this.filters);
        },
        resetFilter() {
            this.filters = {...FILTER_FORM};
        },
        handleCloseRequest() {
            this.$emit('close', this.userRequestId);
            this.$refs['my-help-requests-table'].handleRefreshList();
        },
        openModalWithId(id) {
            this.userRequestId = id;
            this.$bvModal.show('event-user-request-close');
        },
        closeModal() {
            this.$bvModal.hide('event-user-request-close');
        },
        refreshTable() {
            console.log("refresh table called...");
            this.$refs['my-help-requests-table'].handleRefreshList();
        }
    },
    computed: {
        columns() {
            return COLUMN_STATE(this);
        }
    }
};
</script>
