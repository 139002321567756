<template>
    <div class="w-100 d-flex mt-3">
        <div class="col-12">
            <div class="col-12 mt-3 text-right">
                <router-link :to="{name:'AdminUserManagement'}" class="btn btn-primary">{{
                        $t('title.back')
                    }}
                </router-link>
                <hr />
            </div>
            <b-form @submit.prevent="handleSubmit">
                <b-row>
                    <div class="col-12 col-md-6">
                        <label for="full_name">{{ $t('title.fullName') }}</label>
                        <b-form-input :class="formErrors.has('full_name') ? `error-input` : null" name="full_name"
                                      v-model="formFields.full_name" @input="resetError"
                                      type="text"></b-form-input>
                        <b-form-invalid-feedback
                            :state="!formErrors.has('full_name')">
                            {{ formErrors.first('full_name') }}
                        </b-form-invalid-feedback>
                    </div>
                    <div class="col-12 col-md-6">
                        <label for="email">{{ $t('title.email') }}</label>
                        <b-form-input :class="formErrors.has('email') ? `error-input` : null" name="email"
                                      v-model="formFields.email" @input="resetError"
                                      type="email"></b-form-input>
                        <b-form-invalid-feedback
                            :state="!formErrors.has('email')">
                            {{ formErrors.first('email') }}
                        </b-form-invalid-feedback>
                    </div>
                </b-row>
                <b-row class="mt-3">
                    <div class="col-6 col-md-6">
                        <label for="phone">{{ $t('title.phone') }}</label>
                        <vue-tel-input
                            :class="formErrors.has('phone') ? `error-input` : ``"
                            v-model="formFields.phone"
                            v-bind="phone.options"
                            @input="onInput"
                            @focus="resetError"
                            required
                        />
                        <b-form-invalid-feedback
                            :state="!formErrors.has('phone')">
                            {{ formErrors.first('phone') }}
                        </b-form-invalid-feedback>
                    </div>
                    <div class="col-12 col-md-6">
                        <label for="role">{{ $t('title.role') }}</label>
                        <treeselect :class="formErrors.has('role_id') ? `error-input` : ``" v-model="formFields.role_id"
                                    class="form-input" :options="dropdowns.roles"
                                    :placeholder="$t('title.select')" />
                        <b-form-invalid-feedback
                            :state="!formErrors.has('role_id')">
                            {{ formErrors.first('role_id') }}
                        </b-form-invalid-feedback>
                    </div>
                </b-row>
                <b-row class="mt-3">
                    <div class="col-12">
                        <div class="d-flex">
                            <label for="residential_address">{{ $t('title.residentialAddress') }}</label>
                            <b-form-radio-group class="ml-2" v-model="formFields.address_type" name="address_type">
                                <b-form-radio value="residential">
                                </b-form-radio>
                            </b-form-radio-group>
                        </div>
                        <input
                            type="text"
                            v-model="formFields.residential_address"
                            @input="debouncedSearch('residential_address')"
                            @focus="onFocus('residential_address')"
                            @blur="handleBlur"
                            :placeholder="$t('title.searchLocation')"
                            class="autocomplete-input form-control"
                        />
                        <ul class="autocomplete-list">
                            <li
                                v-for="(suggestion, index) in dropdowns.addresses.residential_address"
                                :key="index"
                                @click="selectSuggestion(suggestion,'residential_address')"
                            >
                                        <span v-if="suggestion.id == 'default'">
                                             <b-spinner small variant="primary" type="grow"
                                                        label="Loading..."></b-spinner>
                                        </span>
                                {{ suggestion.label }}
                            </li>
                        </ul>
                        <b-form-invalid-feedback
                            :state="!formErrors.has('residential_address')">
                            {{ formErrors.first('residential_address') }}
                        </b-form-invalid-feedback>
                    </div>
                    <div class="col-12 mt-3">
                        <div class="d-flex">
                            <label for="arrival_address">{{ $t('title.workAddress') }}</label>
                            <b-form-radio-group class="ml-2" v-model="formFields.address_type" name="address_type">
                                <b-form-radio value="arrival">
                                </b-form-radio>
                            </b-form-radio-group>
                        </div>
                        <input
                            type="text"
                            v-model="formFields.arrival_address"
                            @input="debouncedSearch('arrival_address');resetError"
                            @focus="onFocus('arrival_address')"
                            @blur="handleBlur"
                            :placeholder="$t('title.searchLocation')"
                            class="autocomplete-input form-control"
                        />
                        <ul class="autocomplete-list">
                            <li
                                v-for="(suggestion, index) in dropdowns.addresses.arrival_address"
                                :key="index"
                                @click="selectSuggestion(suggestion,'arrival_address')"
                            >
                                        <span v-if="suggestion.id == 'default'">
                                            <b-spinner small variant="primary" type="grow"
                                                       label="Loading..."></b-spinner>
                                        </span>
                                {{ suggestion.label }}
                            </li>
                        </ul>

                        <b-form-invalid-feedback
                            :state="!formErrors.has('arrival_address')">
                            {{ formErrors.first('arrival_address') }}
                        </b-form-invalid-feedback>
                    </div>
                    <div class="col-12 mt-3">
                        <label>
                            {{ $t('title.wayForCommunication') }}
                        </label>
                    </div>
                    <div class="col-3">
                        <b-form-checkbox
                            id="checkbox-1"
                            v-model="formFields.is_phone"
                            name="checkbox-1"
                            value="1"
                            unchecked-value="0"
                        >
                            {{ $t('title.phone') }}
                        </b-form-checkbox>
                        <b-form-invalid-feedback
                            :state="!formErrors.has('is_phone')">
                            {{ formErrors.first('is_phone') }}
                        </b-form-invalid-feedback>
                    </div>
                    <div class="col-3">
                        <b-form-checkbox
                            id="checkbox-2"
                            v-model="formFields.is_wp"
                            name="checkbox-2"
                            value="1"
                            unchecked-value="0"
                        >
                            {{ $t('title.whatsApp') }}
                        </b-form-checkbox>
                        <b-form-invalid-feedback
                            :state="!formErrors.has('is_wp')">
                            {{ formErrors.first('is_wp') }}
                        </b-form-invalid-feedback>
                    </div>
                    <div class="col-3">
                        <b-form-checkbox
                            id="checkbox-3"
                            v-model="formFields.is_active"
                            name="checkbox-3"
                            value="1"
                            unchecked-value="0"
                        >
                            {{ $t('title.isActive') }}
                        </b-form-checkbox>
                        <b-form-invalid-feedback
                            :state="!formErrors.has('is_active')">
                            {{ formErrors.first('is_active') }}
                        </b-form-invalid-feedback>
                    </div>
                </b-row>
                <b-row>
                    <div class="col-12">
                        <hr class="hr-basic mb-0" />
                    </div>
                    <div class="col-12">
                        <div
                            class="mb-5 mt-2 gap-3 justify-content-start align-items-center px-2 px-lg-0 flex-column flex-md-row"
                        >
                            <button :disabled="loader" type="submit"
                                    class="btn main-btn fw-semibold col col-lg-auto mw-220">
                                <span>{{ $t('title.saveAndClose') }}</span>
                            </button>
                        </div>
                    </div>
                </b-row>
            </b-form>
        </div>
    </div>
</template>

<script>
import {request} from "@/Util/Request";
import {mapGetters} from "vuex";
import Error from "@/Util/Error";
import Treeselect from "@riophae/vue-treeselect";
import '@riophae/vue-treeselect/dist/vue-treeselect.css';
import {reactive} from "vue";
import destinationMixin from "@/Util/destinationMixin";

const DEFAULT_FORM_STATE = {
    full_name: null,
    email: null,
    phone: null,
    role_id: null,
    residential_address: null,
    arrival_address: null,
    disability_id: null,
    is_phone: 0,
    is_wp: 0,
    address_type: 'residential',
    is_active: false,
};

const phone = reactive({
    value: "",
    isBlurred: false,
    inputValue: {
        formatted: "",
        valid: false,
    },
    options: {
        autoFormat: true,
        inputOptions: {
            showDialCode: false,
            showDialCodeInList: true,
        },
        mode: "international",
        validCharactersOnly: true,
    },
});

export default {
    mixins: [destinationMixin],
    components: {Treeselect},
    data() {
        return {
            dropdowns: {
                roles: [],
            },
            formFields: {...DEFAULT_FORM_STATE},
            formErrors: new Error({}),
            phone: phone,
        };
    },
    mounted() {
        this.getDropdowns();
    },
    methods: {
        onInput(formattedNumber, input) {
            this.phone.inputValue = input;
            if ( input && input.nationalNumber ) {
                setTimeout(() => {
                    this.formFields.phone = this.$global.displayPhoneNumber(input.nationalNumber);
                }, 0);
            }
        },
        resetError() {
            this.formErrors = new Error({})
        },
        async handleSubmit() {
            this.resetError();
            if ( !this.phone.inputValue.valid ) {
                this.formErrors = new Error({
                    phone: [this.$t('title.enterValidPhoneNumber')]
                });
            } else {
                try {
                    if ( this.formFields.id ) {
                        this.formFields._method = 'PUT';
                    }

                    const response = await request({
                        method: 'post',
                        url: this.formFields.id ? `/admin/users/${this.formFields.id}` : `/admin/users`,
                        data: {
                            ...this.formFields,
                            phone: ( this.phone.inputValue || {} ).number,
                        }
                    });

                    this.notifySuccess(this.$t('title.user'));

                    this.handleCancel();

                } catch (error) {
                    this.notifyError();

                    if ( error.request && error.request.status && error.request.status === 422 ) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                        return false;
                    }
                }
            }
        },
        async getDropdowns() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/roles`,
                    data: {
                        type: 'manual_registration'
                    }
                })

                this.dropdowns.roles = _.filter(response, (i) => i.label && _.includes([_.upperFirst('manager'), _.upperFirst('admin')], i.label))

            } catch (error) {
            }
        },
        handleCancel() {
            this.formFields = {...DEFAULT_FORM_STATE};
            this.$router.push({name: 'AdminUserManagement'});
        }
    },
    computed: {
        ...mapGetters(['loader'])
    }
};
</script>
