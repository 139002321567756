<template>
    <div class="chat-container">
        <ul class="chat-box chatContainerScroll">
            <li
                v-for="(message, index) in messages"
                :key="index"
                class="chat-right"
            >
                <div class="chat-text">{{ message.message }}</div>
                <div class="chat-hour">
                    {{ message.time }}
                    <span class="fa fa-check-circle"></span>
                </div>
                <div class="chat-avatar">
                    <b-avatar :size="30"></b-avatar>
                    <div class="chat-name">{{ message.sender.full_name }}</div>
                </div>
            </li>
        </ul>
        <div class="form-group mt-3 mb-0 d-flex gap-2 align-items-end">
      <textarea
          class="form-control"
          rows="3"
          :placeholder="$t('title.typeYourMessageHear')"
          v-model="formFields.sms"
          @keyup.enter="sendMessage"
          @input="removeError('sms')"
      ></textarea>
            <b-button
                type="button"
                :disabled="isSubmit"
                variant="primary"
                class="btn sec-btn small-btn btn-primary rounded border-0"
                @click="sendMessage"
            >
                <svg
                    xmlns="http://www.w3.org/2000/svg"
                    width="16"
                    height="16"
                    fill="currentColor"
                    class="bi bi-send"
                    viewBox="0 0 16 16"
                >
                    <path
                        d="M15.854.146a.5.5 0 0 1 .11.54l-5.819 14.547a.75.75 0 0 1-1.329.124l-3.178-4.995L.643 7.184a.75.75 0 0 1 .124-1.33L15.314.037a.5.5 0 0 1 .54.11ZM6.636 10.07l2.761 4.338L14.13 2.576zm6.787-8.201L1.591 6.602l4.339 2.76z"
                    />
                </svg>
            </b-button>
        </div>
        <b-form-invalid-feedback
            :state="!formErrors.has('sms')">
            {{ formErrors.first('sms') }}
        </b-form-invalid-feedback>
    </div>
</template>

<script>
import {request} from "@/Util/Request";
import Error from "@/Util/Error";
import {mapGetters, mapState} from "vuex";

const FORM_STATE = {
    sms: null,
    category_id: null,
    receiver_id: null
};

export default {
    props: {
        categoryUser: {
            type: Object,
            required: true
        },
        categoryId: {
            type: Number,
            required: true
        }
    },
    data() {
        return {
            newMessage: '',
            formFields: {...FORM_STATE},
            formErrors: new Error({}),
            isSubmit: false,
            messages: []
        };
    },
    mounted() {
        this.getSMSMessage();
    },
    computed: {
        ...mapGetters(['user']),
    },
    methods: {
        async getSMSMessage() {
            try {
                const response = await request({
                    url: `/chats/categories`,
                    method: 'get',
                    params: {
                        category_id: this.categoryId,
                        receiver_id: this.categoryUser.id,
                    }
                });

                const {data} = response;
                this.messages = data;
            } catch (error) {
                this.notifyError();
            }
        },
        async sendMessage() {
            try {
                this.isSubmit = true;
                //temporory display the message
                if ( this.formFields.sms ) {
                    this.messages.push({
                        sender: {
                            full_name: this.user.full_name
                        },
                        message: this.formFields.sms,
                        time: new Date().toLocaleTimeString('en-US', {
                            hour: '2-digit',
                            minute: '2-digit',
                            hour12: false
                        }),
                    });
                }

                this.formFields.category_id = this.categoryId;
                this.formFields.receiver_id = this.categoryUser.id;
                const response = await request({
                    method: 'post',
                    url: `/chats`,
                    data: this.formFields
                });
                this.notifySuccessWithMsg(this.$t('title.sendSMSSuccessMessage'));
                this.formFields.sms = '';
                setTimeout(() => {
                    this.isSubmit = false;
                }, 2000);
            } catch (error) {
                console.log(error)
                this.isSubmit = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }
        },
        async removeError(key) {
            if ( typeof key === `object` ) {
                for (let i = 0; i < key.length; i++) {
                    this.formErrors.remove(key[i]);
                }
            } else {
                this.formErrors.remove(key);
            }
        },
    },
};
</script>
