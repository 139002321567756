<template>
    <div class="w-100 d-flex mt-3" v-if="$global.hasRole('admin')">
        <div class="col-12">
            <!-- <div class="col-12 mt-3 text-right">
                <router-link :to="{name:'adminEvent'}" class="btn btn-primary">{{ $t('title.back') }}</router-link>
                <hr />
            </div> -->
            <b-form @submit.prevent="handleSubmit">
                <b-row>
                    <div class="col-12 col-md-8">
                        <b-row>
                            <div class="col-6 col-md-6">
                                <label for="radius">{{ $t('title.expandSearchRadius') }} ({{ $t('title.km') }})</label>
                                <b-form-input @keypress="allowOnlyNumbers" name="radius" v-model="formFields.radius"
                                              @input="removeError('radius')"></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('radius')">
                                    {{ formErrors.first('radius') }}
                                </b-form-invalid-feedback>
                            </div>
                            <div class="col-6 col-md-6">
                                <label for="time">{{ $t('title.ifNoResponseCallTheVolunteerAfter') }} ({{ $t('title.minutes') }})</label>
                                <b-form-input @keydown="allowOnlyIntegers" name="time" v-model="formFields.time"
                                              @input="removeError('time')"></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('time')">
                                    {{ formErrors.first('time') }}
                                </b-form-invalid-feedback>
                            </div>
                        </b-row>
                        <b-row>
                            <div class="col-12">
                                <hr class="hr-basic mb-0" />
                            </div>
                            <div class="col-12">
                                <div
                                    class="mb-5 mt-2 gap-3 justify-content-start align-items-center px-2 px-lg-0 flex-column flex-md-row"
                                >
                                    <button :disabled="loader || isSubmit" type="submit"
                                            class="btn main-btn fw-semibold col col-lg-auto mw-220">
                                        <span>{{ $t('title.save') }}</span>
                                    </button>
                                </div>
                            </div>
                        </b-row>
                    </div>
                </b-row>
            </b-form>
        </div>
    </div>
</template>

<script>
import Error from "@/Util/Error";
import { mapState } from "vuex";
import destinationMixin from "@/Util/destinationMixin";
import { request } from "@/Util/Request";

const DEFAULT_FORM = {
    id: null,
    radius: null,
    time: null
};

export default {
    mixins: [destinationMixin],
    data() {
        return {
            formFields: { ...DEFAULT_FORM },
            formErrors: new Error({}),
            dropdowns: {
                managers: []
            },
            isSubmit: false
        };
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        allowOnlyNumbers(event) {
            // console.log($event.keyCode); //keyCodes value
            let keyCode = (event.keyCode ? event.keyCode : event.which);

            // only allow number and one dot
            if ((keyCode < 48 || keyCode > 57) && (keyCode !== 46 || (String(this.formFields.radius).indexOf('.') != -1))) { // 46 is dot
                event.preventDefault();
            }
        },
        allowOnlyIntegers(event) {
            const charCode = event.which || event.keyCode;

            // Prevent decimal point (.)
            if (charCode === 46) {
                event.preventDefault();
            }

            // Allow numbers (0-9), backspace (8), delete (46), arrow keys (37-40), and negative sign (-)
            if (
                !(
                (charCode >= 48 && charCode <= 57) || // 0-9
                charCode === 8 || // Backspace
                charCode === 37 || // Left arrow
                charCode === 39 || // Right arrow
                charCode === 46 || // Delete
                (charCode === 45 && this.userInput.length === 0) // Negative sign at the start
                )
            ) {
                event.preventDefault();
            }
        },
        validateInput() {
            const value = this.formFields.radius.trim();
            // Regular expression to check for integers
            this.isValid = /^-?\d+$/.test(value);
            console.log(this.isValid);
        },
        async removeError(key){
            if ( typeof key === `object` ) {
                for (let i = 0; i < key.length; i++) {
                    this.formErrors.remove(key[i]);
                }
            } else {
                this.formErrors.remove(key);
            }
        },
        handleOutsideClick(event) {
            const isOutside = !event.target.closest('.autocomplete-input') &&
                !event.target.closest('.autocomplete-list');
            if ( isOutside ) {
                Object.keys(this.dropdowns.addresses).forEach(key => {
                    this.dropdowns.addresses[key] = [];
                });
            }
        },
        async handleSubmit() {
            this.resetError();
            try {
                this.isSubmit = true;
                const response = await request({
                    method: 'post',
                    url: `/admin/auto-assigns`,
                    data: {
                        ...this.formFields,
                    }
                });

                this.getDetail();
                this.notifySuccessWithMsg(`${ this.$t('title.autoAssign') } ${ this.$t('title.updateSuccessfully') }`);
                this.isSubmit = false;

            } catch (error) {
                this.isSubmit = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        async getDetail() {
            try {
                const response = await request({
                    method: 'get',
                    url: `/admin/auto-assigns`
                });

                const { data } = response;

                if(data) {
                    this.formFields = {
                        ...data[0] 
                    };
                }
                
            } catch (error) {
                this.notifyError();
            }
        },
        resetError() {
            this.formErrors = new Error({});
        }
    },
    computed: {
        ...mapState(['loader'])
    }
};
</script>
