<template>
    <div>
        <div class="filter-container ms-auto my-2 col-12 z-index-10000">
            <div class="d-flex flex-wrap gap-2">
                <div>
                    <label for="fromDate">{{ $t('title.fromDate') }}:</label>
                    <datetime
                        type="date"
                        input-id="from_date"
                        name="from_date"
                        v-model="filters.from_date"
                        :auto="true"
                        :format="{year: 'numeric', month: 'numeric',day: 'numeric'}"
                        class="vdatetime"
                        :phrases="{ ok: $t('title.ok'), cancel: $t('title.close')}"
                    ></datetime>
                </div>
                <div>
                    <label for="toDate">{{ $t('title.toDate') }}:</label>
                    <datetime
                        type="date"
                        input-id="to_date"
                        name="to_date"
                        v-model="filters.to_date"
                        :auto="true"
                        :min-datetime="new Date(filters.from_date).toISOString()"
                        :format="{year: 'numeric', month: 'numeric',day: 'numeric'}"
                        class="vdatetime"
                        :phrases="{ ok: $t('title.ok'), cancel: $t('title.close')}"
                    ></datetime>
                </div>
                <div class="align-self-end">
                    <b-button size="sm" variant="primary" @click="getDetail">{{ $t('title.show') }}</b-button>
                </div>
            </div>


        </div>
        <div class="map-events d-flex flex-column flex-sm-row col-12 gap-2">
            <CounterBlock :count="active_events" :label="$t('title.activeEvents')" />
            <CounterBlock :count="deactive_events" :label="$t('title.deActiveEvents')" />
        </div>
    </div>
</template>

<script>

import { request } from "@/Util/Request";
import moment from "moment-timezone";
import CounterBlock from "@/components/CounterBlock";

export default {
    components: {
        CounterBlock
    },
    data() {
        return {
            filters: {
                from_date: null,
                to_date: null
            },
            active_events: 0,
            deactive_events: 0
        };
    },
    mounted() {
        this.filters.from_date = moment().subtract(30, 'days').format("YYYY-MM-DD");
        this.filters.to_date = moment().format("YYYY-MM-DD");
        this.getDetail();
    },
    methods: {

        async getDetail() {
            try {
                const response = await request({
                    method: 'get',
                    params: {
                        from_date: this.filters.from_date,
                        to_date: this.filters.to_date,
                    },
                    url: `/manager/dashboard/count/box`,
                });

                const { data } = response;

                const { active_events, deactive_events } = data.count;

                this.active_events = active_events;
                this.deactive_events = deactive_events;

            } catch (error) {
                this.notifyError();
            }
        }
    }
};
</script>
