<template>
    <div class="main-block request-management">
        <!--        <div class="div col-12 p-0 d-flex align-items-start">-->
        <!--            <h4 class="">{{ $t('title.volunteesrsManagement') }}</h4>-->
        <!--        </div>-->
        <div class="scrollable-block">
            <div class="col-12 row m-0 p-0" style="min-height: 90%">
                <crud-table :columns="columns" :list-url="listUrl" ref="application-table" v-on:refresh="resetFilter"
                            v-on:rowClick="onRowClicked">
                    <template #filter-slot>
                        <b-col lg="3" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.category')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" :placeholder="$t('title.select')" @input="handleFilter"
                                            v-model="filters.categories"
                                            class="form-input"
                                            :options="dropdowns.categories" />
                            </b-form-group>
                        </b-col>
                        <b-col lg="3" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.status')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" :placeholder="$t('title.select')"
                                            @input="handleFilter" v-model="filters.statuses"
                                            class="form-input"
                                            :options="dropdowns.statuses" />
                            </b-form-group>
                        </b-col>
                    </template>
                    <template>
                        <b-button :disabled="loader" variant="primary" @click="exportData">
                            <i class="fa fa-file"></i> {{ $t('title.export') }}
                        </b-button>
                    </template>
                    <template #cell(userCare)="{item}">
                        <a href="#" @click.prevent="openVolunteerDetailModal(item)">{{
                                ( item.volunteer || {} ).full_name
                            }}</a>
                    </template>
                    <template #cell(label_priority)="{item}">
                        <span :class="getPriorityClass(item.label_priority)"
                              v-if="item.application && item.application.expiration_date">

                                {{ getLabel(item.label_priority) }}
                        </span>
                    </template>
                    <template #cell(status)="{item}">
                        <span v-if="item.is_approved == 2 && item.volunteer">
                            {{ $t('title.rejected') }}
                        </span>
                        <span v-else>
                            {{ $t(`title.${_.camelCase(item.application.status)}`) }}
                        </span>
                    </template>
                    <template #cell(destination_address)="{item}">
                        {{
                            ( item.application.destination_address ) ?? ( item.application.user || {} ).residential_address
                        }}
                    </template>
                    <template #cell(origin_address)="{item}">
                        {{ ( item.application.origin_address ) ?? ( item.application.user || {} ).arrival_address }}
                    </template>
                    <template #cell(category)="{item}">
                        {{ ( item.application.category || {} ).name }}
                    </template>
                    <template #cell(name)="{item}">
                        <a href="#" @click.prevent="openUserDetailModal(item.application)">{{
                                ( item.application.user || {} ).full_name
                            }}</a>
                    </template>
                    <template #cell(disability)="{item}">
                        {{ ( item.application.user?.disability || {} ).name }}
                    </template>
                    <template #cell(creation_date)="{item}">
                        {{ $global.utcDateToLocalDate(item.application.created_at) }}
                    </template>
                    <template #cell(validity)="{item}">
                        {{ item.application.expiration_date }}
                    </template>
                    <template #cell(actions)="{item}">
                        <b-button v-if="item.is_approved == 0 && item.volunteer && item.application.status == 'open'"
                                  v-b-popover.hover.top="$t('title.accept')"
                                  variant="outline-primary"
                                  :disabled="loader"
                                  class="mr-1" @click="onSubmit(item);">
                            <b-icon icon="shield-check" class="icon-style"></b-icon>
                        </b-button>
                        <b-button
                            v-if="item.volunteer && item.application.status == 'in treatment' && item.is_approved != 2"
                            v-b-popover.hover.top="$t('title.close')"
                            variant="outline-primary"
                            :disabled="loader"
                            class="mr-1" @click="openCloseRequestConfirmationModal(item.application.id);">
                            <b-icon icon="x-circle-fill" class="icon-style"></b-icon>
                        </b-button>
                    </template>
                </crud-table>
            </div>
        </div>
        <VolunteerDetailModelCategory ref="volunteerDetailModal" />
        <UserDetailModelCategory ref="userDetailModal" />
        <ReuqestClosedConfirmationModel ref="reuqestClosedConfirmationModal" @refresh="refreshTable" />
        <RequestApprovalUserDetailModal ref="requestApprovalUserDetailModal" />
    </div>
</template>

<script>

import {request} from "@/Util/Request";
import {mapState} from "vuex";
import Error from "@/Util/Error";
import VolunteerDetailModelCategory from "@/view/pages/admin/requestApproval/modal/VolunteerDetailModelCategory"
import UserDetailModelCategory from "@/view/pages/admin/requestApproval/modal/UserDetailModelCategory"
import ReuqestClosedConfirmationModel from "@/view/pages/admin/requestApproval/modal/ReuqestClosedConfirmationModel"
import RequestApprovalUserDetailModal from "@/view/pages/admin/modal/RequestApprovalUserDetailModal";

const FILTER_FORM = {
    roles: null,
    statuses: null
};

const COLUMN_STATE = (self) => [
    {key: 'actions', label: self.$t('title.actions')},
    {key: 'category', label: self.$t('title.category'), sortable: true},
    {key: 'status', label: self.$t('title.status'), sortable: true},
    {key: 'label_priority', label: self.$t('title.urgency'), sortable: true},
    {key: 'userCare', label: self.$t('title.inUserCare'), sortable: true},
    {key: 'validity', label: self.$t('title.validity'), sortable: true},
    {key: 'creation_date', label: self.$t('title.creationDate'), sortable: true},
    {key: 'name', label: self.$t('title.fullName'), sortable: true},
    {key: 'disability', label: self.$t('title.disability'), sortable: true},
];

export default {
    data() {
        return {
            operation: '',
            listUrl: '/admin/application-users',
            filters: {
                ...FILTER_FORM
            },
            dropdowns: {
                categories: [],
                statuses: []
            },
            formErrors: new Error({}),
        };
    },
    components: {
        VolunteerDetailModelCategory,
        UserDetailModelCategory,
        ReuqestClosedConfirmationModel,
        RequestApprovalUserDetailModal
    },
    mounted() {
        this.getDropdowns();
    },
    methods: {
        getPriorityClass(priority) {
            return {
                'text-dark': priority == 0,
                'text-info': priority == 1,
                'text-danger': priority == 3,
                'text-warning': priority == 2,
            };
        },
        getLabel(priority) {
            return priority == '1' ? this.$t('title.low') : ( priority == '2' ? this.$t('title.medium') : ( priority == '0' ) ? this.$t('title.expired') : this.$t('title.high') );
        },
        async exportData() {
            this.$refs['application-table'].handleExport(this.filters, 'Single help requests');
        },
        onRowClicked(item) {
            this.$refs.requestApprovalUserDetailModal.setItemData(item)
        },
        openVolunteerDetailModal(volunteer) {
            this.$refs['volunteerDetailModal'].handleToggleModal(volunteer);
        },
        openUserDetailModal(user) {
            this.$refs['userDetailModal'].handleToggleModal(user);
        },
        openCloseRequestConfirmationModal(applicationId) {
            this.$refs['reuqestClosedConfirmationModal'].handleToggleModal(applicationId);
        },
        async onSubmit(item) {

            try {
                const response = await request({
                    method: 'patch',
                    url: `/admin/application-users/${item.id}`,
                    data: {
                        'is_approved': 1
                    }
                });

                this.notifySuccessWithMsg(this.$t('title.volunteer') + ' ' + this.$t('title.acceptRequestSuccess'));

                this.$refs['application-table'].refreshTableData();

            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);

                    if ( this.formErrors.has('error') ) {
                        this.notifyErrorWithMsg(this.formErrors.first('error'));
                    }
                    return false;
                } else {
                    this.notifyError();
                }
            }

        },
        refreshTable() {
            this.$refs['application-table'].handleRefreshList();
        },
        handleFilter() {
            this.$refs['application-table'].handleFilter(this.filters);
        },
        resetFilter() {
            this.filters = {...FILTER_FORM};
        },
        async getDropdowns() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/application`,
                });

                const {categories, statuses} = response;

                this.dropdowns.categories = categories;
                this.dropdowns.statuses = _.map(statuses, (i) => {
                    return {
                        id: i.id,
                        label: this.$t(`title.${_.camelCase(i.label)}`)
                    };
                });

            } catch (error) {
            }
        },
    },
    computed: {
        columns() {
            return COLUMN_STATE(this);
        },
        ...mapState(['loader'])
    }
};
</script>
