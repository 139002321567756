<template>
    <div class="register w-100 h-100 d-flex">
        <section class="bg-blur auth col-12 mx-auto align-self-md-center col-md-7 col-xl-5 col-xxl-4">
            <div class="half-design row">
                <div class="col-12 form-block">
                    <div class="logo col-auto p-0 d-flex justify-content-center align-items-center">
                        <div v-if="isLoading" class="spinner-border spinner-border-sm mx-auto"></div>
                        <img v-else-if="organization && organization.logo" :src="getImageUrl(organization.logo)" alt="logo" height="120px" class="mx-auto w-100">
                        <img v-else :src="locale === 'en-US' ? require('@/assets/logo_full_en.png')  : require('@/assets/logo_full_he.png')" height="120px" class="mx-auto" />
                    </div>
                    <h6 class="text-center mt-3" v-if="organization && organization.name">{{ organization.name }}</h6>
                    <p class="text-center mt-3">
                        {{ $t('title.registerToHelp') }}<br />
                        {{ $t('title.joinUs') }}
                    </p>

                    <div class="col-12 col-md-9 mx-auto">
                        <h5 class="text-center mb-3">{{$t('title.registerAs')}} {{$t('title.volunteer')}}</h5>
                        <div class="mb-3" v-if="!phoneVerified" dir="ltr">
                            <label for="Phone">{{ $t('title.enterPhoneNumber') }}</label
                            >
                            <vue-tel-input
                                :class="formErrors.has('phone') ? `error-input` : ``"
                                v-model="formFields.phone"
                                mode="international"
                                v-bind="phone.options"
                                :autoFormat="false"
                                validCharactersOnly
                                @focus="resetError"
                                @input="onInput"
                                @keydown.native="checkEnterToSendOtp"
                            />
                            <b-form-invalid-feedback
                                :state="!formErrors.has('phone')">
                                {{ formErrors.first('phone') }}
                            </b-form-invalid-feedback>
                            <b-button type="button" :disabled="isSubmitLoading" @click="onSubmitRegistration"
                                      variant="primary"
                                      class="mt-3 mb-3 w-100">
                                {{ $t('title.getCode') }}
                            </b-button>
                            <p class="text-ship-grey text-center mt-1">
                                {{ $t('title.alreadyHavingAccount') }}
                                <router-link :to="{name:'Login'}"
                                             class="text-main text-decoration-none">{{ $t('title.signIn') }}
                                </router-link>
                            </p>
                        </div>

                        <div v-else-if="!otpVerified" class="mb-3 text-center" dir="ltr">
                            <label>{{ $t('title.enterVerificationCode') }}</label>
                            <div class="d-flex gap-3 text-center">
                                <b-form-input
                                    type="text"
                                    class="form-control base-input text-code"
                                    :class="formErrors.has('otp') ? `error-input` : ``"
                                    v-model="formFields.otp.code1"
                                    :state="otpVerified"
                                    @input.native="e => handleInput('code1', e)"
                                    @keydown.native="e => handleKeyDown('code1', e)"
                                    @focus.native="removeError()"
                                    maxlength="1"
                                />
                                <b-form-input
                                    type="text"
                                    class="form-control base-input text-code"
                                    :class="formErrors.has('otp') ? `error-input` : ``"
                                    v-model="formFields.otp.code2"
                                    :state="otpVerified"
                                    @input.native="e => handleInput('code2', e)"
                                    @keydown.native="e => handleKeyDown('code2', e)"
                                    @focus.native="removeError()"
                                    maxlength="1"
                                />
                                <b-form-input
                                    type="text"
                                    class="form-control base-input text-code"
                                    :class="formErrors.has('otp') ? `error-input` : ``"
                                    v-model="formFields.otp.code3"
                                    :state="otpVerified"
                                    @input.native="e => handleInput('code3', e)"
                                    @keydown.native="e => handleKeyDown('code3', e)"
                                    @focus.native="removeError()"
                                    maxlength="1"
                                />
                                <b-form-input
                                    type="text"
                                    class="form-control base-input text-code"
                                    :class="formErrors.has('otp') ? `error-input` : ``"
                                    v-model="formFields.otp.code4"
                                    :state="otpVerified"
                                    @input.native="e => handleInput('code4', e)"
                                    @keydown.native="e => handleKeyDown('code4', e)"
                                    @focus.native="removeError()"
                                    maxlength="1"
                                />
                            </div>
                            <b-form-invalid-feedback
                                :state="!formErrors.has('otp')">
                                {{ formErrors.first('otp') }}
                            </b-form-invalid-feedback>
                            <b-button type="button" @click.prevent="onSubmit" :disabled="isSubmitLoading"
                                      ref="submitBtn" variant="primary" class="mt-3 w-100">
                                {{ $t('title.verifyCode') }}
                            </b-button>
                        </div>

                        <div v-else>
                            <b-form-group :label="$t('title.fullName')" label-for="full-name">
                                <b-form-input v-model="formFields.full_name" id="full-name"
                                              :class="formErrors.has('full_name') ? `error-input` : ``"
                                              required
                                              @input="removeError('full_name')"></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('full_name')">
                                    {{ formErrors.first('full_name') }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-group :label="$t('title.email')" label-for="email">
                                <b-form-input type="email" v-model="formFields.email" id="email"
                                              :class="formErrors.has('email') ? `error-input` : ``"
                                              @input="removeError('email')"></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('email')">
                                    {{ formErrors.first('email') }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-form-group :label="$t('title.residentialAddress')" label-for="address">
                                <input
                                    type="text"
                                    v-model="formFields.residential_address"
                                    @input="debouncedSearch('residential_address');removeError('residential_address')"
                                    @focus="onFocus('residential_address')"
                                    @blur="handleBlur"
                                    :placeholder="$t('title.searchLocation')"
                                    class="autocomplete-input form-control"
                                />
                                <ul class="autocomplete-list">
                                    <li
                                        v-for="(suggestion, index) in dropdowns.addresses.residential_address"
                                        :key="index"
                                        @click="selectSuggestion(suggestion,'residential_address')"
                                    >
                                        <span v-if="suggestion.id == 'default'">
                                             <b-spinner small variant="primary" type="grow"
                                                        label="Loading..."></b-spinner>
                                        </span>
                                        {{ suggestion.label }}
                                    </li>
                                </ul>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('residential_address')">
                                    {{ formErrors.first('residential_address') }}
                                </b-form-invalid-feedback>
                            </b-form-group>

                            <b-button type="button" @click="onFinalSubmit" :disabled="isSubmitLoading" variant="primary"
                                      class="mb-3 w-100">
                                {{ $t('title.send') }}
                            </b-button>
                        </div>
                    </div>
                </div>
            </div>
        </section>
    </div>
</template>

<script>
import { getAuthUser, setStorage } from "@/Util/auth";
import { mapGetters } from "vuex";
import { request } from "@/Util/Request";
import Error from "@/Util/Error";
import commonMixin from "@/Util/commonMixin";
import { reactive } from "vue";
import destinationMixin from "@/Util/destinationMixin";

const FORM_STATE = {
    phone: null,
    disability_id: null,
    full_name: null,
    email: null,
    role_id: null,
    residential_address: null,
    otp: {
        code1: null,
        code2: null,
        code3: null,
        code4: null,
    },
};

const phone = reactive({
    value: "",
    isBlurred: false,
    inputValue: {
        formatted: "",
        valid: false,
    },
    options: {
        autoFormat: true,
        inputOptions: {
            showDialCode: false,
            showDialCodeInList: true,
        },
        mode: "international",
        validCharactersOnly: true,
    },
});

export default {
    mixins: [commonMixin, destinationMixin],
    data() {
        return {
            formFields: { ...FORM_STATE },
            isValidP: false,
            phoneVerified: false,
            otpVerified: null,
            inputOptions: {
                placeholder: 'Enter your phone number',
                autofocus: true,
            },
            dropdowns: {
                disabilities: [],
                roles: [],
            },
            formErrors: new Error({}),
            number: null,
            phone: phone,
            isSubmitLoading: false,
            slug: this.$route.params.slug || null,
            organization: null,
            isLoading: false
        };
    },
    computed: {
        selectedRoleName() {
            const role = this.dropdowns.roles.find((role) => role.id === this.formFields.role_id);
            return role ? role.label.toLowerCase() : null; // Return role name or null if not found
        },
        ...mapGetters(['user', 'loader', 'locale'])
    },
    mounted() {
        if(this.slug) {
            this.getOrganizationDetail(this.slug);
        }
        if ( getAuthUser() && Object.keys(getAuthUser()).length == 0 ) {
            this.getDropdowns();
        }
    },
    methods: {
        getImageUrl(path) {
            if (!path) return 'https://via.placeholder.com/150'; // Default placeholder
            return `${process.env.VUE_APP_BACKEND_URL}storage/${path}`;
        },
        async getOrganizationDetail(slug) {
            try {
                this.isLoading = true;
                const response = await request({
                    method: 'get',
                    url: `/admin/auth/organization/${slug}`
                });

                const { data } = response;
                this.organization = data;
                this.isLoading = false;
            } catch (error) {
                this.isLoading = false;
                this.notifyError();
            }
        },
        async checkEnterToSendOtp(event) {
            if ( event.keyCode == 13 || event.key === "Enter" ) {
                this.onSubmitRegistration();
            }
        },
        async removeError(key=null) {
            if(key != null) {
                if ( typeof key === `object` ) {
                    for (let i = 0; i < key.length; i++) {
                        this.formErrors.remove(key[i]);
                    }
                } else {
                    this.formErrors.remove(key);
                }
            } else {
                let removeError = true;
                _.forEach(this.formFields.otp, (otp, key) => {
                    if ( otp ) {
                        removeError = false;
                    }
                });

                if ( removeError ) {
                    this.formErrors = new Error({});
                }
            }
        },
        handleRoleChange() {
            if ( this.formFields.role_id === this.getRoleIdByName('Volunteer') ) {
                this.formFields.disability_id = null;
            }
        },
        getRoleIdByName(roleName) {
            const role = this.dropdowns.roles.find((role) => role.label === roleName);
            return role ? role.id : null; // Return the ID if found, otherwise null
        },
        onInput(formattedNumber, input) {
            this.phone.inputValue = input;
            if ( input && input.nationalNumber ) {
                console.log(formattedNumber,input);
                setTimeout(() => {
                    if(formattedNumber.startsWith('+61')) {
                        input.countryCallingCode = 61;
                        input.countryCode = 'AU';
                    }
                    this.formFields.phone = input.nationalNumber;
                }, 0);
            }
        },
        resetError() {
            this.formErrors = new Error({});
        },
        async onSubmitRegistration() {
            if ( !this.phone.inputValue.valid ) {
                this.formErrors = new Error({
                    phone: [this.$t('title.enterValidPhoneNumber')]
                });
            } else {
                try {
                    this.isSubmitLoading = true;
                    const response = await request({
                        method: 'post',
                        url: `/auth/register-otp`,
                        data: {
                            phone: ( this.phone.inputValue || {} ).number
                        }
                    });

                    this.phoneVerified = true;

                    this.$nextTick(() => {
                        const firstOtpInput = this.$el.querySelector('.text-code');
                        if (firstOtpInput) {
                            firstOtpInput.focus();
                        }
                    });

                    _.map(this.formFields.otp, (value, key) => {
                        this.formFields.otp[key] = null;
                    });
                    this.notifySuccessWithMsg(this.$t('title.otpSentSuccessMsg'));
                    this.isSubmitLoading = false;
                } catch (error) {
                    this.isSubmitLoading = false;
                    if ( error.request && error.request.status && error.request.status === 422 ) {
                        this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                        return false;
                    } else {
                        this.notifyError();
                    }
                }
            }
        },
        async onSubmit() {
            try {
                this.isSubmitLoading = true;
                const response = await request({
                    method: 'post',
                    url: `/auth/verify-register-otp`,
                    data: {
                        phone: ( this.phone.inputValue || {} ).number,
                        otp: _.map(this.formFields.otp).join('')
                    }
                });

                this.otpVerified = true;

                this.notifySuccessWithMsg(this.$t('title.otpVerifiedMsg'));
                this.isSubmitLoading = false;
            } catch (error) {
                this.isSubmitLoading = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        async onFinalSubmit() {
            try {
                this.isSubmitLoading = true;
                const response = await request({
                    method: 'post',
                    url: `/auth/register`,
                    data: {
                        ...this.formFields,
                        phone: ( this.phone.inputValue || {} ).number,
                        role_id: this.getRoleIdByName('Volunteer'),
                        slug: this.slug
                    }
                });

                const { data } = response;

                await this.$router.push({ name: 'Login' });
                this.notifySuccessWithMsg(this.$t('title.registrationSuccessMessage'));
                this.isSubmitLoading = false;
            } catch (error) {
                this.isSubmitLoading = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        handleInput(codeField, event) {
            const value = event.target.value;
            if ( !/^\d$/.test(value) ) {
                this.formFields.otp[codeField] = '';
                return;
            }
            this.formFields.otp[codeField] = value;

            const nextInput = event.target.nextElementSibling;
            if ( nextInput && nextInput.tagName === 'INPUT' ) {
                nextInput.focus();
            }
        },
        handleKeyDown(codeField, event) {
            const target = event.target;
            if ( event.key == "Enter" || event.keyCode == 13 ) {
                this.$refs.submitBtn.focus();
                return;
            }

            if ( event.key === 'Backspace' && !this.formFields.otp[codeField] ) {
                const previousInput = target.previousElementSibling;
                if ( previousInput && previousInput.tagName === 'INPUT' ) {
                    previousInput.focus();
                }
            }
        },
        async getDropdowns() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/register`
                });

                const { roles, disabilities } = response;
                this.dropdowns.roles = roles;
                this.dropdowns.disabilities = disabilities;
                this.formFields.role_id = this.getRoleIdByName('Volunteer');
            } catch (error) {
            }
        },
    },
    watch: {
        'formFields.otp': {
            handler(newOtpValues) {
                this.removeError();
            },
            deep: true
        }
    },
};
</script>
