<template>
    <div>
        <b-modal v-model="isOpenModal" id="updateUserStatusModal" :hide-header="true"
                 :hide-footer="true">
            <div class="col-12" v-if="roles.includes('user') && status == 1">
                <h5>{{$t('title.role')}} <b>{{$t('title.user')}}</b></h5>
                <p v-if="statusCounts.length == 0">{{$t('title.noApplicationFound')}}</p>
                <p v-else><b>{{ $t('title.displayExistingUserStatus') }}</b></p>
                <table class="table" v-if="statusCounts.length > 0">
                    <thead>
                        <tr>
                            <th>{{ $t('title.applicationStatus') }}</th>
                            <th>{{ $t('title.amount') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="status in statusCounts" :key="status.status">
                            <td>{{ $t('title.'+_.camelCase(status.status)) }}</td>
                            <td>{{ status.count }}</td>
                        </tr>
                    </tbody>
                </table>


                <p v-if="userAssignedEvents.length == 0">{{$t('title.noEventsFound')}}</p>
                <p v-else><b>{{ $t('title.displayExistingUserEvents') }}</b></p>
                <table class="table" v-if="userAssignedEvents.length > 0">
                    <thead>
                        <tr>
                            <th>{{ $t('title.event') }}</th>
                            <th>{{ $t('title.volunteer') }}</th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="userEvent in userAssignedEvents" :key="userEvent.id">
                            <td>{{ userEvent.event.name }}</td>
                            <td>{{ userEvent.volunteer.full_name }}</td>
                        </tr>
                    </tbody>
                </table>
            </div>

            <div class="col-12" v-if="roles.includes('volunteer') && status == 1">
                <h5>{{$t('title.role')}} <b>{{$t('title.volunteer')}}</b></h5>
                <p><b>{{$t('title.volunteerInTreatment')}} : {{totalIntreatmentRequest}}</b></p>
                <div class="mb-3" v-if="totalIntreatmentRequest > 0">
                    <p>{{$t('title.openVolunteerList')}}</p>
                    <label for="type">{{$t('title.selectVolunteer')}}</label>
                    <treeselect :multiple="false" v-model="formFields.volunteer_id" :options="volunteers"
                                :placeholder="$t('title.select')"
                                id="type"></treeselect>
                    <b-form-invalid-feedback
                        :state="!formErrors.has('volunteer_id')">
                        {{ formErrors.first('volunteer_id') }}
                    </b-form-invalid-feedback>
                </div>

                <p><b>{{$t('title.volunteerAssignedEvent')}} : {{totalAssignedEvents}}</b></p>
                <div class="mb-3" v-if="totalAssignedEvents > 0">
                    <p>{{$t('title.openVolunteerListStatusMessageForEvent')}}</p>
                    <label for="type">{{$t('title.selectVolunteer')}}</label>
                    <treeselect :multiple="false" v-model="formFields.event_volunteer_id" :options="volunteers"
                                :placeholder="$t('title.select')"
                                id="type"></treeselect>
                    <b-form-invalid-feedback
                        :state="!formErrors.has('volunteer_id')">
                        {{ formErrors.first('volunteer_id') }}
                    </b-form-invalid-feedback>
                </div>
            </div>

            <div class="col-12" v-if="roles.includes('manager') && status == 1">
                <h5>{{$t('title.role')}} <b>{{$t('title.manager')}}</b></h5>
                <p><b>{{$t('title.managerTotalEventsIs')}} : {{totalManagerActiveEvents}}</b></p>
                <div class="mb-3" v-if="totalManagerActiveEvents > 0">
                    <p>{{$t('title.openManagerList')}}</p>
                    <label for="type">{{$t('title.selectManager')}}</label>
                    <treeselect :multiple="false" v-model="formFields.manager_id" :options="managers"
                                :placeholder="$t('title.select')"
                                id="type"></treeselect>
                    <b-form-invalid-feedback
                        :state="!formErrors.has('manager_id')">
                        {{ formErrors.first('manager_id') }}
                    </b-form-invalid-feedback>
                </div>
            </div>
             <div class="col-12">
            <p>{{ (status == 1) ? $t('title.userDeactiveConfirmation') : $t('title.userActiveConfirmation')  }}</p>
            </div>
            <div class="modal-footer d-flex mr-2 gap-2">
                <button type="button" class="btn main-btn col m-0" :disabled="isSubmit||((volunteers.length > 0) ? ((formFields.volunteer_id != null || formFields.event_volunteer_id != null ) ? ((managers.length > 0) ? ((formFields.manager_id != null) ? false : true) : false) : true) : ((managers.length > 0) ? ((formFields.manager_id != null) ? false : true) : false))" @click="handleSubmitRequest">{{
                        $t('title.yes')
                    }}
                </button>
                <button type="button" class="btn sec-btn col m-0" @click="cancelModal">
                    {{ $t('title.cancel') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { request } from "@/Util/Request";
import Error from "@/Util/Error";

const FORM_STATE = {
    volunteer_id: null,
    event_volunteer_id: null,
    manager_id: null,
    event_user_request_id: null
};

export default {
    data() {
        return {
            isOpenModal: false,
            status: null,
            userId: null,
            formErrors: new Error({}),
            isSubmit: false,
            statusCounts : [],
            roles: [],
            volunteers: [],
            managers: [],
            userAssignedEvents: [],
            totalIntreatmentRequest: 0,
            totalManagerActiveEvents: 0,
            totalAssignedEvents: 0,
            formFields: { ...FORM_STATE },
        };
    },
    methods: {
        async handleToggleModal(id, status, roles) {
            this.refreshModal();
            this.isOpenModal = !this.isOpenModal;
            this.status = status;
            this.userId = id;
            this.roles = roles.map((role) => role.name);
            if(this.roles.includes('user')) {
                try {
                    const response = await request({
                        method: 'post',
                        url: `admin/users/applications/${ id }`,

                    });

                    let { data } = response;
                    this.statusCounts = data;
                } catch (error) {
                    this.notifyError();
                }

                try {
                    const response = await request({
                        method: 'post',
                        url: `admin/user/assigned/events/${ id }`,

                    });

                    let { data } = response;
                    this.userAssignedEvents = data;
                    if(this.userAssignedEvents.length > 0) {
                        this.formFields.event_user_request_id = this.userAssignedEvents[0].id;
                    }
                } catch (error) {
                    this.notifyError();
                }
            }

            if(this.roles.includes('volunteer')) {
                try {
                    const response = await request({
                        method: 'post',
                        url: `admin/volunteer/intreatment/${ id }`,

                    });

                    let { data } = response;
                    this.totalIntreatmentRequest = data.intreatment_request;
                    if(this.totalIntreatmentRequest > 0) {
                        this.getOpenVolunteerLists(id);
                    }
                }  catch (error) {
                    this.notifyError();
                }

                // total event volunteers
                try {
                    const response = await request({
                        method: 'post',
                        url: `admin/assigned/event/volunteers/${ id }`,

                    });

                    let { data } = response;
                    this.totalAssignedEvents = data.total_event_volunteer_requests;
                    if(this.totalAssignedEvents > 0) {
                        this.getOpenEventVolunteerLists(id);
                    }
                }  catch (error) {
                    this.notifyError();
                }
            }

            if(this.roles.includes('manager')) {
                try {
                    const response = await request({
                        method: 'post',
                        url: `admin/total-manager-events/${ id }`,

                    });

                    let { data } = response;
                    this.totalManagerActiveEvents = data.total_events;
                    if(this.totalManagerActiveEvents > 0) {
                        this.getManagerLists(id);
                    }
                }  catch (error) {
                    this.notifyError();
                }
            }

        },
        async getManagerLists(id) {
            try {
                const response = await request({
                    method: 'post',
                    url: `admin/manager/lists/${ id }`,

                });

                let { data } = response;
                this.managers = data;
            }  catch (error) {
                this.notifyError();
            }
        },
        async getOpenVolunteerLists(id) {
            try {
                const response = await request({
                    method: 'post',
                    url: `admin/volunteer/open/lists/${ id }`,

                });

                let { data } = response;
                this.volunteers = data;
            }  catch (error) {
                this.notifyError();
            }
        },
        async getOpenEventVolunteerLists(id) {
            try {
                const response = await request({
                    method: 'post',
                    url: `admin/open/event/volunteers/${ id }`,

                });

                let { data } = response;
                this.volunteers = data;
            }  catch (error) {
                this.notifyError();
            }
        },
        async handleSubmitRequest() {
            try {
                this.isSubmit = true;
                let isActive =  (this.status == 0) ? 1 : 0;
                const response = await request({
                    method: 'post',
                    url: `admin/user/update-status/${this.userId}/${isActive}`,
                    data: this.formFields
                });
                if ( response ) {
                    let message = '';
                    if(isActive == 1) {
                        message = this.$t('title.accountActivatedSuccessMessage');
                    } else {
                        message = this.$t('title.accountDeActivatedSuccessMessage');
                    }
                    this.notifySuccessWithMsg(message);
                    this.$emit('refreshTable');
                    this.formErrors = new Error({});
                    setTimeout(() => {
                        this.isSubmit = false;
                        this.cancelModal();
                    }, 1000);
                }
            } catch (error) {
                this.isSubmit = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    if(this.formErrors.has('message')) {
                        this.notifyErrorWithMsg(this.formErrors.first('message'));
                    }

                    if(this.formErrors.has('error')) {
                        this.notifyErrorWithMsg(this.formErrors.first('error'));
                    }
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        cancelModal() {
            this.isOpenModal = false;
        },
        refreshModal() {
            this.totalIntreatmentRequest = 0;
            this.statusCounts = [];
            this.volunteers = [];
            this.managers = [];
            this.formErrors = new Error({});
            this.formFields = { ...FORM_STATE };
        }
    }
};
</script>
