const titles = {
    action: "Action",
    addSuccessfully: "Added Successfully",
    updateSuccessfully: "Updated Successfully",
    errorMessage: "Ops, something went wrong, try again",
    deletedSuccess: "deleted successfully",
    otpVerifiedMsg: "OTP Verified Successfully !",
    otpSentSuccessMsg: "OTP Sent Successfully !",
    registerSuccessMsg: "Register Successfully !",
    areYouSureWantToLogout: "Are you sure you want to logout?",
    successLogin: "You have been successfully logged in",
    update: "Update",
    save: "Save",
    item: "Item",
    profile: "Profile",
    dashboard: "Dashboard",
    enterToSearch: "Enter to search",
    email: "Email",
    phone: "Phone",
    openVolunteering: "Open Volunteering",
    myVolunteerBasket: "My Volunteer Basket",
    newHelpRequest: "New Help Request",
    userManagement: "User Management",
    helpRequests: "Help Requests",
    notificationLog: "Notification log",
    aboutUs: "About Us",
    instructions: "Instructions",
    regulations: "Regulations",
    accessibilityStatement: "Accessibility Statement",
    fullName: "Full Name",
    type: "Type",
    registrationDate: "Registration Date",
    disability: "Disability",
    residentialAddress: "Residential Address",
    currentAddress: "Current Address",
    alerts: "Alerts",
    actions: "Actions",
    all: "All",
    volunteer: "Volunteer",
    user: "User",
    welcomeMessage: "Welcome to the purple vest app of the Israel Accessibility Association - to help people with disabilities and the elderly in emergency situations. Here you can help people with disabilities all over the country. Your help will significantly help the mission of the purple vest - to leave no one behind!",
    enterPhoneNumber: "Enter a phone number to receive a code via SMS",
    enterVerificationCode: "Enter the verification code",
    verifyCode: "Verify Code",
    getCode: "Get Code",
    registerAs: "Register as",
    clear: "Clear",
    editingMyProfile: "Editing my profile",
    exitFromSystem: "Exit from system",
    refresh: "Refresh",
    disableCategoryOption: "Here are your in-progress help requests",
    chooseHelpType: "To get started, choose the type of help you're requesting:",
    toNextStep: "To the next step",
    nextStep: "Next step",
    needRideTo: "I need a ride to",
    iAmFrom: "I am from",
    originAddressLonger: "Your origin address must be longer than 5 characters.",
    destinationAddressLonger: "Your Destination address must be longer than 5 characters.",
    wantToBeThereOn: "I want to be there on",
    selectFutureDate: "Please select a future date.",
    selectFutureTime: "Please select a future time.",
    requestDetailsDescription: "Details and description of the request",
    descriptionLonger: "Your description must be longer than 5 characters.",
    untilRequestRelevant: "Until when the request is relevant",
    personalDetails: "Personal details",
    fullNameLonger: "Your full name must be longer than 5 characters.",
    phoneLonger: "Your phone must be longer than 5 characters.",
    residentialAddressLonger: "Your Residential Address must be longer than 5 characters",
    createNewHelpRequest: "Create new help request",
    enterValidPhoneNumber: "Enter a valid phone number",
    sentTo: "Sent to",
    date: "Date",
    time: "Time",
    channel: "Channel",
    content: "Content",
    usePrevious: "Use Previous",
    useCurrent: "Use Current",
    status: "Status",
    sendTo: "Send To",
    category: "Category",
    pendingApplicationUserRequestManagement: "Pending Application User Request Management",
    perPage: "Per Page",
    arrivalAddress: "Arrival Address",
    wayForCommunication: "Preferred way communication",
    whatsApp: "WhatsApp",
    welcomeDashboard: "Welcome Dashboard",
    iNeedARideTo: "I need a ride to",
    iWantToBeThereOn: "I want to be there on",
    at: "at",
    detailsAndDescriptionOfRequest: "Details and description of the request",
    toTheNextStep: "To the next step",
    goToNextStep: "Go to the next step",
    referenceDescription: "Reference description",
    comments: "Comments",
    relevantUntil: "Relevant until",
    sorryICantHelpThisTime: "Sorry, I can't help this time",
    imReadyAccept: "I'm ready to accept",
    appliedFilters: "Applied Filters",
    filtering: "Filtering",
    showVolunteers: "Show Volunteers",
    cancel: "Cancel",
    byCategory: "By Category",
    byTown: "By Town",
    accessibilityAndDisabilityInfo: "Information about accessibility and disability need",
    goBack: "Go Back",
    details: "Details",
    youCanContactMeAt: "You can contact me at",
    deletedSuccessMsg: "has been deleted successfully",
    yes: "Yes",
    registerToHelp: "Register to help",
    joinUs: "Join us",
    role: "Role",
    switchAs: "Switch as",
    submit: "Submit",
    updateRoleMsg: "Role has been updated successfully",
    newToPurpleVest: "New To Purple Vest?",
    signUp: "Sign Up",
    alreadyHavingAccount: "Already Having Account?",
    signIn: "Sign In",
    urgency: "Urgency",
    inUserCare: "Volunteer",
    description: "Description",
    validity: "Validity",
    creationDate: "Creation Date",
    systemNotes: "System Notes",
    destinationAddress: "Residential Address",
    originAddress: "Current Address",
    volunteesrsManagement: "Volunteesrs Management",
    no: "No",
    ok: "OK",
    noResultFound: "No Result Found...",
    areYouSureWantToDelete: "Are you sure you want to delete?",
    deleteConfirmation: "Delete Confirmation",
    delete: "Delete",
    select: "Select...",
    register: "Register",
    events: "Emergency Events",
    manager: "Manager",
    name: "Name",
    active: "Active",
    deActive: "DeActive",
    lastStoppedAt: "Last Stopped At",
    createdAt: "Created At",
    address: "Address",
    back: "Back",
    clickHereForEmergency: "Click here for Emergency",
    sendSuccessRequestForEmergency: "Emergency request sent successfully",
    addNew: "Add New",
    title: "Title",
    radius: "Radius",
    saveAndClose: "Save and Close",
    location: "Location",
    isActive: "Is Active?",
    accept: "Accept",
    reject: "Reject",
    updateStatusMessage: "status updated successfully",
    residential_address: "Residential Address",
    arrival_address: "Arrival Address",
    communication: "Communication",
    assign: "Assign",
    assignSuccessMessage: "assign successfully",
    emergencyRequests: "Emergency Requests",
    closeEmergencyRequest: "Close Emergency request",
    closeEmergencyRequestMessage: "Emergency request closed successfully",
    manageSingleHelpRequest: "Manage Single Help Request",
    manageEventHelpRequest: "Manage Event Help Request",
    event: "Event",
    confirmation: "Confirmation",
    request: "Request",
    assigned: "Assigned",
    pendingApproval: "Pending Approval",
    pending: "Pending",
    notFoundVolunteer: "Volunteer not found",
    notFoundUser: "User not found",
    close: "Close",
    areYouSureWantToCloseRequest: "Are you sure you want to close this request?",
    areYouSureWantToRejectRequest: "Are you sure you want to reject this request? You will not help this user again.",
    areYouWantToCompleteRequest: "Are you sure you want to complete this request?",
    completeRequestSuccessfully: "Request completed successfully.",
    eventHelpRequestCompletedSuccessfully: "Event help request completed successfully",
    rejectRequestSuccessfully: "Reject request successfully.",
    categories: "Categories",
    author: "Author",
    eventVolunteerHelpRequest: "Event Volunteer Help Request",
    eventStatusVerifyMessage: "Please review the help request and choose to accept or reject it.",
    helpRequestDetails: "Help Request Details",
    from: "From",
    message: "Message",
    eventRequestConfirmationMessage: "event has occurred in your area. Are you ready to help?",
    accepted: "Accepted",
    rejected: "Rejected",
    requestExpired: "Request Expired",
    requestInvalid: "Request Invalid",
    assignRoles: "Assign Roles",
    forbiddenTitle: "403 Forbidden",
    forbiddenMessage: "Your access has been restricted. If you think this is a mistake, contact support.",
    validUntil: "Valid until",
    footerText: "Designed by Mor Alon Developed by",
    codeForIsrael: "From Israel Accessibility, all rights reserved. Based on a beta version of",
    checkerSoft: "Checker Software",
    contactUs: "Contact Us",
    subject: "Subject",
    submitYourQueryHere: "Submit your query here",
    submitSuccessfully: "Submit successfully",
    contact: "Contact",
    iWantToVolunteer: "I want to Volunteer.",
    iAmPerson: "I am a person with disability or and elderly person.",
    requests: "Requests",
    totalBuddies: "Total Buddies",
    totalActivities: "Total Activities",
    totalRequests: "Total Requests",
    volunteerJoined: "Volunteer joined in 30 days",
    personWithDisabilities: "Person with Disabilities",
    volunteers: "Volunteers",
    coverageMap: "Coverage Map",
    fromDate: "From Date",
    toDate: "To Date",
    acceptRequestSuccess: "request accepted successfully",
    acceptRequest: "Accept request",
    typeToSearch: "Type to search...",
    query: "Query",
    searchLocation: "Search location...",
    send: "Send",
    sms: "SMS",
    sendMessage: "Send Message",
    users: "Users",
    okSend: "Okay, Send",
    sendSuccessMessage: "message send successfully.",
    sendingMessage: "Sending a message",
    typeMessage: "Type your message here...",
    sendMessageConfirmMsg1: "You are about to send message to",
    sendMessageConfirmMsg2: "users, are you sure ?",
    waitingMessage1: "Waiting for",
    waitingMessage2: "seconds...",
    settings: "Settings",
    autoAssign: "Auto Assign",
    tasks: "Tasks",
    complete: "Complete",
    completeRequestMessage: "Are you sure you want to complete this help request?",
    closeRequestMessage: "Are you sure you want to close this help request?",
    helpRequestSuccessMessage: "Help request was sent successfully",
    helpRequestCompleteMessage: "Help request status successfully updated",
    helpRequestClosedMessage: "Help request closed successfully",
    open: "Open",
    closed: "Closed",
    expired: "Expired",
    completed: "Completed",
    inTreatment: "In Treatment",
    activeEvents: "Active Events",
    deActiveEvents: "DeActive Events",
    info: "Info",
    color: "Color",
    waitingForApproval: "Waiting for admin approval",
    userPendingInfo: "The user who has requested for emergency help is still in the pending list.",
    userAssignedInfo: "We show the Assigned button when an event owner assigns a volunteer to a user.",
    userWaitingForAdminApproval: "When the event owner assigns the volunteer to the user, it goes for admin approval and we see a red color Pending button.  If the user is in the voluneet's buddy list then there is no need for admin approval and he gets directly assigned. Even when an admin assigns a volunteer to a user, he or she gets directly assigned.",
    volunteerPendingInfo: "When we create an event, a request is sent to all the volunteers within the radius of the event.  Like are you ready to help? Until the volunteer accepts or rejects the request, it remains pending.",
    volunteerAcceptedInfo: "When event owners or volunteers accept requests, we look accepted.",
    volunteerRejectedInfo: "When event owners or volunteers reject requests, we look rejected.",
    volunteerAssignedInfo: "When the event owner assigns a user to a volunteer who has accepted a help request, we see the Assigned button.",
    volunteerWaitingForApproval: "When the event owner assigns the user to the accepted volunteer, it goes for admin approval and we see a red color Pending button.  If the volunteer is in the user's buddy list then there is no need for admin approval and he gets directly assigned. Even when an admin assigns a user to a volunteer, he or she gets directly assigned.",
    acceptDropdown: "Accept dropdown",
    acceptDropdownInfo: "When the volunteer does not accept or reject the request sent but he wants to do it but is busy with some work then the event owner calls the volunteer and he is ready to accept the request then the event owner himself accepts the request.",
    rejectDropdown: "Reject dropdown",
    rejectDropdownInfo: "When the volunteer does not accept or reject the request sent but he wants to do it but is busy with some work then the event owner calls the volunteer and he is ready to reject the request then the event owner himself rejects the request.",
    volunteerAcceptReject: "Are you sure you want to make this user available to help?",
    buddies: "Buddies",
    chat: "Chat",
    volunteerCompletedInfo: "We see the Completed status when the event owner or volunteer complete the assigned request.",
    userRejectedInfo: "When the event owner and volunteer reject the assign request, we can again assign a new volunteer to that user.",
    volunteerRejectInfo: "It reopens when the event owner and volunteer reject the assigned request.",
    searchAddress: "Search address...",
    declineRequest: "Decline request",
    youDeclineRequest: "You decline request successfully",
    typeYourMessageHear: "Type your message here...",
    messageQuestion: "Hello, do you want help user 1?",
    yep: "Yep",
    helpRequestAcceptMessage: "Help request accept successfully",
    deleteUserMessage: "Are you sure you want to delete user?",
    noApplicationFound: "No applications found",
    noEventsFound: "No events found",
    applicationStatus: "Application Status",
    count: "Count",
    amount: "Amount",
    displayExistingUserStatus: "Following table is display user existing applications status",
    displayExistingUserEvents: "Following table is display user existing assigned events",
    volunteerInTreatment: "Applications for treatment",
    openVolunteerList: "You can choose another user to whom the tasks in the treatment will be assigned",
    userHelpRequestAccepted: "User help request accepted successfully",
    disableDisablityOption: "First you have to update the disability after that you will be able to do the activity",
    sendSuccessMessageToUser: "Message successfully sent to user",
    isAutoAssign: "Is Auto Assign?",
    minutes: "Minutes",
    km: "KM",
    region: "Region",
    country: "Country",
    updatePermissionMsg: "Permission Updated Successfully",
    permissionDeleteConfirmMsg: "Are you sure you want to delete this permission?",
    deletePermissionMsg: "Permission Deleted Successfully",
    permission: "Permission",
    selectCountry: "Select Country",
    search: "Search",
    selectRegion: "Select Region",
    emailRequired: "Email field is required",
    disabilityRequired: "Disability field is required",
    residentialAddressRequired: "Residential address field is required",
    export: "Export",
    as: "as",
    or: "or",
    expandSearchRadius: "Extend search radius when no volunteers are found",
    ifNoResponseCallTheVolunteerAfter: "If there is no response, call the next volunteer after",
    column: "column",
    additionalWaysToContactUs: "Additional ways to contact us",
    alsoSendEmailTo: "You can also send an e-mail to support@purple-vest.net",
    newRegistration: "New registration?",
    imPersonAskingForHelp: "I'm a person asking for help",
    imInterestedInVolunteering: "I'm interested in volunteering",
    addPermissionToAnotherArea: "Add permission to another area",
    registrationSuccessMessage: "Your account register successfully. Please wait for admin approval.",
    userActiveConfirmation: "Are you sure you want to activate this user?",
    userDeactiveConfirmation: "Are you sure you want to deactivate this user?",
    translate: "Translate",
    roles: "Roles",
    approved: "Approved",
    map: "Map",
    edit: "Edit",
    assignVolunteers: "Assign Volunteers",
    updateVolunteerAssignMessage: "Volunteer assigned successfully",
    accountActivatedSuccessMessage: "User account activated successfully",
    accountDeActivatedSuccessMessage: "User account deactivated successfully",
    selectVolunteer: "Select a Volunteer",
    thisVolunteerInTreatmentRequestsIs: "This volunteer In Treatment requests is",
    openVolunteerListStatusMessage: "This is an open volunteer list. You can select this volunteer to assign all existing In Treatment requests.",
    deleteVolunteerConfirmMessage: "Are you sure you want to delete volunteer?",
    managerTotalEventsIs: "This manager total events is",
    openManagerList: "This is an another manager list. You can select this manager to assign all existing active events.",
    selectManager: "Select Manager",
    volunteerAssignedEvent: "Embedded requests under Events",
    openVolunteerListStatusMessageForEvent: "This is an open volunteer list. You can select this volunteer to assign event user.",
    registrationAskingForHelp: "Registration asking for help",
    show: "Show",
    volunteerReject: "Are you sure you would like to make this user unavailable for assistance?",
    switchToVolunteer: "Switch to volunteer mode",
    switchToUser: "Switch to asking for help mode",
    switchToManager: "Switch to manager mode",
    workAddress: "Work Address",
    activated: "Activated",
    deactivated: "Deactivated",
    deleted: "Deleted",
    sendSMSSuccessMessage: "SMS send successfully",
    organizations: "Organizations",
    logo: "Logo",
    organization: "Organization",
    selectOrganization: "Select organization",
    updateOrganizationMessage: "Organization update successfully",
    link: "Link",
    linkCopyMessage: "Link copy successfully",
    detail: "Detail",
    myHelpRequest: "My Help Request",
    thereAreNoMoreHelpRequests: "There are no open help requests",
    passwordConfirmation: "Password Confirmation",
    password: "Password",
    addressUpdateAlert: "If you update the address, the previous address has some help requests created. Do you want to close them?",
    addressUpdateAlertVolunteer: "If you update the address, the previous address has some assignee help request created. Do you want to close them?",
    warningForAddressUpdateVolunteer: "If address update there are another role as volunteer your assignee help request will be closed automatically by system.",
    warningForAddressUpdate: "If address update there are another role as user your in-progress or pending help request will be closed automatically by system.",
    arrivalTime: "Arrival Time",
    requestInProgress: "Note: You submitted the request at {time}, which is {status}.",
    inProgress: "In Progress",
    lastUpdatedAt: "Last Updated At",
    unassign: "Unassign",
    numberOfVolunteer: "Number Of Volunteer",
    organizationName: "Organization Name",
    purpleVestLogo: "Purple vest logo",
    changeLanguage: "Change language",
    myProfile: "My profile",
    hebrew: "עִברִית",
    english: "English",
    openHelpRequests: "Open help requests",
    needHelp: "Need Help",
    volunteerRegisterMessage: "Your registration has been received, the system team will review the details and contact you as soon as possible.",
    low: "Low",
    high: "High",
    medium: "Medium",
    restoreUserMessage: "Are you sure you want to restore user?",
    restoredSuccessMsg: "has been restored successfully",
};

export default {
    locale: "en-US",
    messages: {
        title: titles
    },
};
