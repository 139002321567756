<template>
    <div class="main-block request-management" v-if="$global.hasRole('manager')">
        <div class="div col-12 p-0 d-flex align-items-start">
            <h4 class="">{{ $t('title.events') }}</h4>
        </div>
        <div class="scrollable-block">
            <div class="col-12 row mt-3 m-0 p-0" style="min-height: 90%">
                <crud-table :columns="columns" :list-url="listUrl" ref="application-table" v-on:refresh="resetFilter">
                    <template #filter-slot>
                        <b-col lg="3" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.region')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" :placeholder="$t('title.select')" @input="handleFilter"
                                            v-model="filters.state"
                                            class="form-input"
                                            :options="dropdowns.states"/>
                            </b-form-group>
                        </b-col>
                    </template>
                    <template slot>
                        <b-button
                            variant="primary"
                            class=""
                            :disabled="isExport"
                            @click="exportCSV">
                            {{ $t('title.export')}}
                        </b-button>
                        <router-link :to="{name:'managerEventForm',params:{operation:'add'}}" class="btn btn-primary">
                            <b-icon icon="plus" class="icon-style"></b-icon>
                            {{ $t('title.addNew') }}
                        </router-link>
                    </template>
                    <template #cell(manager)="{item}">
                        {{ ( item.manager || {} ).full_name }}
                    </template>
                    <template #cell(last_stopped_at)="{item}">
                        {{ item.last_stopped_at ? $global.utcDateToLocalDate(item.last_stopped_at) : null }}
                    </template>
                    <template #cell(created_at)="{item}">
                        {{ item.created_at ? $global.utcDateToLocalDate(item.created_at) : null }}
                    </template>
                    <template #cell(status)="{item}">
                        <span class="text-success" v-if="item.status == true">{{ $t('title.active') }}</span>
                        <span v-else class="text-danger">{{ $t('title.deActive') }}</span>
                    </template>
                    <template #cell(actions)="{item}">
                        <router-link :to="{name:'managerEventMap',params:{id:item.id}}"
                                     v-b-popover.hover.top="$t('title.map')"
                                     v-if="item.status"
                                     variant="outline-primary"
                                     class="mr-1" @click="onSubmit(item);">
                            <b-icon icon="map" class="icon-style"></b-icon>
                        </router-link>
                        <router-link :to="{name:'managerEventForm',params:{id:item.id,operation:'edit'}}"
                                     v-if="item.status"
                                     v-b-popover.hover.top="$t('title.edit')"
                                     variant="outline-primary"
                                     class="mr-1" @click="onSubmit(item);">
                            <b-icon icon="pencil-square" class="icon-style"></b-icon>
                        </router-link>
                    </template>
                </crud-table>
            </div>
        </div>
    </div>
</template>

<script>

import { request } from "@/Util/Request";
import { mapState } from "vuex";
import * as XLSX from "xlsx";
import { saveAs } from "file-saver";

const FILTER_FORM = {
    roles: null,
    statuses: null,
    state: null
};

const COLUMN_STATE = (self) => [
    { key: 'actions', label: self.$t('title.actions') },
    { key: 'status', label: self.$t('title.status'), sortable: true },
    { key: 'name', label: self.$t('title.name'), sortable: true },
    { key: 'manager', label: self.$t('title.manager'), sortable: true },
    { key: 'address', label: self.$t('title.address'), sortable: true },
    { key: 'system_note', label: self.$t('title.systemNotes'), sortable: true },
    { key: 'last_stopped_at', label: self.$t('title.lastStoppedAt'), sortable: true },
    { key: 'created_at', label: self.$t('title.createdAt'), sortable: true },
];

export default {
    data() {
        return {
            operation: '',
            listUrl: '/manager/events',
            filters: {
                ...FILTER_FORM
            },
            dropdowns: {
                states: []
            },
            isExport: false
        };
    },
    mounted() {
        this.getStates();
    },
    methods: {
        async exportCSV() {
            try {
                this.isExport = true;
                const listUrl = `manager/export-events`;
                const queryParams = new URLSearchParams();

                if (this.filters.state) {
                    queryParams.append('filters[state]', this.filters.state);
                }

                if(this.$refs['application-table'].query && this.$refs['application-table'].query.search) {
                     queryParams.append('search', this.$refs['application-table'].query.search);
                }

                const apiUrl = `${listUrl}?${queryParams.toString()}`;

                const response = await request({
                    method: 'get',
                    url: apiUrl,
                });
                const data = response.data;
                // console.log(data);
            
                // // Convert data to a worksheet
                const worksheet = XLSX.utils.json_to_sheet(data);

                // Create a new workbook and append the worksheet
                const workbook = XLSX.utils.book_new();
                XLSX.utils.book_append_sheet(workbook, worksheet, "Events");

                // Convert workbook to Excel file
                const excelBuffer = XLSX.write(workbook, { bookType: "xlsx", type: "array" });

                // Convert to Blob and trigger download
                const blob = new Blob([excelBuffer], { type: "application/octet-stream" });
                saveAs(blob, "events.xlsx");
                this.isExport = false;
            } catch (error) {
            }
        },
        async getStates() {
            try {
                const response = await request({
                    method: 'get',
                    url: `manager/event/states`,
                });

                this.dropdowns.states = response.data;

            } catch (error) {
            }
        },
        async onSubmit(item) {

            try {
                const response = await request({
                    method: 'patch',
                    url: `/admin/application-users/${ item.id }`,
                    data: {
                        'is_approved': 1
                    }
                });

                this.$bvToast.toast(`Accept request successfully`, {
                    title: `Accept Request`,
                    solid: true,
                    variant: 'success',
                });

                this.$refs['application-table'].refreshTableData();

            } catch (error) {
                this.notifyError();
            }

        },
        handleFilter() {
            this.$refs['application-table'].handleFilter(this.filters);
        },
        resetFilter() {
            this.filters = { ...FILTER_FORM };
        },
    },
    computed: {
        columns() {
            return COLUMN_STATE(this);
        }
    }
};
</script>
