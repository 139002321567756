<template>
    <div>
        <div v-if="!isUnAuthorized">
            <header class="row mx-0 align-items-center main-header bg-blur">
                <div class="logo col-auto p-0" v-b-popover.hover.top="$t('title.purpleVestLogo')">
                    <img :src="locale === 'en-US' ? require('@/assets/logo_full_en.png')  : require('@/assets/logo_full_he.png')" alt="logo" height="80px" class="d-none d-md-flex" />
                    <img :src="locale === 'en-US' ? require('@/assets/logo_full_en.png')  : require('@/assets/logo_full_he.png')" alt="logo" height="80px" class="d-md-none" />
                </div>
                <div class="menu col-auto ms-auto position-relative p-0 gap-0 d-flex">
                    <b-button
                        @click="$emit('toggle-sidebar')"
                        variant="link"
                        class="ms-auto p-0 d-lg-none toggleSidebar"
                        id="toggleSidebar"
                    >
                        <b-icon icon="list"></b-icon>
                    </b-button>

                    <b-dropdown right>
                        <template #button-content>
                            <div class="box image rounded-circle lang" v-b-popover.hover.top="$t('title.changeLanguage')">
                                <b-icon icon="translate"></b-icon>
                            </div>
                        </template>
                        <b-dropdown-item
                            class="notify-item"
                            v-for="(entry, i) in languages"
                            :key="`Lang${i}`"
                            :value="entry"
                            @click="setLanguage(entry.language, entry.title)"
                        >
                            <span class="align-middle">{{ $t('title.'+ entry.name) }}</span>
                        </b-dropdown-item>
                    </b-dropdown>

                    <!-- User Dropdown -->
                    <b-dropdown right v-if="user" ref="dropdown-profile">
                        <template #button-content>
                            <div class="box image rounded-circle" v-b-popover.hover.top="$t('title.myProfile')">
                                <b-icon icon="person"></b-icon>
                            </div>
                        </template>
                        <router-link @click.native="closeDropdown" :to="{name:'MyVolunteerBasket'}"
                                     class="dropdown-item"
                                     v-if="$global.hasRole('volunteer')">
                            <b-icon icon="basket"></b-icon>
                            {{ $t('title.myVolunteerBasket') }}
                        </router-link>
                        <router-link @click.native="closeDropdown" :to="{name:'Profile'}" class="dropdown-item"
                                     v-if="!$global.hasRole('admin')">
                            <b-icon icon="gear"></b-icon>
                            {{ $t('title.editingMyProfile') }}
                        </router-link>
                        <router-link @click.native="closeDropdown" :to="{name:'AdminProfile'}" class="dropdown-item"
                                     v-else>
                            <b-icon icon="gear"></b-icon>
                            {{ $t('title.editingMyProfile') }}
                        </router-link>
                        <b-dropdown-divider></b-dropdown-divider>
                        <a href="javascript:void(0);" class="dropdown-item" v-b-modal.my-logout>
                            <b-icon icon="box-arrow-right"></b-icon>
                            {{ $t('title.exitFromSystem') }}
                        </a>
                    </b-dropdown>
                </div>
                <div
                    v-if="user && !$global.hasRole('admin')" class="col-auto col-md-12 p-0 mt-1 px-2">
                        <span class="bg-primary text-white d-block text-center py-2 px-2 px-md-0 mt-1"
                              id="switch-button"
                              v-for="(role,index) in _.filter(user.roles,(i) => i !== user.role && i !== 'admin')"
                              :key="index" @click="switchingRole(role)">
                            {{ ( role == 'user' ) ? $t('title.switchToUser') : '' }}
                            {{ ( role == 'volunteer' ) ? $t('title.switchToVolunteer') : '' }}
                            {{ ( role == 'manager' ) ? $t('title.switchToManager') : '' }}
                        </span>
                </div>

                <b-modal id="my-logout" :title="$t('title.areYouSureWantToLogout')"
                         no-close-on-backdrop no-close-on-esc
                         @ok="handleSubmitLogout" :ok-title="$t('title.yes')" :cancel-title="$t('title.no')">
                    <div class="my-3">
                        <div class="col-12">
                            <p class="fs-25">
                                <strong> {{ $t('title.areYouSureWantToLogout') }}</strong>
                            </p>
                        </div>
                    </div>
                </b-modal>
            </header>
        </div>
        <div v-else>
            <header class="row mx-0 align-items-center main-header"
                    style="box-shadow: unset;background-color: unset !important;">
                <div class="menu col-auto ms-auto position-relative p-0 gap-0 d-flex">
                    <b-dropdown right>
                        <template #button-content>
                            <div class="box image rounded-circle lang" v-b-popover.hover.top="$t('title.translate')">
                                <b-icon icon="translate"></b-icon>
                            </div>
                        </template>
                        <b-dropdown-item
                            class="notify-item"
                            v-for="(entry, i) in languages"
                            :key="`Lang${i}`"
                            :value="entry"
                            @click="setLanguage(entry.language, entry.title)"
                        >
                            <span class="align-middle">{{ $t('title.'+ entry.name) }}</span>
                        </b-dropdown-item>
                    </b-dropdown>
                </div>
            </header>
        </div>
    </div>
</template>

<script>
import {getAuthUser, getStorage, hasRole, refresh, removeStorage, setStorage} from "@/Util/auth";
import {request} from "@/Util/Request";
import {mapGetters, mapState} from "vuex";

export default {
    name: 'AppHeader',
    computed: {
        ...mapGetters(['user', 'locale']),
        ...mapState(['isUnAuthorized'])
    },
    data() {
        return {
            languages: [
                {
                    language: "en-US",
                    title: "English",
                    name: "english"
                },
                {
                    language: "he-IL",
                    title: "Hebrew",
                    name: "hebrew"
                },
            ],
        };
    },
    methods: {
        closeDropdown() {
            this.$refs['dropdown-profile'].hide(); // Close the dropdown
        },
        switchingRole(role) {
            const user = JSON.parse(getStorage(`auth`)) || {};
            if ( user && user.role ) {
                user.role = role;
                setStorage(`auth`, JSON.stringify(user));
                this.$store.dispatch("user", user);
                if ( this.$global.hasRole('admin') ) {
                    if ( ( this.$route || {} ).name == 'AdminCoverageMap' ) {
                        refresh();
                    } else {
                        this.$router.push({name: 'AdminCoverageMap'});
                    }
                } else {
                    if ( ( this.$route || {} ).name == 'Dashboard' ) {
                        if ( this.$global.hasRole('volunteer') ) {
                            this.$router.push({name: 'OpenVolunteering'});
                        }
                        refresh();

                    } else {
                        if ( !user.disability_id && user && user.roles.includes('user') ) {
                            if ( this.$route.path !== '/profile' ) {
                                this.$router.push({name: 'Profile'});
                            }
                        } else {
                            if ( this.$global.hasRole('volunteer') ) {
                                this.$router.push({name: 'OpenVolunteering'});
                            } else {
                                this.$router.push({name: 'Dashboard'});
                            }

                        }

                    }
                }
            }
        },
        setLanguage(locale, country) {
            this.text = country;
            this.$store.commit('CHANGE_SETTING', locale);
            const isRTL = locale === "he-IL";
            this.$store.dispatch("setIsRTL", isRTL);
            setStorage(`locale`, locale);
            refresh();
        },
        toggleSidebar() {
            this.$emit('toggle-sidebar');
        },
        async handleSubmitLogout() {
            const adminRole = hasRole('admin');
            try {
                const response = await request({
                    method: 'post',
                    url: adminRole ? `/admin/auth/logout` : `/auth/logout`,
                });

                removeStorage('auth');

                await this.$store.dispatch('user', null);
                await this.$store.dispatch('setUnAuthorized', true);

                if ( !adminRole ) {
                    await this.$router.push({name: 'Login'});
                } else {
                    await this.$router.push({name: 'AdminLogin'});
                }

            } catch (error) {
                this.notifyError();
            }
        },
    },
};
</script>
