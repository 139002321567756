<template>
    <div>
        <b-modal v-model="isOpenModal" id="userDetailModal" :hide-header="true" :hide-footer="true">
            <div>
                <b-tabs content-class="mt-3">
                    <b-tab :title="$t('title.details')" active>
                        <UserDetail :user-detail="userDetail" />
                    </b-tab>
                    <b-tab :title="$t('title.chat')">
                        <CategoryChat :category-user="userDetail" :categoryId="detail && detail?.category?.id" />
                    </b-tab>
                </b-tabs>
            </div>
            <div class="modal-footer d-flex mr-2 gap-2">
                <button type="button" class="btn sec-btn col m-0" @click="cancelModal">
                    {{ $t('title.cancel') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import UserDetail from "@/components/UserDetail";
import CategoryChat from "@/components/CategoryChat";

export default {
    data() {
        return {
            isOpenModal: false,
            userDetail: null,
            detail: null,
        };
    },
    components: {
        UserDetail,
        CategoryChat
    },
    methods: {
        handleToggleModal(detail) {
            this.isOpenModal = !this.isOpenModal;
            this.detail = detail;
            this.userDetail = detail.user;
        },
        cancelModal() {
            this.isOpenModal = false;
        }
    }
};
</script>
