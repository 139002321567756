const titles = {
    action: "פעולה",
    addSuccessfully: "נוסף בהצלחה",
    updateSuccessfully: "עודכן בהצלחה",
    errorMessage: "אופס, משהו השתבש, נסה שוב",
    deletedSuccess: "נמחק בהצלחה",
    otpVerifiedMsg: "קוד אימות אומת בהצלחה!",
    otpSentSuccessMsg: "קוד אימות נשלח בהצלחה!",
    registerSuccessMsg: "נרשמת בהצלחה!",
    areYouSureWantToLogout: "האם אתה בטוח שברצונך להתנתק?",
    successLogin: "נכנסת בהצלחה",
    update: "עדכן",
    save: "שמור",
    item: "פריט",
    profile: "פרופיל",
    dashboard: "לוח בקרה",
    enterToSearch: "הזן לחיפוש",
    email: "אימייל",
    phone: "טלפון",
    openVolunteering: "התנדבות פתוחה",
    myVolunteerBasket: "סל ההתנדבות שלי",
    newHelpRequest: "בקשת עזרה חדשה",
    userManagement: "ניהול משתמשים",
    helpRequests: "בקשות עזרה",
    notificationLog: "יומן התראות",
    aboutUs: "אודותינו",
    instructions: "הנחיות",
    regulations: "תקנון",
    accessibilityStatement: "הצהרת נגישות",
    fullName: "שם מלא",
    type: "סוג",
    registrationDate: "תאריך רישום",
    disability: "מוגבלות",
    residentialAddress: "כתובת מגורים",
    currentAddress: "כתובת נוכחית",
    alerts: "התראות",
    actions: "פעולות",
    all: "הכל",
    volunteer: "מתנדב",
    user: "משתמש",
    welcomeMessage: "ברוכים הבאים לאפליקציית האפוד הסגול של עמותת נגישות ישראל - לסיוע לאנשים עם מוגבלויות וקשישים במצבי חירום. כאן תוכלו לסייע לאנשים עם מוגבלויות בכל רחבי הארץ. העזרה שלכם תסייע רבות למשימת האפוד הסגול - לא להשאיר אף אחד מאחור!",
    enterPhoneNumber: "הזן מספר טלפון לקבלת קוד ב-SMS",
    enterVerificationCode: "הזן את קוד האימות",
    verifyCode: "אמת קוד",
    getCode: "קבל קוד",
    registerAs: "הרשם כ",
    clear: "נקה",
    editingMyProfile: "עריכת הפרופיל שלי",
    exitFromSystem: "יציאה מהמערכת",
    refresh: "רענן",
    disableCategoryOption: "הנה בקשות העזרה שלך שנמצאות בתהליך",
    chooseHelpType: "כדי להתחיל, בחר את סוג העזרה שאתה מבקש:",
    toNextStep: "לשלב הבא",
    nextStep: "שלב הבא",
    needRideTo: "אני צריך הסעה ל",
    iAmFrom: "אני מ",
    originAddressLonger: "כתובת המקור שלך חייבת להיות ארוכה מ-5 תווים.",
    destinationAddressLonger: "כתובת היעד שלך חייבת להיות ארוכה מ-5 תווים.",
    wantToBeThereOn: "אני רוצה להיות שם בתאריך",
    selectFutureDate: "נא לבחור תאריך עתידי.",
    selectFutureTime: 'אנא בחר שעה עתידית.',
    requestDetailsDescription: "פרטים ותיאור הבקשה",
    descriptionLonger: "התיאור שלך חייב להיות ארוך מ-5 תווים.",
    untilRequestRelevant: "עד מתי הבקשה רלוונטית",
    personalDetails: "פרטים אישיים",
    fullNameLonger: "שמך המלא חייב להיות ארוך מ-5 תווים.",
    phoneLonger: "מספר הטלפון שלך חייב להיות ארוך מ-5 תווים.",
    residentialAddressLonger: "כתובת המגורים שלך חייבת להיות ארוכה מ-5 תווים",
    createNewHelpRequest: "צור בקשת עזרה חדשה",
    enterValidPhoneNumber: "הכנס מספר טלפון תקין",
    sentTo: "נשלח אל",
    date: "תאריך",
    time: "זמן",
    channel: "ערוץ",
    content: "תוכן",
    usePrevious: "השתמש קודם",
    useCurrent: "השתמש נוכחי",
    status: "סטטוס",
    sendTo: "שלח אל",
    category: "קטגוריה",
    pendingApplicationUserRequestManagement: "ניהול בקשות משתמשים ממתינים",
    perPage: "לפי עמוד",
    arrivalAddress: "כתובת הגעה",
    wayForCommunication: "דרך תקשורת מועדפת",
    whatsApp: "וואטסאפ",
    welcomeDashboard: "ברוך הבא ללוח המחוונים",
    iNeedARideTo: "אני צריך הסעה ל",
    iWantToBeThereOn: "אני רוצה להיות שם בתאריך",
    at: "בשעה",
    detailsAndDescriptionOfRequest: "פרטים ותיאור הבקשה",
    toTheNextStep: "לשלב הבא",
    goToNextStep: "עבור לשלב הבא",
    referenceDescription: "תיאור הפניה",
    comments: "הערות",
    relevantUntil: "רלוונטי עד",
    sorryICantHelpThisTime: "מצטער, אני לא יכול לעזור הפעם",
    imReadyAccept: "אני מוכן לקבל",
    appliedFilters: "סינונים שהוחלו",
    filtering: "מסנן",
    showVolunteers: "הצג מתנדבים",
    cancel: "בטל",
    byCategory: "לפי קטגוריה",
    byTown: "לפי עיר",
    accessibilityAndDisabilityInfo: "מידע על נגישות וצרכי נכות",
    goBack: "חזור",
    details: "פרטים",
    youCanContactMeAt: "תוכל ליצור איתי קשר ב",
    deletedSuccessMsg: "נמחק בהצלחה",
    yes: "כן",
    registerToHelp: "הירשם כדי לעזור",
    joinUs: "הצטרפו אלינו",
    role: "תפקיד",
    switchAs: "החלף בתור",
    submit: "לְהַגִישׁ",
    updateRoleMsg: "התפקיד עודכן בהצלחה",
    newToPurpleVest: "אפוד סגול חדש?",
    signUp: "הירשם",
    alreadyHavingAccount: "כבר יש לך חשבון?",
    signIn: "להיכנס",
    urgency: "דְחִיפוּת",
    inUserCare: "מתנדב",
    description: "תֵאוּר",
    validity: "תוֹקֶף",
    creationDate: "תאריך יצירה",
    systemNotes: "הערות מערכת",
    destinationAddress: "כתובת מגורים",
    originAddress: "כתובת נוכחית",
    volunteesrsManagement: "ניהול מתנדבים",
    no: "לֹא",
    ok: "בְּסֵדֶר",
    noResultFound: "לא נמצאה תוצאה...",
    areYouSureWantToDelete: "האם אתה בטוח שברצונך למחוק?",
    deleteConfirmation: "מחק אישור",
    delete: "לִמְחוֹק",
    select: "לִבחוֹר...",
    register: "לִרְשׁוֹם",
    events: "אירועי חירום",
    manager: "מנהל",
    name: "שם",
    active: "פעיל",
    deActive: "לא פעיל",
    lastStoppedAt: "נעצר לאחרונה בתאריך",
    createdAt: "נוצר בתאריך",
    address: "כתובת",
    back: "חזור",
    clickHereForEmergency: "לחץ כאן לחירום",
    sendSuccessRequestForEmergency: "בקשת חירום נשלחה בהצלחה",
    addNew: "הוסף חדש",
    title: "כותרת",
    radius: "רדיוס",
    saveAndClose: "שמור וסגור",
    location: "מיקום",
    isActive: "האם פעיל?",
    accept: "אשר",
    reject: "דחה",
    updateStatusMessage: "הסטטוס עודכן בהצלחה",
    residential_address: "כתובת מגורים",
    arrival_address: "כתובת הגעה",
    communication: "תקשורת",
    assign: "הקצה",
    assignSuccessMessage: "הוקצה בהצלחה",
    emergencyRequests: "בקשות חירום",
    closeEmergencyRequest: "סגור בקשת חירום",
    closeEmergencyRequestMessage: "בקשת חירום נסגרה בהצלחה",
    manageSingleHelpRequest: "נהל בקשת עזרה יחידה",
    manageEventHelpRequest: "נהל בקשת עזרה לאירוע",
    event: "אירוע",
    confirmation: "אישור",
    request: "בקשה",
    assigned: "שויך",
    pendingApproval: "ממתין לאישור",
    pending: "ממתין",
    notFoundVolunteer: "לא נמצא מתנדב",
    notFoundUser: "המשתמש לא נמצא",
    close: "סגור",
    areYouSureWantToCloseRequest: "האם אתה בטוח שברצונך לסגור את הבקשה?",
    areYouSureWantToRejectRequest: "האם אתה בטוח שברצונך לדחות את הבקשה הזו? לא תוכל לעזור למשתמש זה שוב.",
    areYouWantToCompleteRequest: "האם אתה בטוח שברצונך להשלים את הבקשה הזו?",
    completeRequestSuccessfully: "הבקשה הושלמה בהצלחה.",
    eventHelpRequestCompletedSuccessfully: "בקשת עזרה לאירוע הושלמה בהצלחה",
    rejectRequestSuccessfully: "הבקשה נדחתה בהצלחה.",
    categories: "קטגוריות",
    author: "מחבר",
    eventVolunteerHelpRequest: "בקשת עזרה למתנדבים באירוע",
    eventStatusVerifyMessage: "אנא עיין בבקשת העזרה ובחר לאשר או לדחות אותה.",
    helpRequestDetails: "פרטי בקשת עזרה",
    from: "מִן",
    message: "הוֹדָעָה",
    eventRequestConfirmationMessage: "אירוע התרחש באזור שלך. האם אתה מוכן לעזור?",
    accepted: "מְקוּבָּל",
    rejected: "נִדחֶה",
    requestExpired: "פג תוקף הבקשה",
    requestInvalid: "הבקשה לא חוקית",
    assignRoles: "הקצאת תפקידים",
    forbiddenTitle: "403 אסור",
    forbiddenMessage: "הגישה שלך הוגבלה. אם אתה חושב שזו טעות, פנה לתמיכה.",
    validUntil: "בתוקף עד",
    footerText: "תוכנן על ידי מור אלון פותח על ידי",
    codeForIsrael: "מבית נגישות ישראל, כל הזכויות שמורות. על בסיס גרסת בטא של",
    checkerSoft: "תוכנת בודק",
    contactUs: "צור קשר",
    subject: "נושא",
    submitYourQueryHere: "שלח את השאילתה שלך כאן",
    submitSuccessfully: "נשלח בהצלחה",
    contact: "צור קשר",
    iWantToVolunteer: "אני רוצה להתנדב.",
    iAmPerson: "אני אדם בעל מוגבלות או אדם מבוגר.",
    requests: "בקשות",
    totalBuddies: "סה״כ חברים",
    totalActivities: "סה״כ פעילויות",
    totalRequests: "סה״כ בקשות",
    volunteerJoined: "מתנדב הצטרף ב-30 הימים האחרונים",
    personWithDisabilities: "אדם עם מוגבלות",
    volunteers: "מתנדבים",
    coverageMap: "מפת כיסוי",
    fromDate: "מתאריך",
    toDate: "עד היום",
    searchLocation: "חפש מיקום...",
    typeToSearch: "הקלד לחיפוש...",
    query: "שאילתה",
    send: "שלח",
    sms: "SMS",
    sendMessage: "שלח הודעה",
    users: "משתמשים",
    okSend: "בסדר, שלח",
    sendSuccessMessage: "שלח הודעה בהצלחה.",
    sendingMessage: "שליחת הודעה",
    typeMessage: "הקלד את הודעתך כאן...",
    sendMessageConfirmMsg1: "אתה עומד לשלוח הודעה ל",
    sendMessageConfirmMsg2: "משתמשים, אתה בטוח?",
    waitingMessage1: "מחכה ל",
    waitingMessage2: "שניות...",
    settings: "הגדרות",
    autoAssign: "הקצאה אוטומטית",
    tasks: "משימות",
    complete: "לְהַשְׁלִים",
    completeRequestMessage: "האם אתה בטוח שברצונך להשלים את בקשת העזרה הזו?",
    closeRequestMessage: "האם אתה בטוח שברצונך לסגור את בקשת העזרה הזו?",
    helpRequestSuccessMessage: "בקשת העזרה נשלחה בהצלחה",
    helpRequestCompleteMessage: "סטטוס בקשת העזרה עודכן בהצלחה",
    helpRequestClosedMessage: "בקשת העזרה נסגרה בהצלחה",
    open: "פתוח",
    closed: "סגור",
    expired: "פג תוקף",
    completed: "הושלם",
    inTreatment: "בטיפול",
    info: "מידע",
    color: "צֶבַע",
    waitingForApproval: "ממתין לאישור מנהל המערכת",
    userPendingInfo: "המשתמש שביקש עזרה חירום עדיין ברשימה הממתינה.",
    userAssignedInfo: "אנו מציגים את הלחצן מוקצה כאשר בעל אירוע מקצה מתנדב למשתמש.",
    userWaitingForAdminApproval: "כאשר בעל האירוע מקצה את המתנדב למשתמש, הוא עובר לאישור מנהל ואנו רואים כפתור צבע אדום בהמתנה.  אם המשתמש נמצא ברשימת החברים של ה-voluneet, אין צורך באישור מנהל והוא מקבל הקצה ישירות. גם כאשר מנהל מערכת מקצה מתנדב למשתמש, הוא או היא מקבלים הקצאה ישירה.",
    volunteerPendingInfo: "כאשר אנו יוצרים אירוע נשלחת בקשה לכל המתנדבים ברדיוס האירוע.  כאילו אתה מוכן לעזור? עד שהמתנדב יקבל או ידחה את הבקשה, היא נשארת תלויה ועומדת.",
    volunteerAcceptedInfo: "כשבעלי אירועים או מתנדבים מקבלים בקשות, אנחנו נראים מקובלים.",
    volunteerRejectedInfo: "כאשר בעלי אירועים או מתנדבים דוחים בקשות, אנו נראים נדחים.",
    volunteerAssignedInfo: "כאשר בעל האירוע מקצה משתמש למתנדב שקיבל בקשת עזרה, אנו רואים את הלחצן מוקצה.",
    volunteerWaitingForApproval: "כאשר בעל האירוע מקצה את המשתמש למתנדב המקובל, הוא עובר לאישור מנהל ואנו רואים כפתור צבע אדום בהמתנה.  אם המתנדב נמצא ברשימת החברים של המשתמש, אין צורך באישור מנהל והוא מקבל הקצה ישירות. גם כאשר מנהל מערכת מקצה משתמש למתנדב, הוא או היא מקבלים הקצאה ישירה.",
    acceptDropdown: "קבל תפריט נפתח",
    acceptDropdownInfo: "כאשר המתנדב לא מקבל או דוחה את הבקשה שנשלחה אבל הוא רוצה לעשות את זה אבל עסוק בעבודה מסוימת אז בעל האירוע מתקשר למתנדב והוא מוכן לקבל את הבקשה אז בעל האירוע עצמו מקבל את הבקשה.",
    rejectDropdown: "דחייה נפתחת",
    rejectDropdownInfo: "כאשר המתנדב לא מקבל או דוחה את הבקשה שנשלחה אבל הוא רוצה לעשות את זה אבל עסוק בעבודה כלשהי אז בעל האירוע מתקשר למתנדב והוא מוכן לדחות את הבקשה אז בעל האירוע עצמו דוחה את הבקשה.",
    activeEvents: "אירועים פעילים",
    deActiveEvents: "אירועים לא פעילים",
    volunteerAcceptReject: "האם אתה בטוח שתרצה להפוך משתמש זה לזמין לסיוע?",
    buddies: "באדי׳ז",
    chat: "צ'אט",
    volunteerCompletedInfo: "אנו רואים את הסטטוס הושלם כאשר בעל האירוע או המתנדב משלימים את הבקשה שהוקצתה.",
    userRejectedInfo: "כאשר בעל האירוע והמתנדב דוחים את בקשת ההקצאה, נוכל שוב להקצות מתנדב חדש לאותו משתמש.",
    volunteerRejectInfo: "הוא נפתח מחדש כאשר בעל האירוע והמתנדב דוחים את הבקשה שהוקצתה.",
    searchAddress: "חפש כתובת...",
    declineRequest: "לדחות בקשה",
    youDeclineRequest: "דחית את הבקשה בהצלחה",
    typeYourMessageHear: "הקלד את הודעתך כאן...",
    messageQuestion: "שלום, האם אתה רוצה עזרה למשתמש 1?",
    yep: "כֵּן",
    helpRequestAcceptMessage: "בקשת עזרה אושרה בהצלחה",
    deleteUserMessage: "האם אתה בטוח שברצונך למחוק משתמש?",
    noApplicationFound: "לא נמצאו אפליקציות",
    noEventsFound: "לא נמצאו אירועים",
    applicationStatus: "סטטוס בקשה",
    count: "לִסְפּוֹר",
    amount: "כמות",
    displayExistingUserStatus: "הטבלה הבאה מציגה את מצב היישומים הקיימים של המשתמש",
    displayExistingUserEvents: "הטבלה הבאה מציגה אירועים שהוקצו למשתמש הקיימים",
    volunteerInTreatment: "בקשות בטפול",
    openVolunteerList: "ניתן לבחור משתמש אחר אליו ישובצו המשימות בטיפול",
    userHelpRequestAccepted: "בקשת עזרה למשתמש התקבלה בהצלחה",
    disableDisablityOption: "ראשית עליך לעדכן את הנכות לאחר מכן תוכל לבצע את הפעילות",
    sendSuccessMessageToUser: "ההודעה נשלחה בהצלחה למשתמש",
    isAutoAssign: "האם הקצאה אוטומטית?",
    minutes: "דקות",
    km: "ק'מ",
    region: "אֵזוֹר",
    country: "מְדִינָה",
    updatePermissionMsg: "ההרשאה עודכנה בהצלחה",
    permissionDeleteConfirmMsg: "האם אתה בטוח שברצונך למחוק הרשאה זו?",
    deletePermissionMsg: "ההרשאה נמחקה בהצלחה",
    permission: "רְשׁוּת",
    selectCountry: "בחר מדינה",
    search: "חפש",
    selectRegion: "בחר אזור",
    emailRequired: "שדה דוא ל נדרש",
    disabilityRequired: "שדה נכות חובה",
    residentialAddressRequired: "שדה כתובת מגורים נדרש",
    export: "יְצוּא",
    as: "כְּמוֹ",
    or: "או",
    expandSearchRadius: "הרחב רדיוס חיפוש כאשר לא נמצאים מתנדבים",
    ifNoResponseCallTheVolunteerAfter: "בחוסר מענה, הזמן המתנדב הבא לאחר",
    column: "עמוד",
    additionalWaysToContactUs: "דרכים נוספות ליצור עימנו קשר",
    alsoSendEmailTo: "ניתן גם לשלוח דואר אלקטרוני לכתובת support@purple-vest.net",
    newRegistration: "הרשמה חדשה?",
    imPersonAskingForHelp: "אני אדם שמבקש עזרה",
    imInterestedInVolunteering: "אני מעוניין להתנדב",
    addPermissionToAnotherArea: "הוסף הרשאה לאיזור נוסף",
    registrationSuccessMessage: "החשבון שלך נרשם בהצלחה. אנא המתן לאישור מנהל המערכת.",
    userActiveConfirmation: "האם אתה בטוח שברצונך להפעיל משתמש זה?",
    userDeactiveConfirmation: "האם אתה בטוח שברצונך להשבית את המשתמש הזה?",
    translate: "לְתַרְגֵם",
    roles: "תפקידים",
    approved: "מְאוּשָׁר",
    map: "מַפָּה",
    edit: "לַעֲרוֹך",
    assignVolunteers: "הקצאת מתנדבים",
    updateVolunteerAssignMessage: "מתנדב הוקצה בהצלחה",
    accountActivatedSuccessMessage: "חשבון משתמש הופעל בהצלחה",
    accountDeActivatedSuccessMessage: "חשבון המשתמש הושבת בהצלחה",
    selectVolunteer: "בחר מתנדב",
    thisVolunteerInTreatmentRequestsIs: "זה מתנדב בטיפול בקשות הוא",
    openVolunteerListStatusMessage: "זוהי רשימת מתנדבים פתוחה. אתה יכול לבחור את המתנדב הזה כדי להקצות את כל הבקשות הקיימות בטיפול.",
    deleteVolunteerConfirmMessage: "האם אתה בטוח שברצונך למחוק מתנדב?",
    managerTotalEventsIs: "המנהל הזה כולל אירועים",
    openManagerList: "זוהי רשימת מנהלים נוספת. אתה יכול לבחור במנהל זה כדי להקצות את כל האירועים הפעילים הקיימים.",
    selectManager: "בחר מנהל",
    volunteerAssignedEvent: "בקשות משובצות תחת אירועים",
    openVolunteerListStatusMessageForEvent: "זוהי רשימת מתנדבים פתוחה. אתה יכול לבחור את המתנדב הזה כדי להקצות משתמש באירוע.",
    registrationAskingForHelp: "הרשמת מבקש עזרה",
    show: "הצג",
    volunteerReject: "האם אתה בטוח שתרצה להפוך משתמש זה ללא זמין לסיוע?",
    switchToVolunteer: "החלף למצב מתנדב",
    switchToUser: "החלף למצב מבקש עזרה",
    switchToManager: "עבור למצב מנהל",
    workAddress: "כתובת עבודה",
    activated: "מוּפעָל",
    deactivated: "מושבת",
    deleted: "נמחק",
    sendSMSSuccessMessage: "שליחת SMS בהצלחה",
    organizations: "ארגונים",
    logo: "לוגו",
    organization: "אִרגוּן",
    selectOrganization: "בחר ארגון",
    updateOrganizationMessage: "עדכון הארגון בהצלחה",
    link: "לְקַשֵׁר",
    linkCopyMessage: "עותק קישור בהצלחה",
    detail: "פְּרָט",
    myHelpRequest: "בקשת העזרה שלי",
    thereAreNoMoreHelpRequests: "אין בקשות עזרה פתוחות",
    passwordConfirmation: "אישור סיסמה",
    password: "סִיסמָה",
    addressUpdateAlert: "אם תעדכן את הכתובת, לכתובת הקודמת יש בקשות לעזרה שנוצרו. האם תרצה לסגור אותן?",
    addressUpdateAlertVolunteer: "אם תעדכן את הכתובת, לכתובת הקודמת יש בקשת עזרה עם ממונה שנוצרה. האם תרצה לסגור אותה?",
    warningForAddressUpdateVolunteer: "אם עדכון כתובת יש תפקיד נוסף כמתנדב בקשת העזרה של המוקצה שלך תיסגר אוטומטית על ידי המערכת.",
    warningForAddressUpdate: "אם עדכון כתובת יש תפקיד נוסף כמשתמש בקשת העזרה שלך שנמצאת בתהליך או בהמתנה תיסגר אוטומטית על ידי המערכת.",
    arrivalTime: "זמן הגעה",
    requestInProgress: "הערה: הגשת את הבקשה בשעה {time}, שהיא {status}.",
    inProgress: "בתהליך",
    lastUpdatedAt: "עודכן לאחרונה",
    unassign: "בטל הקצאה",
    numberOfVolunteer: "מספר המתנדבים",
    organizationName: "שם הארגון",
    purpleVestLogo: "לוגו האפוד הסגול",
    changeLanguage: "שינוי שפה",
    myProfile: "הפרופיל שלי",
    hebrew: "עִברִית",
    english: "English",
    needHelp: "צריך עזרה",
    volunteerRegisterMessage: "ההרשמה התקבלה, צוות המערכת יבדוק את הפרטים וייצור עמך קשר בהקדם",
    openHelpRequests: "בקשות פתוחות",
    low: "נמוך",
    high: "גבוה",
    medium: "בינוני",
    restoreUserMessage: "האם אתה בטוח שברצונך לשחזר את המשתמש?",
    restoredSuccessMsg: "שוחזר בהצלחה"
};

export default {
    locale: "he-IL",
    messages: {
        title: titles
    },
};
