import {client} from "./Request";
import SecureLS from "secure-ls";
import serverConfig from '../../server.config.json';
import moment from "moment-timezone";
import {parsePhoneNumberFromString} from "libphonenumber-js";

export const DEFAULT_STORAGE = new SecureLS({
    encodingType: 'base64',
    isCompression: false
});

// Set the storage prefix based on the environment
const env = process.env.VUE_APP_ENV || "development";

export const STORAGE_PREFIX = serverConfig.storagePrefix[env];

export function setStorage(key, value) {
    DEFAULT_STORAGE.set(`${STORAGE_PREFIX}.${key}`, value);
}

export function getStorage(key) {
    try {
        return DEFAULT_STORAGE.get(`${STORAGE_PREFIX}.${key}`) || null;
    } catch (error) {
        return null;
    }
}

export function removeStorage(key) {
    return DEFAULT_STORAGE.remove(`${STORAGE_PREFIX}.${key}`);
}

export function hasAuthUser() {
    let user = getStorage("auth");
    user = ( ( user && user !== '' ) ? JSON.parse(user) : {} );
    if ( !user ) {
        return false;
    }
    return !!( user.token );
}

export function getAuthUser() {
    let user = getStorage(`auth`);

    user = ( ( user && user !== '' ) ? JSON.parse(user) : {} );

    if ( Object.keys(user).length <= 0 ) {
        removeStorage(`auth`);
        return {};
    }

    return user;
}


export function hasRole(role) {
    let user = getStorage(`auth`);

    user = ( ( user && user !== '' ) ? JSON.parse(user) : {} );

    return user?.role == role;
}

export function dateFormat(date = null, format) {
    if ( !date ) {
        return null;
    }
    
    return moment(date, "DD-MM-YYYY", true).format(format);
}

export function utcDateToLocalDate(date, toFormat = 'DD-MM-YYYY HH:mm A', fromFormat = 'YYYY-MM-DD HH:mm:ss') {
    if ( !date ) {
        return null;
    }

    const utcDate = moment.utc(date, fromFormat);
    return utcDate.clone().tz(moment.tz.guess()).format(toFormat);
}

export function refresh() {
    window.location.reload(true);
}

export function refreshToHome() {
    window.location.reload(true);
}

export function displayPhoneNumber(phone) {
    const locale = getStorage(`locale`);
    return phone;
    // return locale == 'en-US' ? phone : phone.split("").reverse().join("");
}

export function handleSyncRequestLoader({dispatch, getters}, baseURL) {
    client.interceptors.request.use(
      config => {
          config.baseURL = `${baseURL}`;
          if ( config.method !== "get" && ( config.data && config.data.ignore_request > 0 ) ) {
              return config;
          } else if ( config.method === "get" && ( config.params && config.params.ignore_request > 0 ) ) {
              return config;
          }

          dispatch('loader', true);
          return config;
      },
      error => {
          return Promise.reject(error);
      }
    );

    client.interceptors.response.use(
      ({data}) => {
          dispatch('loader', false);
          return Promise.resolve(data);
      },
      error => {
          dispatch('loader', false);
          if ( error.response && error.response.status === 401 ) {
              const role = getters?.user.role;
              alert('Your session has expired. Please log in again.');

              dispatch('setUnAuthorized', true);
              dispatch('user', null);

              if ( role !== 'admin' ) {
                  window.location.href = "/login";
              } else {
                  window.location.href = "/admin/login";
              }
          }
          return Promise.reject(error);
      }
    );
}
