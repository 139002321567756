<template>
    <div class="main-block request-management">
        <div class="div col-12 p-0 d-flex align-items-start">
            <h4 class="">{{ $t('title.organizations') }}</h4>
        </div>
        <div class="scrollable-block">
            <div class="col-12 row mt-3 m-0 p-0" style="min-height: 90%">
                <crud-table :columns="columns" :list-url="listUrl" ref="organization-table" v-on:refresh="resetFilter">
                    <template slot>
                        <router-link :to="{name:'adminOrganizationForm',params:{operation:'add'}}"
                                     class="btn btn-primary">
                            <b-icon icon="plus" class="icon-style"></b-icon>
                            {{ $t('title.addNew') }}
                        </router-link>
                    </template>
                    <template #cell(link)="{item}">
                        <b-button
                            variant="outline-primary"
                            class="mr-1" @click="copyLink(item.slug)">
                            {{ volunteerRegistrationLink(item.slug) }}
                            <b-icon icon="file-check-fill" class="icon-style"></b-icon>
                        </b-button>
                    </template>
                    <template #cell(author)="{item}">
                        {{ ( item.author || {} ).full_name }}
                    </template>
                    <template #cell(logo)="{item}">
                        <img :src="getImageUrl(item.logo)" alt="Preview" class="img-thumbnail" width="150">
                    </template>
                    <template #cell(created_at)="{item}">
                        {{ item.created_at ? $global.utcDateToLocalDate(item.created_at) : null }}
                    </template>
                    <template #cell(actions)="{item}">
                        <router-link :to="{name:'adminOrganizationForm',params:{id:item.id,operation:'edit'}}"
                                     v-b-popover.hover.top="$t('title.edit')"
                                     variant="outline-primary"
                                     class="mr-1">
                            <b-icon font-scale="1.5" icon="pencil-square" class="icon-style"></b-icon>
                        </router-link>
                    </template>
                </crud-table>
            </div>
        </div>
    </div>
</template>

<script>

import {request} from "@/Util/Request";

const FILTER_FORM = {};

const COLUMN_STATE = (self) => [
    {key: 'actions', label: self.$t('title.actions')},
    {key: 'name', label: self.$t('title.organizationName'), sortable: true},
    {key: 'logo', label: self.$t('title.logo'), sortable: false},
    {key: 'link', label: self.$t('title.link'), sortable: true},
    {key: 'author', label: self.$t('title.author'), sortable: true},
    {key: 'users_count', label: self.$t('title.numberOfVolunteer'), sortable: true},
    {key: 'created_at', label: self.$t('title.createdAt'), sortable: true},
];

export default {
    data() {
        return {
            operation: '',
            listUrl: '/admin/organizations',
            filters: {
                ...FILTER_FORM
            },
        };
    },
    methods: {
        async copyLink(slug) {
            const link = this.volunteerRegistrationLink(slug);
            try {
                await navigator.clipboard.writeText(link);
                this.notifySuccessWithMsg(this.$t('title.linkCopyMessage'));
                console.log("Copied:", link); // Logs success (optional)
            } catch (err) {
                console.error("Copy failed:", err);
            }
        },
        volunteerRegistrationLink(slug) {
            return `${process.env.VUE_APP_URL}${this.$router.resolve({name: 'volunteerRegistration'}).href.replace(/^\/+/, '')}/${slug}`;
        },
        getImageUrl(path) {
            if ( !path ) {
                return 'https://via.placeholder.com/150';
            } // Default placeholder
            return `${process.env.VUE_APP_BACKEND_URL}storage/${path}`;
        },
        handleFilter() {
            this.$refs['organization-table'].handleFilter(this.filters);
        },
        resetFilter() {
            this.filters = {...FILTER_FORM};
        },
    },
    computed: {
        columns() {
            return COLUMN_STATE(this);
        }
    }
};
</script>
