<template>
    <div>
        <b-modal v-model="isOpenModal" id="assignFreeVolunteerModal"  :hide-header="true"
                 :hide-footer="true">
            <div class="div col-12 p-0 d-flex align-items-start">
                <h4 class="">{{ $t('title.assignVolunteers') }}</h4>
            </div>
            <b-form @submit.prevent="handleSubmitRequest" class="col-12 col-md-12 mx-auto p-0">
                <div class="mb-3">
                    <label for="type">{{ $t('title.volunteers') }}</label>
                    <treeselect :multiple="false" v-model="formFields.volunteer_id"
                                    :options="volunteers"
                                    :placeholder="$t('title.select')"
                                    :clearOnSelect="true"
                                    id="type"></treeselect>
                 </div>
                <div class="modal-footer d-flex mr-2 gap-2">
                    <button type="submit" :disabled="(formFields.volunteer_id ? false : true) || isSubmit" class="btn main-btn col m-0">
                         <i class="fa fa-spin fa-spinner" v-if="isSubmit"></i> {{ $t('title.submit') }}
                    </button>
                    <button type="button" class="btn sec-btn col m-0" @click="cancelModal">
                        {{ $t('title.cancel') }}
                    </button>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import {request} from "@/Util/Request";
import Error from "@/Util/Error";

const FORM_STATE = {
    volunteer_id: null
}
export default {
    data() {
        return {
            isOpenModal: false,
            applicationId: null,
            formFields: { ...FORM_STATE },
            formErrors: new Error({}),
            volunteers: [],
            isSubmit: false
        };
    },
    methods: {
        async handleToggleModal(id, volunteers) {
            this.applicationId = id;
            try {
                const response = await request({
                    method: 'get',
                    url: `admin/user/open-volunteers`,
                });

                const { data } = response;

                this.volunteers = data
                    .map((volunteer) => ({
                        id: volunteer.id,
                        label: volunteer.full_name.toLowerCase() + ' (' + volunteer.phone + ')', // Optional transformation of value
                    }));
            } catch (error) {
                this.notifyError();
            }
            this.isOpenModal = !this.isOpenModal;
        },
        async handleSubmitRequest() {
            try {
                this.isSubmit = true;
                const response = await request({
                    method: 'post',
                    url: `admin/application/assigned/${ this.applicationId }`,
                    data: this.formFields
                });
                if(response) {
                    this.notifySuccessWithMsg(`${ this.$t('title.updateVolunteerAssignMessage') }`);
                    this.$emit('refreshTable');
                    setTimeout(() => {
                        this.isSubmit = false;
                        this.cancelModal();
                    }, 1000);
                    
                }
                this.formErrors = new Error({});
                
            } catch (error) {
                this.isSubmit = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }

        },
        cancelModal() {
            this.isOpenModal = false;
            this.formErrors = new Error({});
            this.formFields = { ...FORM_STATE };
        }
    }
};
</script>
