import { getAuthUser, setStorage } from "@/Util/auth";

export default {
    data() {
        return {};
    },
    mounted() {
        if ( getAuthUser() && this.user ) {
            if ( this.user?.role !== 'admin' ) {
                if ( this.$global.hasRole('volunteer') ) {
                    this.$router.push({ name: 'OpenVolunteering' });
                } else {
                    this.$router.push({ name: 'Dashboard' });
                }
            } else {
                this.$router.push({ name: 'AdminCoverageMap' });
            }
        }
    },
    methods: {
        async redirectingUserPage(data) {
            await setStorage("auth", JSON.stringify(data));
            await this.$store.dispatch("user", data);
            await this.$store.dispatch("setUnAuthorized", false);

            if ( data.role == 'admin' ) {
                await this.$router.push({ name: "AdminCoverageMap" });
            } else {
                if(!data.disability_id && data && data.roles.includes('user')) {
                    await this.$router.push({ name: 'Profile' });
                } else {
                    if ( this.$global.hasRole('volunteer') ) {
                        await this.$router.push({ name: 'OpenVolunteering' });
                    } else {
                        await this.$router.push({ name: 'Dashboard' });
                    }
                }

            }
        }
    }
};
