<template>
    <div class="main-block request-management">
        <!--        <div class="div col-12 p-0 d-flex align-items-start">-->
        <!--            <h4 class="">{{ $t('title.volunteesrsManagement') }}</h4>-->
        <!--        </div>-->
        <div class="scrollable-block">
            <div class="col-12 row m-0 p-0" style="min-height: 90%">
                <crud-table :columns="columns" :list-url="listUrl" ref="application-table" v-on:refresh="resetFilter">
                    <template #filter-slot>
                        <b-col lg="3" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.category')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" :placeholder="$t('title.select')" @input="handleFilter"
                                            v-model="filters.categories"
                                            class="form-input"
                                            :options="dropdowns.categories" />
                            </b-form-group>
                        </b-col>
                        <b-col lg="3" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.status')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" :placeholder="$t('title.select')"
                                            @input="handleFilter" v-model="filters.statuses"
                                            class="form-input"
                                            :options="dropdowns.statuses" />
                            </b-form-group>
                        </b-col>
                    </template>
                    <template #cell(userCare)="{item}">
                        <a href="#" @click.prevent="openVolunteerDetailModal(item.volunteer)">{{ ( item.volunteer || {} ).full_name }}</a>
                    </template>
                    <template #cell(urgency)="{item}">
                        <span v-if="( item.application || {} ).priority == 0" class="text-info">Low</span>
                        <span v-else class="text-danger">High</span>
                    </template>
                    <template #cell(status)="{item}">
                        <span v-if="item.is_approved == 2 && item.volunteer"> 
                            {{$t('title.rejected')}}
                        </span>
                        <span v-else>
                            {{ $t(`title.${ _.camelCase(item.application.status) }`) }}
                        </span>
                    </template>
                    <template #cell(description)="{item}">
                        {{ item.application.description }}
                    </template>
                     <template #cell(destination_address)="{item}">
                        {{
                            ( item.application.destination_address ) ?? ( item.application.user || {} ).residential_address
                        }}
                    </template>
                    <template #cell(origin_address)="{item}">
                        {{ ( item.application.origin_address ) ?? ( item.application.user || {} ).arrival_address }}
                    </template>
                    <template #cell(category)="{item}">
                        {{ ( item.application.category || {} ).name }}
                    </template>
                    <template #cell(name)="{item}">
                        <a href="#" @click.prevent="openUserDetailModal(item.application.user)">{{ ( item.application.user || {} ).full_name }}</a>
                    </template>
                    <template #cell(phone)="{item}">
                        <div dir="ltr">
                            {{
                                ( item.application.user || {} ).phone ? $global.displayPhoneNumber(( item.application.user || {} ).phone) : ""
                            }}
                        </div>
                    </template>
                    <template #cell(email)="{item}">
                        {{ ( item.application.user || {} ).email }}
                    </template>
                    <template #cell(disability)="{item}">
                        {{ ( item.application.user?.disability || {} ).name }}
                    </template>
                    <template #cell(system_notes)="{item}">
                        {{ ( item.application || {} ).system_notes }}
                    </template>
                    <template #cell(creation_date)="{item}">
                        {{ $global.utcDateToLocalDate(item.application.created_at) }}
                    </template>
                    <template #cell(validity)="{item}">
                        {{ item.application.expiration_date }}
                    </template>
                    <template #cell(actions)="{item}">
                        <b-button v-if="item.volunteer && item.application.status == 'in treatment'"
                                  variant="outline-primary"
                                  :disabled="loader"
                                  class="mr-1" @click="openCloseRequestConfirmationModal(item.application.id);">
                            <b-icon icon="x-circle-fill" class="icon-style"></b-icon>
                        </b-button>
                    </template>
                </crud-table>
            </div>
        </div>
        <VolunteerDetailModel ref="volunteerDetailModal"/>
        <UserDetailModel ref="userDetailModal"/>
        <ReuqestClosedConfirmationModel ref="reuqestClosedConfirmationModal" @refresh="refreshTable"/>
    </div>
</template>

<script>

import { request } from "@/Util/Request";
import { mapState } from "vuex";
import Error from "@/Util/Error";
import VolunteerDetailModel from "@/view/pages/admin/settings/tasks/modal/VolunteerDetailModel"
import UserDetailModel from "@/view/pages/admin/settings/tasks/modal/UserDetailModel"
import ReuqestClosedConfirmationModel from "@/view/pages/admin/settings/tasks/modal/ReuqestClosedConfirmationModel"

const FILTER_FORM = {
    roles: null,
    statuses: null
};

const COLUMN_STATE = (self) => [
    { key: 'actions', label: self.$t('title.actions') },
    { key: 'category', label: self.$t('title.category'), sortable: true },
    { key: 'status', label: self.$t('title.status'), sortable: true },
    { key: 'urgency', label: self.$t('title.urgency'), sortable: true },
    { key: 'userCare', label: self.$t('title.inUserCare'), sortable: true },
    { key: 'description', label: self.$t('title.description'), sortable: true },
    { key: 'validity', label: self.$t('title.validity'), sortable: true },
    { key: 'creation_date', label: self.$t('title.creationDate'), sortable: true },
    { key: 'system_notes', label: self.$t('title.systemNotes'), sortable: true },
    { key: 'name', label: self.$t('title.fullName'), sortable: true },
    { key: 'phone', label: self.$t('title.phone'), sortable: true },
    { key: 'email', label: self.$t('title.email'), sortable: true },
    { key: 'disability', label: self.$t('title.disability'), sortable: true },
    { key: 'destination_address', label: self.$t('title.destinationAddress'), sortable: true },
    { key: 'origin_address', label: self.$t('title.originAddress'), sortable: true },
];

export default {
    data() {
        return {
            operation: '',
            listUrl: '/admin/assigned-closed-application-users',
            filters: {
                ...FILTER_FORM
            },
            dropdowns: {
                categories: [],
                statuses: []
            },
            formErrors: new Error({}),
        };
    },
    components: {
        VolunteerDetailModel,
        UserDetailModel,
        ReuqestClosedConfirmationModel
    },
    mounted() {
        this.getDropdowns();
    },
    methods: {
        openVolunteerDetailModal(volunteer) {
            this.$refs['volunteerDetailModal'].handleToggleModal(volunteer);
        },
        openUserDetailModal(user) {
            this.$refs['userDetailModal'].handleToggleModal(user);
        },
        openCloseRequestConfirmationModal(applicationId) {
            this.$refs['reuqestClosedConfirmationModal'].handleToggleModal(applicationId);
        },
        refreshTable() {
            this.$refs['application-table'].handleRefreshList();
        },
        handleFilter() {
            this.$refs['application-table'].handleFilter(this.filters);
        },
        resetFilter() {
            this.filters = { ...FILTER_FORM };
        },
        async getDropdowns() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/application`,
                });

                const { categories, statuses } = response;

                this.dropdowns.categories = categories;
                this.dropdowns.statuses = _.map(statuses, (i) => {
                    return {
                        id: i.id,
                        label: this.$t(`title.${ _.camelCase(i.label) }`)
                    };
                });

            } catch (error) {
            }
        },
    },
    computed: {
        columns() {
            return COLUMN_STATE(this);
        },
        ...mapState(['loader'])
    }
};
</script>
