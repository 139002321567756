<template>
    <div>
        <b-modal v-model="isOpenModal" id="requestAcceptRejectModal" :hide-header="true"
                 :hide-footer="true">
            <p>{{ (status == 'accept') ? $t('title.volunteerAcceptReject') : $t('title.volunteerReject') }}</p>
            <div class="modal-footer d-flex mr-2 gap-2">
                <button type="button" class="btn main-btn col m-0" @click="handleSubmitRequest">{{
                        $t('title.yes')
                    }}
                </button>
                <button type="button" class="btn sec-btn col m-0" @click="cancelModal">
                    {{ $t('title.cancel') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import { request } from "@/Util/Request";
import Error from "@/Util/Error";

export default {
    data() {
        return {
            isOpenModal: false,
            status: null,
            volunteerEventRequestId: null,
            formErrors: new Error({}),
        };
    },
    methods: {
        handleToggleModal(id, status) {
            this.isOpenModal = !this.isOpenModal;
            this.status = status;
            this.volunteerEventRequestId = id;
        },
        async handleSubmitRequest() {
            try {
                this.cancelModal();
                const response = await request({
                    method: 'put',
                    url: `/event-requests/${ this.volunteerEventRequestId }`,
                    data: {
                        'is_ready_to_help': ( this.status == 'accept' ) ? 1 : 0
                    }
                });
                if ( response ) {
                    this.notifySuccessWithMsg(`${ this.$t('title.volunteer') } ${ this.$t('title.updateStatusMessage') }`);
                    this.$emit('refreshVolunteerList');
                    this.formErrors = new Error({});
                }
            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    if(this.formErrors.has('message')) {
                        this.notifyErrorWithMsg(this.formErrors.first('message'));
                    }

                    if(this.formErrors.has('error')) {
                        this.notifyErrorWithMsg(this.formErrors.first('error'));
                    }
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        cancelModal() {
            this.isOpenModal = false;
        }
    }
};
</script>
