<template>
    <div>
        <div
            class="container-fluid p-3 h-100"
            :locale="locale"
            :dir="isRTL ? 'rtl' : 'ltr'"
        >
            <div :class="!isUnAuthorized ? 'd-flex gap-3 flex-column flex-md-row' : 'd-flex gap-3 h-100'">
                <div :class="!isUnAuthorized ? 'header-sidebar' : ''">
                    <div :class="user && !$global.hasRole('admin') ? 'big-header sticky':'sticky'">
                        <AppHeader class="mb-3" @toggle-sidebar="toggleSidebar" />
                        <SideBar
                            v-if="!isUnAuthorized"
                            :is-expanded="isExpanded"
                            @toggle-sidebar="toggleSidebar"
                        />
                    </div>
                </div>
                <div class="flex-grow-1 special-block">
                    <router-view></router-view>
                </div>
            </div>
            <AppFooter class="mt-3"/>
        </div>
    </div>
</template>

<script>
import Vue from 'vue';
import VueI18n from 'vue-i18n';
import english from './locales/en-US';
import AppHeader from './components/AppHeader.vue';
import AppFooter from './components/AppFooter';
import SideBar from './components/SideBar.vue';
import { mapState } from 'vuex';
import Hebrew from "./locales/he-IL";
import { request } from "@/Util/Request";
import { getAuthUser } from "@/Util/auth";

// Localization setup
const locales = {
    'en-US': english,
    'he-IL': Hebrew
};

Vue.use(VueI18n);

export const i18n = new VueI18n({
    locale: 'he-IL',
    fallbackLocale: 'he-IL',
    silentTranslationWarn: true,
    messages: {
        'en-US': locales['en-US'].messages,
        'he-IL': locales['he-IL'].messages
    },
});

export default {
    name: 'Localization',
    components: {
        AppHeader,
        SideBar,
        AppFooter
    },
    data() {
        return {
            isExpanded: window.innerWidth > 768,
        };
    },
    mounted() {
        this.$i18n.locale = this.locale;
        if ( _.isEmpty(getAuthUser()) && this.user !== null ) {
            this.checkingAuth();
        }
    },
    computed: {
        ...mapState(['locale', 'user', 'isUnAuthorized', 'isRTL']),
    },
    methods: {
        toggleSidebar() {
            this.isExpanded = !this.isExpanded;
        },
        async checkingAuth() {
            try {
                const response = await request({
                    method: 'get',
                    url: `/auth/profile`,
                });
            } catch (error) {
            }
        },
    },
    watch: {
        locale(val) {
            // this.$i18n.locale = val;
        },
    },
};
</script>
