import Vue from 'vue';
import VueRouter from 'vue-router';
import OpenVolunteering from '../view/pages/volunteer/OpenVolunteering.vue';
import MyVolunteerBasket from '@/view/pages/volunteer/requestBasekt';
import UpdateHelpRequestStatus from '@/view/pages/volunteer/request/UpdateHelpRequestStatus';
import NewHelpRequest from '@/view/pages/user/NewHelpRequest.vue';
import UserManagement from '@/view/pages/admin/UserManagement.vue';
import VolunteersManagement from '@/view/pages/admin/requestApproval';
import NotificationLog from '@/view/pages/NotificationLog';
import AboutUs from '@/view/AboutUs.vue';
import Login from '../view/auth/login/index.vue';
import Registration from '../view/auth/registration/index.vue';
import VolunteerRegistration from '../view/auth/volunteer/registration/index.vue';
import Profile from "../view/auth/profile/index.vue";
import {hasAuthUser, removeStorage, getAuthUser} from "@/Util/auth";
import AdminLogin from "../view/auth/admin/login/index.vue";
import Dashboard from "../view/auth/dashboard/index.vue";
import UserNewHelpRequest from "../view/pages/user/WithoutLoginNewHelpRequest";
import AdminLayout from "../view/layouts/admin";

import Event from "../view/pages/manager/events";
import EventForm from "../view/pages/manager/events/add";
import EventMap from "../view/pages/manager/events/map";
import ManagerVolunteersManagement from '@/view/pages/manager/requestApproval';
import ManagerUserManagement from '@/view/pages/manager/users/UserManagement.vue';

import AdminEvent from "../view/pages/admin/events";
import AdminMapForm from "../view/pages/admin/events/map";
import AdminEventForm from "../view/pages/admin/events/add";
import AdminCategoryList from "../view/pages/admin/categories";
import AdminTasks from "../view/pages/admin/settings/tasks";
import AdminCategoryForm from "../view/pages/admin/categories/form";
import AdminAutoAssign from "../view/pages/admin/settings/autoAssign"
import AdminCoverageMap from "../view/pages/admin/coverageMap";
import AdminOrganization from "../view/pages/admin/organizations";
import AdminOrganizationForm from "../view/pages/admin/organizations/form"
import AdminUserManagementForm from "../view/pages/admin/users/form"

import Contact from "../view/pages/contact";
import ForbiddenPage from "../view/error/ForbiddenPage";

import UserLogin from "@/view/auth/user/login";
import VolunteerLogin from "@/view/auth/volunteer/login";
import VolunteerLayout from "../view/layouts/volunteer";

Vue.use(VueRouter);

//error
import Error from "../view/error";

const ParentComponent = {
    render(h) {
        return h('router-view');
    }
};

const routes = [
    {
        path: '/',
        redirect: '/dashboard',
        component: Login,
        meta: {
            authRequired: false,
        },
    },
    {
        path: '/dashboard',
        name: 'Dashboard',
        meta: {
            authRequired: true,
        },
        component: Dashboard
    },
    {
        path: '/login',
        name: 'Login',
        meta: {
            authRequired: false,
        },
        component: Login,
    },
    {
        path: '/registration',
        name: 'Registration',
        component: Registration,
    },
    {
        path: '/volunteer/registration/:slug?',
        name: 'volunteerRegistration',
        component: VolunteerRegistration,
    },
    {
        path: '/profile',
        name: 'Profile',
        meta: {
            authRequired: true,
        },
        component: Profile,
    },
    {
        path: '/volunteer/update-status/:code',
        name: 'volunteerUpdateHelpRequestStatus',
        component: UpdateHelpRequestStatus,
        meta: {
            authVolunteerRequired: false,
        },
    },
    {
        path: '/help-menu',
        name: 'NewHelpRequest',
        meta: {
            authRequired: true,
        },
        component: NewHelpRequest,
    },
    {
        path: '/notification-menu',
        name: 'NotificationLog',
        meta: {
            authRequired: true,
        },
        component: NotificationLog,
    },
    {
        path: '/user/new-help-request',
        name: 'UserNewHelpRequest',
        meta: {
            authRequired: false,
        },
        component: UserNewHelpRequest,
    },
    {
        path: '/about-menu',
        name: 'AboutUs',
        meta: {
            authRequired: true,
        },
        component: AboutUs,
    },
    {
        path: '/volunteers-menu',
        name: 'ManagerVolunteersManagement',
        component: ManagerVolunteersManagement,
    },
    {
        path: '/user-management-menu',
        name: 'ManagerUserManagement',
        meta: {
            authRequired: true,
        },
        component: ManagerUserManagement,
    },
    {
        path: '/events',
        name: 'managerEvent',
        meta: {
            authRequired: true,
        },
        component: Event,
    },
    {
        path: '/events/map/:id?',
        name: 'managerEventMap',
        meta: {
            authRequired: true,
        },
        component: EventMap,
    },
    {
        path: '/events/:operation/:id?',
        name: 'managerEventForm',
        meta: {
            authRequired: true,
        },
        component: EventForm,
    },
    {
        path: '/contact-us',
        name: 'contactUs',
        meta: {
            authRequired: true,
        },
        component: Contact,
    },
    {
        path: '/user/login',
        name: 'UserLogin',
        component: UserLogin,
        meta: {
            authRequired: false,
        },
    },

    //volunteer
    {
        path: '/volunteer/login',
        name: 'VolunteerLogin',
        component: VolunteerLogin,
        meta: {
            authVolunteerRequired: false,
        },
    },

    {
        path: '/volunteer',
        meta: {
            authVolunteerRequired: true,
        },
        component: VolunteerLayout,
        children: [
            {
                path: 'open-menu',
                name: 'OpenVolunteering',
                component: OpenVolunteering,
            },
            {
                path: 'basket-menu',
                name: 'MyVolunteerBasket',
                component: MyVolunteerBasket,
            },

        ]
    },

    //admin
    {
        path: '/admin/login',
        name: 'AdminLogin',
        component: AdminLogin,
        meta: {
            authAdminRequired: false,
        },
    },

    {
        path: '/admin',
        meta: {
            authAdminRequired: true,
        },
        component: AdminLayout,
        children: [
            {
                path: 'dashboard',
                name: 'AdminDashboard',
                component: Dashboard,
            },
            {
                path: 'notification-menu',
                name: 'AdminNotificationLog',
                component: NotificationLog,
            },
            {
                path: 'profile',
                name: 'AdminProfile',
                component: Profile,
            },
            {
                path: 'coverage-map-menu',
                name: 'AdminCoverageMap',
                component: AdminCoverageMap,
            },
            {
                path: 'management-menu',
                name: 'AdminUserManagement',
                component: UserManagement,
            },
            {
                path: 'management-menu/:operation',
                name: 'AdminUserManagementForm',
                component: AdminUserManagementForm,
            },
            {
                path: 'organizations',
                name: 'adminOrganization',
                component: AdminOrganization,
            },
            {
                path: 'organizations/:operation/:id?',
                name: 'adminOrganizationForm',
                component: AdminOrganizationForm,
            },
            {
                path: 'volunteers-menu',
                name: 'AdminVolunteersManagement',
                component: VolunteersManagement,
            },
            {
                path: 'events',
                name: 'adminEvent',
                component: AdminEvent,
            },
            {
                path: 'events/map/:id?',
                name: 'adminEventMap',
                component: AdminMapForm,
            },
            {
                path: 'events/:operation/:id?',
                name: 'adminEventForm',
                component: AdminEventForm,
            },
            {
                path: 'categories',
                name: 'adminCategory',
                component: AdminCategoryList,
            },
            {
                path: 'auto-assign',
                name: 'adminAutoAssign',
                component: AdminAutoAssign,
            },
            {
                path: 'tasks',
                name: 'adminTasks',
                component: AdminTasks,
            },
            {
                path: 'categories/:operation/:id?',
                name: 'adminCategoryForm',
                component: AdminCategoryForm,
            },
            {
                path: 'about-menu',
                name: 'AdminAboutUs',
                component: AboutUs,
            },
            {
                path: 'contact-us',
                name: 'adminContactUs',
                component: Contact,
            },
        ],
    },

    //error
    {
        path: '*',
        beforeEnter: (to, from, next) => {
            next('/404');
        },
    },
    {
        path: '/404',
        name: '404',
        component: Error,
    },
    {
        path: '/forbidden',
        name: 'Forbidden',
        component: ForbiddenPage,
    },

];

const router = new VueRouter({
    mode: 'history',
    base: '/',
    routes
});


router.beforeEach((to, from, next) => {
    if (
      hasAuthUser()
      && getAuthUser().roles.includes('user') &&
      getAuthUser().disability_id == null
      && to.name !== 'Profile'
    ) {
        return next({
            name: 'Profile',
        });
    }

    if (
      hasAuthUser()
      && getAuthUser().role !== 'admin' &&
      to.matched.some(record => record.meta.authAdminRequired)
    ) {
        next({
            name: 'adminLogin',
        });
    }

    if (
      hasAuthUser()
      && getAuthUser().role !== 'volunteer' &&
      to.matched.some(record => record.meta.authVolunteerRequired)
    ) {
        next({
            name: 'adminLogin',
        });
    }
    if (
      hasAuthUser()
      && getAuthUser().role == 'admin' &&
      to.matched.some(record => record.meta.authRequired)
    ) {
        next({
            name: '404',
        });
    }
    if (
      ( to.matched.some(record => record.meta.authRequired)
        || to.matched.some(record => record.meta.authAdminRequired)
        || to.matched.some(record => record.meta.authVolunteerRequired) )
      && !hasAuthUser()
    ) {
        removeStorage('auth');
        next({
            name: 'Login',
        });
    }


    next();
});


export default router;
