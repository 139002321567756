<template>
    <div class="w-100 d-flex mt-3">
        <div class="col-12">
            <div class="col-12 mt-3 text-right">
                <router-link :to="{name:'adminOrganization'}" class="btn btn-primary">{{ $t('title.back') }}</router-link>
                <hr />
            </div>
            <b-form @submit.prevent="handleSubmit">
                <b-row>
                    <div class="col-12 col-md-8">
                        <b-row>
                            <div class="col-12 col-md-6">
                                <label for="name">{{ $t('title.name') }}</label>
                                <b-form-input @input="resetError" name="name" v-model="formFields.name"
                                              type="text"></b-form-input>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('name')">
                                    {{ formErrors.first('name') }}
                                </b-form-invalid-feedback>
                            </div>
                            <div class="col-12 col-md-6">
                                <label for="name">{{ $t('title.logo') }}</label>
                                <b-form-file 
                                v-model="formFields.logo"
                                @change="previewImage"
                                accept="image/*"
                                browse-text="Choose file"
                                ></b-form-file>
                                <b-form-invalid-feedback
                                    :state="!formErrors.has('logo')">
                                    {{ formErrors.first('logo') }}
                                </b-form-invalid-feedback>

                                <div v-if="imagePreview" class="mt-2">
                                    <img :src="imagePreview" alt="Preview" class="img-thumbnail" width="150">
                                </div>
                            </div>
                        </b-row>
                        <b-row>
                            <div class="col-12">
                                <hr class="hr-basic mb-0" />
                            </div>
                            <div class="col-12">
                                <div
                                    class="mb-5 mt-2 gap-3 justify-content-start align-items-center px-2 px-lg-0 flex-column flex-md-row"
                                >
                                    <button :disabled="loader" type="submit"
                                            class="btn main-btn fw-semibold col col-lg-auto mw-220">
                                        <span>{{ $t('title.saveAndClose') }}</span>
                                    </button>
                                </div>
                            </div>
                        </b-row>
                    </div>
                </b-row>
            </b-form>
        </div>
    </div>
</template>

<script>
import Error from "@/Util/Error";
import { mapState } from "vuex";
import destinationMixin from "@/Util/destinationMixin";
import { request } from "@/Util/Request";

const DEFAULT_FORM = {
    id: null,
    name: null,
    logo: null
};

export default {
    mixins: [destinationMixin],
    data() {
        return {
            formFields: { ...DEFAULT_FORM },
            formErrors: new Error({}),
            dropdowns: {
                languages: []
            },
            imagePreview: null
        };
    },
    mounted() {
        if ( this.$route.params && this.$route.params.id ) {
            this.getDetail(this.$route.params.id);
        }
    },
    methods: {
        getImageUrl(path) {
            if (!path) return 'https://via.placeholder.com/150'; // Default placeholder
            return `${process.env.VUE_APP_BACKEND_URL}storage/${path}`;
        },
        previewImage(event) {
            const file = event.target.files[0];
            if (file) {
                this.imagePreview = URL.createObjectURL(file);
            }
        },
        async handleSubmit() {
            try {
                this.isSubmit = true;

                 // Create FormData instance
                const formData = new FormData();

                // Append all form fields except the image
                for (const key in this.formFields) {
                    if (key !== 'logo') {
                        formData.append(key, this.formFields[key]);
                    }
                }

                // Append the image file if it exists
                if (this.formFields.logo instanceof File) {
                    formData.append('logo', this.formFields.logo);
                }

                if(this.formFields.id) {
                     formData.append('_method','PUT');
                }

                const response = await request({
                    method: 'post',
                    url: this.formFields.id ? `/admin/organizations/${ this.formFields.id }` : `/admin/organizations`,
                    data: formData,
                    headers: {
                        'Content-Type': 'multipart/form-data'
                    }
                });

                if ( this.formFields.id ) {
                    this.notifySuccessWithMsg(`${ this.$t('title.organization') } ${ this.$t('title.updateSuccessfully') }`);
                } else {
                    this.notifySuccessWithMsg(`${ this.$t('title.organization') } ${ this.$t('title.addSuccessfully') }`);
                }

                this.$router.push({ name: 'adminOrganization' });
                this.isSubmit = false;

            } catch (error) {
                this.isSubmit = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        async getDetail(id) {
            try {
                const response = await request({
                    method: 'get',
                    url: `/admin/organizations/${ id }`
                });

                const { data } = response;

                this.formFields = {
                    id: data.id,
                    name: data.name,
                    logo: data.logo
                };

                this.imagePreview = this.getImageUrl(data.logo);
            } catch (error) {
                this.notifyError();
            }
        },
        resetError() {
            this.formErrors = new Error({});
        }
    },
    computed: {
        ...mapState(['loader'])
    }
};
</script>
