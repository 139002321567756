<template>
    <div class="main-block request-management">
        <!--        <div class="div col-12 p-0 d-flex align-items-start">-->
        <!--            <h4 class="">{{ $t('title.volunteesrsManagement') }}</h4>-->
        <!--        </div>-->
        <div class="scrollable-block">
            <div class="col-12 row m-0 p-0" style="min-height: 90%">
                <crud-table :columns="columns" :list-url="listUrl" ref="application-table" v-on:refresh="resetFilter">
                    <template #filter-slot>
                        <b-col lg="3" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.event')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" @input="handleFilter" v-model="filters.event_id"
                                            class="form-input"
                                            :placeholder="$t('title.select')"
                                            :options="dropdowns.events" />
                            </b-form-group>
                        </b-col>
                        <b-col lg="3" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.status')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" @input="handleFilter" v-model="filters.status"
                                            class="form-input"
                                            :placeholder="$t('title.select')"
                                            :options="dropdowns.statuses" />
                            </b-form-group>
                        </b-col>
                    </template>
                    <template>
                        <b-button :disabled="loader" variant="primary" @click="exportData">
                            <i class="fa fa-file"></i> {{ $t('title.export') }}
                        </b-button>
                    </template>
                    <template #cell(event)="{item}">
                        {{ ( item.event || {} ).name }}
                    </template>
                    <template #cell(volunteer)="{item}">
                        <a href="#" @click.prevent="openVolunteerDetailModal(item)">{{
                                ( item.volunteer || {} ).full_name
                            }}</a>
                    </template>
                    <template #cell(user)="{item}">
                        <a href="#" @click.prevent="openUserDetailModal(item)">{{
                                ( item.user || {} ).full_name
                            }}</a>
                    </template>
                    <template #cell(status)="{item}">
                        {{ $t('title.' + item.status) }}
                        <span class="text-danger"
                              v-if="item.is_approved == 0 && !_.includes(['pending','closed', 'reject'],item.status)">
                            ({{ $t('title.waitingForApproval') }})
                        </span>
                    </template>
                    <template #cell(creation_date)="{item}">
                        {{ $global.utcDateToLocalDate(item.updated_at) }}
                    </template>
                    <template #cell(actions)="{item}">
                        <b-button v-if="item.is_approved == 0 && item.volunteer && item.status == 'assigned'"
                                  v-b-popover.hover.top="$t('title.approved')"
                                  variant="outline-primary"
                                  :disabled="loader"
                                  class="mr-1" @click="onSubmit(item);">
                            <b-icon icon="shield-check" class="icon-style"></b-icon>
                        </b-button>
                        <b-button v-if="item.volunteer && item.status == 'assigned'"
                                  v-b-popover.hover.top="$t('title.reject')"
                                  variant="outline-primary"
                                  :disabled="loader"
                                  class="mr-1" @click="handleCompleteRejectModal(item);">
                            <b-icon icon="x-circle-fill" class="icon-style"></b-icon>
                        </b-button>
                    </template>
                </crud-table>
            </div>
        </div>
        <VolunteerDetailModel ref="volunteerDetailModal" />
        <UserDetailModel ref="userDetailModal" />
        <VolunteerCompleteRejectRequestModal ref="volunteerCompleteRejectRequest"
                                             @refreshVolunteerList="refreshTable" />
    </div>
</template>

<script>

import {request} from "@/Util/Request";
import {mapState} from "vuex";
import VolunteerDetailModel from "@/view/pages/admin/requestApproval/modal/VolunteerDetailModel";
import UserDetailModel from "@/view/pages/admin/requestApproval/modal/UserDetailModel";
import VolunteerCompleteRejectRequestModal from "@/view/pages/manager/events/modal/VolunteerCompleteRejectRequestModal";

const FILTER_FORM = {
    event_id: null,
    status: null
};

const COLUMN_STATE = (self) => [
    {key: 'actions', label: self.$t('title.actions')},
    {key: 'status', label: self.$t('title.status'), sortable: true},
    {key: 'event', label: self.$t('title.event'), sortable: true},
    {key: 'user', label: self.$t('title.user'), sortable: true},
    {key: 'volunteer', label: self.$t('title.volunteer'), sortable: true},
    {key: 'creation_date', label: self.$t('title.creationDate'), sortable: true},
];

export default {
    data() {
        return {
            operation: '',
            listUrl: '/admin/event-approval-requests',
            filters: {
                ...FILTER_FORM
            },
            dropdowns: {
                events: [],
                statuses: []
            }
        };
    },
    components: {
        VolunteerDetailModel,
        UserDetailModel,
        VolunteerCompleteRejectRequestModal
    },
    mounted() {
        this.getDropdowns();
    },
    methods: {
        async exportData() {
            this.$refs['application-table'].handleExport(this.filters, 'Event help requests');
        },
        handleCompleteRejectModal(eventUserAssignedRequest) {
            this.$refs['volunteerCompleteRejectRequest'].handleToggleModal(eventUserAssignedRequest.id, 'reject');
        },
        openVolunteerDetailModal(volunteer) {
            this.$refs['volunteerDetailModal'].handleToggleModal(volunteer);
        },
        openUserDetailModal(user) {
            this.$refs['userDetailModal'].handleToggleModal(user);
        },
        async onSubmit(item) {

            try {
                const response = await request({
                    method: 'patch',
                    url: `/admin/event-approval-requests/${item.id}`,
                });

                this.notifySuccessWithMsg(this.$t('title.helpRequestAcceptMessage'));

                this.$refs['application-table'].refreshTableData();

            } catch (error) {
                this.notifyError();
            }

        },
        refreshTable() {
            this.$refs['application-table'].handleRefreshList();
        },
        handleFilter() {
            this.$refs['application-table'].handleFilter(this.filters);
        },
        resetFilter() {
            this.filters = {...FILTER_FORM};
        },
        async getDropdowns() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/events`,
                });

                const {events, statuses} = response;

                this.dropdowns.events = events;
                this.dropdowns.statuses = _.map(statuses, (i) => {
                    return {
                        id: i.id,
                        label: this.$t(`title.${_.camelCase(i.label)}`)
                    };
                });

            } catch (error) {
            }
        },
    },
    computed: {
        columns() {
            return COLUMN_STATE(this);
        },
        ...mapState(['loader'])
    }
};
</script>
