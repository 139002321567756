<template>
    <div>
        <b-modal v-model="isOpenModal" id="userAssignOrganizationModal"  :hide-header="true"
                 :hide-footer="true">
            <h5>{{ $t('title.organizations') }}</h5>
            <b-form @submit.prevent="handleSubmitRequest" class="col-12 col-md-12 mx-auto p-0">
                <div class="mb-3">
                    <label for="organization">{{ $t('title.selectOrganization') }}</label>
                    <treeselect :multiple="false" v-model="formFields.organization_id" :options="organizations"
                                :placeholder="$t('title.select')"
                                :clearOnSelect="true"
                                id="type"></treeselect>
                    <b-form-invalid-feedback :state="!formErrors.has('organization_id')">
                        {{ formErrors.first('organization_id') }}
                    </b-form-invalid-feedback>
                </div>
                <div class="modal-footer d-flex mr-2 gap-2">
                    <button type="submit" :disabled="isSubmit" class="btn main-btn col m-0">
                         <i class="fa fa-spin fa-spinner" v-if="isSubmit"></i> {{ $t('title.submit') }}
                    </button>
                    <button type="button" class="btn sec-btn col m-0" @click="cancelModal">
                        {{ $t('title.cancel') }}
                    </button>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>
import {request} from "@/Util/Request";
import Error from "@/Util/Error";

const FORM_STATE = {
    organization_id: null
}
export default {
    data() {
        return {
            isOpenModal: false,
            userId: null,
            formFields: { ...FORM_STATE },
            formErrors: new Error({}),
            isSubmit: false,
            organizations: []
        };
    },
    methods: {
        async handleToggleModal(id) {
            this.formFields = { ...FORM_STATE };
            this.userId = id;
            this.isOpenModal = !this.isOpenModal;
            this.getOrganizations();
        },
        async getOrganizations() {
            try {
                const response = await request({
                    method: 'get',
                    url: `admin/organizations`,

                });

                let { data } = response;
                this.organizations = data;
            }  catch (error) {
                this.notifyError();
            }
        },
        async handleSubmitRequest() {
            try {
                this.isSubmit = true;
                const response = await request({
                    method: 'post',
                    url: `admin/user/assign/organization/${ this.userId }`,
                    data: this.formFields
                });
                if(response) {
                    this.notifySuccessWithMsg(`${ this.$t('title.updateOrganizationMessage') }`);
                    this.$emit('refreshTable');
                    setTimeout(() => {
                        this.isSubmit = false;
                        this.cancelModal();
                    }, 1000);
                    
                }
                this.formErrors = new Error({});
                
            } catch (error) {
                this.isSubmit = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }

        },
        cancelModal() {
            this.isOpenModal = false;
            this.formErrors = new Error({});
            this.formFields = { ...FORM_STATE };
        }
    }
};
</script>
