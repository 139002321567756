<template>
    <div>
        <div v-if="locale == 'he-IL'">
            <div class="main-block about-us">
                <div class="div col-12 p-0 d-flex align-items-start">
                    <h4 class="">אודותינו</h4>
                </div>
                <div class="scrollable-block">
                    <b-container fluid class="p-0 overflow-hidden">
                        <div class="col-12 row mt-3 m-0 p-0">
                            <p>
                                יוזמת ה"אפוד הסגול" הוקמה על מנת לתת סיוע לאנשים עם מוגבלות וקשישים בשעת חירום.
                            </p>
                            <p>
                                היוזמה החלה לאחר התפרצות המלחמה שפקדה את אוקראינה, כאשר פניות לעזרה למתן סיוע לאנשים עם
                                מוגבלות החלו להגיע אלינו לעמותת נגישות ישראל.
                                מטרת היוזמה הינה לוודא מוכנות לשעת חירום ועידוד מודעות לחשיבות של היערכות ויצירת תוכניות
                                מגירה גלובליות לאנשים עם מוגבלויות ולמבוגרים לנגישות בשעת חירום
                                במסגרת המיזם אנו מספקים מידע חיוני בעתות חירום – דרך האתר, הכשרות "הכי מוכן שיש" לאנשים
                                עם מוגבלות להערכות מוקדמות לחירום, הדרכות סיוע בעת חירום לצוותים, ארגונים ואנשיים פרטיים
                                שכולם לאחר ההדרכה מוכשרים להיות מתנדבי האפוד הסגול.
                                אנו יוצרים יוצרים "צי" של מתנדבי האפוד הסגול אשר יהיו במקרים רבים בין המגיבים הראשונים
                                אשר יוכלו לסייע בתקשורת נגישה והבנת צרכי הנגישות של האדם עם המוגבלות בפינוי,
                                ופועלים להעלאת מודעות לחשיבות של היערכות מוקדמת לזמני חירום
                            </p>
                            <p>
                                ב7 באוקטובר עמותת <b>נגישות ישראל</b>&nbsp;הקימה חמ"ל צרכים ומתנדבים שעבד ללא הפסקה
                                לסייע לאנשים עם מוגבלות בכל מה שהיו צריכים – ביגוד, מזון, ליווי, הסעות, פינויים וכדומה,
                                וזאת על ידי חיבור ישיר למתנדבים שהוכשרו על ידי העמותה לתת סיוע לאנשים עם מוגבלות בחירום.
                                כדי לייעל את המערך, העמותה פיתחה את אפליקציית האפוד הסגול שתסייע בניהול ישיר של הקשר בין
                                המתנדבים לאנשים עם מוגבלות.
                                <span style="font-family: Arial, sans-serif; font-size: 11pt; color: rgb(36, 36, 36) !important;">הפיתוח הראשוני נעשה יחד </span><span
                                style="font-family: Aptos, sans-serif; font-size: 11pt; color: rgb(36, 36, 36) !important;">CODE FOR ISRAEL</span><span
                                style="font-family: Arial, sans-serif; font-size: 11pt; color: rgb(36, 36, 36) !important;">, והחידוש והסופי נעשה ע"י מור אלון וחברתו </span><span
                                style="font-family: Aptos, sans-serif; font-size: 11pt; color: rgb(36, 36, 36) !important;">CHECKER</span><span
                                style="font-family: Arial, sans-serif; font-size: 11pt; color: rgb(36, 36, 36) !important;">.</span>
                            </p>
                            <p>
                                <b>אפליקציית האפוד הסגול קשובה לצרכים בשטח, מזהה את כל המשאבים הזמינים ומחברת את הנקודות</b>
                                <b>כדי לאפשר את שיפור החוויה של אנשים עם מוגבלויות בשעת חירום על מנת <u>שאף אחד לא יישאר מאחור</u></b>
                            </p>
                            <div class="row">
                                <div class="col-12 col-md-6">
                                    <img
                                        src="https://purple-vest.net/wp-content/uploads/2022/05/b8b9e6f6-0cd1-4a24-b994-1061cca73bfb-copy.png"
                                        alt=""
                                        class="w-100"
                                    />
                                </div>
                            </div>
                        </div>
                    </b-container>
                </div>
            </div>
        </div>
        <div v-else class="main-block about-us">
            <div class="div col-12 p-0 d-flex align-items-start">
                <h4 class="">About Us</h4>
            </div>
            <div class="scrollable-block">
                <b-container fluid class="p-0 overflow-hidden">
                    <div class="col-12 row mt-3 m-0 p-0">
                        <p>
                            The Purple Vest Mission is an initiative which promotes accessibility preparedness in
                            times of emergency for people with disabilities and the elderly. The Purple Vest Mission
                            was initiated on March 2022 by Access Israel who is the leading organization in Israel
                            promoting accessibility and inclusion for all types of disabilities in all areas of
                            life. As leaders of accessibility and inclusion in Israel, Access Israel is very
                            experienced and active in shaping the preparedness of Israel in times of emergency
                            making sure people with disabilities and elderly are not left behind. Access Israel led
                            round tables with stakeholders to understand challenges, needs and possible solutions
                            and conducted training various stakeholders for preparedness in time of emergency and
                            with being part of the advanced legislation process resulting in regulations on
                            preparedness in times of emergency.&nbsp;
                        </p>
                        <p>
                            When the war broke in Ukraine, Access Israel understood the need to focus on making sure
                            solutions on the ground fit those most vulnerable – the elderly and those with
                            disabilities. The requests for help started to come, calling for help for specific and
                            unique needs for people with disabilities. Access Israel immediately understood that
                            people with disabilities and the elderly are left behind and are not evacuated because
                            the complexity of arranging accessible evacuation and because of lack of available
                            information on this subject.
                        </p>
                        <p>
                            Access Israel immediately decided to initiate the “<strong>Purple Vest Mission</strong>”
                            – Our overall long-term goal is to create contingency plans for people with disabilities
                            and the elderly globally, to ensure preparedness and a plan for times of emergency.
                            <strong>Purple Vest mission</strong> is focused on people with disabilities and the
                            elderly and their families.&nbsp;
                        </p>
                        <h3>Why Purple Vest?</h3>
                        <p>
                            The idea behind the purple vest itself is that when people with disabilities and the
                            elderly see a person wearing a purple vest (with the color purple which is identified
                            with rights for people with disabilities) they will know that this person was trained
                            and has the tools to provide assistance in an accessible way.
                        </p>
                        <div class="row">
                            <div class="col-12 col-md-6">
                                <div class="elementor-widget-container">
                                    <p>
                                        In April 2022, we received a generous donation from The Nippon Foundation from
                                        Japan and other donors, enabling us to provide and fund accessible complex
                                        evacuations, provide supplies, contacts and other information for people with
                                        disabilities and elderly in Ukraine and raise awareness and conduct trainings
                                        both
                                        virtual and face-to-face to volunteers and organizations operating in and around
                                        Ukraine to provide tools on making the efforts they provide during these
                                        difficult
                                        times more accessible and fitting for people with disabilities and the elderly.
                                    </p>
                                    <p>
                                        In December 2022 we received the support from the Ministry of Foreign Affairs in
                                        israel and continuous support of Accessibility Accelerator from the USA enabling
                                        us to continue the efforts of making sure people with disabilities and elderly
                                        are
                                        considered and part of preparing for times of emergency, focusing on awareness,
                                        information, training and continuing to connect the dots between those operating
                                        on the ground making sure people with disabilities and elderly are not left
                                        behind.
                                    </p>
                                    <p>
                                        <strong>&nbsp;</strong
                                        ><strong
                                    >The Purple Vest Mission listens to needs on the ground, identifies all
                                        available resources, and connects the dots to facilitate improved experiences
                                        for people with disabilities.</strong
                                    >
                                    </p>
                                </div>
                            </div>
                            <div class="col-12 col-md-6">
                                <img
                                    src="https://purple-vest.net/wp-content/uploads/2022/05/b8b9e6f6-0cd1-4a24-b994-1061cca73bfb-copy.png"
                                    alt=""
                                    class="w-100"
                                />
                            </div>
                        </div>
                    </div>
                </b-container>
            </div>
        </div>
    </div>
</template>

<script>
import {mapState} from "vuex";

export default {
    data() {
        return {};
    },
    computed: {
        ...mapState(['locale'])
    }
};
</script>
