<template>
    <div id="app">
        <Localization />
    </div>
</template>

<script>
import Localization from '@/Localization';
import {Settings as LuxonSettings} from 'luxon';
import {mapState} from "vuex";

export default {
    name: 'App',
    components: {
        Localization,
    },
    computed: {
        rtlClass() {
            return this.$store.state.isRTL ? 'rtl' : 'ltr';
        },
        ...mapState(['locale'])
    },
    watch: {
        rtlClass(newDir) {
            document.body.setAttribute('dir', newDir);
        },
    },
    mounted() {
        document.body.setAttribute('dir', this.rtlClass);
        LuxonSettings.defaultLocale = this.locale
    },
};
</script>
