<template>
    <p class="my-2" v-if="userDetail">
        <span class="title font-weight-bold">{{ $t('title.name') }} : </span>
        <span>{{ userDetail?.full_name }}</span>
        <br />

        <span class="title font-weight-bold">{{ $t('title.phone') }} :</span>
        <span dir="ltr">{{ userDetail?.phone }}</span>
        <br />

        <span class="title font-weight-bold">{{ $t('title.email') }} : </span>
        <span>{{ userDetail?.email }}</span>
        <br />

        <span class="title font-weight-bold">{{ $t('title.disability') }} : </span>
        <span>{{ userDetail?.disability?.name}}</span>
        <br />

        <span class="title font-weight-bold">{{ $t('title.residential_address') }} :</span>
        <span v-if="userDetail?.residential_address && userDetail?.residential_address?.street">
            {{ userDetail?.residential_address?.street }}, {{ userDetail?.residential_address?.city }}, {{ userDetail?.residential_address?.state }}, {{ userDetail?.residential_address?.country }}, {{ userDetail?.residential_address?.lt }}, {{ userDetail?.residential_address?.lng }}.
        </span>
         <span v-else>
            {{ userDetail?.residential_address }}
        </span>
        <br />

        <span class="title font-weight-bold">{{ $t('title.arrival_address') }} :</span>
        <span v-if="userDetail?.arrival_address && userDetail?.arrival_address?.street">
           {{ userDetail?.arrival_address?.street }}, {{ userDetail?.arrival_address?.city }}, {{ userDetail?.arrival_address?.state }}, {{ userDetail?.arrival_address?.country }}, {{ userDetail?.arrival_address?.lt }}, {{ userDetail?.arrival_address?.lng }}.
        </span>
        <span v-else>
            {{ userDetail?.arrival_address }}
        </span>
        <br />

        <span class="title font-weight-bold">{{ $t('title.communication') }} :</span>
        <span>
            {{ (userDetail?.is_phone == 1) ? $t('title.phone') : ''}}
            {{ (userDetail?.is_wp == 1) ? $t('title.whatsApp') : ''}}
        </span>
        <br />
    </p>
</template>

<script>
export default {
    props: ['userDetail'],
}
</script>s
