<template>
    <div class="main-block user-management">
        <div class="div col-12 p-0 d-flex align-items-start">
            <h4 class="">{{ $t('title.userManagement') }}</h4>
        </div>
        <div class="scrollable-block">
            <div class="col-12 row mt-3 m-0 p-0">
                <crud-table :columns="columns" :filters="filters" :list-url="listUrl" ref="users-table"
                            v-on:rowClick="onRowClicked" v-on:refresh="resetFilter">
                    <template #filter-slot>
                        <b-col lg="2" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.status')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" @input="handleFilter" v-model="filters.status"
                                            :placeholder="$t('title.select')"
                                            class="form-input"
                                            :options="statuses" />
                            </b-form-group>
                        </b-col>
                        <b-col lg="2" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.type')" label-for="type-select" class="mb-0">
                                <treeselect id="roles" :multiple="false" @input="handleFilter" v-model="filters.roles"
                                            :placeholder="$t('title.select')"
                                            class="form-input"
                                            :options="dropdowns.roles" />
                            </b-form-group>
                        </b-col>
                        <b-col lg="2" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.region')" label-for="type-select" class="mb-0">
                                <treeselect id="state" :multiple="false" @input="handleFilter" v-model="filters.state"
                                            :placeholder="$t('title.select')"
                                            class="form-input"
                                            :options="dropdowns.states" />
                            </b-form-group>
                        </b-col>
                    </template>
                    <template>
                        <b-button :disabled="selectedUsers.length == 0" variant="outline-primary"
                                  @click="openSendMessageModal">
                            <b-icon icon="cursor-fill"></b-icon>
                            {{ $t('title.sendingMessage') }}
                        </b-button>
                        <b-button :disabled="loader" variant="primary" @click="exportData">
                            <i class="fa fa-file"></i> {{ $t('title.export') }}
                        </b-button>
                        <router-link v-if="user.is_super_admin"
                                     :to="{name:'AdminUserManagementForm',params:{operation:'add'}}" :disabled="loader"
                                     class="btn btn-primary">
                            <i class="fa fa-user-plus"></i> {{ $t('title.addNew') }}
                        </router-link>
                    </template>
                    <template #head(select)>
                        <b-form-checkbox
                            id="checkbox"
                            v-model="selectAll"
                            name="checkbox"
                            @change="selectAllUsers"
                        >
                        </b-form-checkbox>
                    </template>
                    <template #cell(select)="row">
                        <b-form-checkbox
                            v-if="row.item.status == 'Activated'"
                            :id="`checkbox-${row.item.id}`"
                            v-model="selectedUsers"
                            :name="`checkbox-${row.item.id}`"
                            :value="row.item.id"
                        >
                        </b-form-checkbox>
                    </template>
                    <template #cell(organization)="{item}">
                        {{ ( item.organization || {} ).name }}
                    </template>
                    <template #cell(disability_id)="{item}">
                        {{ ( item.disability || {} ).name }}
                    </template>
                    <template #cell(phone)="{item}">
                        <div dir="ltr">
                            {{ $global.displayPhoneNumber(item.phone) }}
                        </div>
                    </template>
                    <template #cell(status)="{item}">
                        <b-button
                            v-if="user?.id !== item.id && item.deleted_at == null"
                            variant="outline-primary"
                            class="mr-1" @click="updateUserStatus(item.id, item.is_active, item.roles)">
                            {{ $t('title.' + _.camelCase(item.status)) }}
                        </b-button>
                        <span v-else :class="(item.status == 'Activated') ? 'text-success' : 'text-danger'">  {{
                                $t('title.' + _.camelCase(item.status))
                            }} </span>
                        <!-- <a href="#" @click="updateUserStatus">
                            <span :class="(item.status == 'Activated') ? 'text-success' : 'text-danger'"> {{ item.status }} </span>
                        </a> -->
                    </template>
                    <template #cell(actions)="{item}">
                        <div class="d-flex">
                            <b-button
                                v-b-popover.hover.top="$t('title.organizations')"
                                v-if="isVolunteer(item.roles) && item.status == 'Activated' && !item.is_super_admin"
                                variant="outline-primary"
                                class="mr-1" @click="assignUserOrganization(item.id)">
                                <b-icon icon="peace-fill" class="icon-style"></b-icon>
                            </b-button>
                            <b-button
                                v-b-popover.hover.top="$t('title.permission')"
                                v-if="user?.id !== item.id && item.status == 'Activated' && !item.is_super_admin"
                                variant="outline-primary"
                                class="mr-1" @click="assignUserPermission(item.id)">
                                <b-icon icon="gear-fill" class="icon-style"></b-icon>
                            </b-button>
                            <b-button
                                v-b-popover.hover.top="$t('title.roles')"
                                v-if="user?.id !== item.id && item.status == 'Activated' && !item.is_super_admin"
                                variant="outline-primary"
                                class="mr-1" @click="assignUserRole(item.id, item.roles)">
                                <b-icon icon="person" class="icon-style"></b-icon>
                            </b-button>
                            <!--                        <b-button-->
                            <!--                            v-if="_.filter(item.roles,(i) => i.name == 'user').length && !item.has_admin_role"-->
                            <!--                            title="become an admin"-->
                            <!--                            variant="outline-primary"-->
                            <!--                            class="mr-1" @click="handleBecomeAdmin(item.id)">-->
                            <!--                            <b-icon icon="person" class="icon-style"></b-icon>-->
                            <!--                        </b-button>-->
                            <b-button
                                v-b-popover.hover.top="$t('title.delete')"
                                v-if="user?.id !== item.id && !item.deleted_at && !item.is_super_admin"
                                variant="outline-primary"
                                class="mr-1" @click="handleDelete(item.id, item.roles)">
                                <b-icon icon="trash" class="icon-style"></b-icon>
                            </b-button>
                            <b-button
                                v-b-popover.hover.top="$t('title.delete')"
                                v-if="user?.id !== item.id && item.deleted_at && !item.is_super_admin"
                                variant="outline-primary"
                                class="mr-1" @click="handleRestore(item.id)">
                                <b-icon icon="arrow-clockwise" class="icon-style"></b-icon>
                            </b-button>
                        </div>
                    </template>
                </crud-table>
            </div>
        </div>
        <UserDeleteConfirmationModal ref="userDeleteModal" @refreshTable="handleRefreshTable" />
        <UserAssignRoleModal ref="userAssignRoleModal" @refreshTable="handleRefreshTable" />
        <SendMessageModal ref="sendMessageModal" @refreshTable="handleRefreshTable" />
        <UserAssignPermissionModal ref="userAssignPermissionModal" />
        <UpdateUserStatusModal ref="updateUserStatusModal" @refreshTable="handleRefreshTable" />
        <UserAssignOrganizationModal ref="userAssignOrganizationModal" @refreshTable="handleRefreshTable" />
        <UserDetailModal ref="userDetailModal" />
        <UserRestoreConfirmationModal ref="userRestoreModal" @refreshTable="handleRefreshTable" />
    </div>
</template>

<script>

import {request} from "@/Util/Request";
import {mapState} from "vuex";
import UserDeleteConfirmationModal from "@/view/pages/admin/modal/UserDeleteConfirmationModal";
import UserAssignRoleModal from "@/view/pages/admin/modal/UserAssignRoleModal";
import SendMessageModal from "@/view/pages/admin/users/modal/SendMessageModal";
import UserAssignPermissionModal from "@/view/pages/admin/users/modal/UserAssignPermissionModal";
import UpdateUserStatusModal from "@/view/pages/admin/users/modal/UpdateUserStatusModal";
import UserAssignOrganizationModal from "@/view/pages/admin/users/modal/UserAssignOrganizationModal"
import UserDetailModal from "@/view/pages/admin/modal/UserDetailModal";
import UserRestoreConfirmationModal from "@/view/pages/admin/modal/UserRestoreConfirmationModal";

const FILTER_FORM = {
    roles: null,
    user_ids: null,
    state: null,
    status: null
};

const COLUMN_STATE = (self) => [
    {key: 'select', label: '', sortable: false},
    {key: 'full_name', label: self.$t('title.fullName'), sortable: true},
    {key: 'organization', label: self.$t('title.organization'), sortable: true},
    {key: 'phone', label: self.$t('title.phone'), sortable: true},
    {key: 'disability_id', label: self.$t('title.disability'), sortable: true},
    {
        key: 'is_notification_alert',
        label: self.$t('title.alerts'),
        formatter: (value, key, item) => {
            return value ? self.$t('title.yes') : self.$t('title.no');
        },
        sortable: true,
        sortByFormatted: true,
        filterByFormatted: true,
    },
    {key: 'status', label: self.$t('title.status')},
    {key: 'actions', label: self.$t('title.actions')},
];

export default {
    data() {
        return {
            operation: '',
            listUrl: '/admin/users',
            filters: {
                ...FILTER_FORM
            },
            selectedUsers: [],
            selectAll: false,
            userLists: []
        };
    },
    async mounted() {
        await this.userStates();
        await this.users();
    },
    created() {
        if ( this.$route.query.user_ids ) {
            this.filters.user_ids = this.$route.query.user_ids;
        }
    },
    components: {
        UserRestoreConfirmationModal,
        UserDeleteConfirmationModal,
        UserAssignRoleModal,
        SendMessageModal,
        UserAssignPermissionModal,
        UpdateUserStatusModal,
        UserAssignOrganizationModal,
        UserDetailModal
    },
    methods: {
        async exportData() {
            this.$refs['users-table'].handleExport(this.filters);
        },
        onRowClicked(item) {
            this.$refs.userDetailModal.setItemData(item)
        },
        async userStates() {
            try {
                const response = await request({
                    method: 'get',
                    url: `admin/user/states`,
                });

                const {data} = response
                this.dropdowns.states = data

            } catch (error) {
            }
        },
        openSendMessageModal() {
            this.$refs['sendMessageModal'].handleToggleModal(this.selectedUsers);
        },
        selectAllUsers() {
            if ( this.selectAll ) {
                this.selectedUsers = this.userLists.filter(user => user.status === 'Activated').map(user => user.id); // Select all user IDs
            } else {
                this.selectedUsers = []; // Deselect all users
            }
        },
        async roles() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/roles`,
                });

                this.dropdowns.roles = response.map((role) => ( {
                    id: role.label,
                    label: role.label, // Optional transformation of value
                } ));

            } catch (error) {
                this.notifyError();
            }
        },
        updateUserStatus(id, status, roles) {
            this.$refs['updateUserStatusModal'].handleToggleModal(id, status, roles);
        },
        assignUserPermission(id) {
            this.$refs['userAssignPermissionModal'].handleToggleModal(id);
        },
        assignUserRole(id, roles) {
            this.$refs['userAssignRoleModal'].handleToggleModal(id, roles);
        },
        assignUserOrganization(id) {
            this.$refs['userAssignOrganizationModal'].handleToggleModal(id);
        },
        getRoleNames(roles) {
            return roles.map(role => role.name).join(', ');
        },
        handleRefreshTable() {
            this.$refs['users-table'].handleRefreshList();
            this.selectedUsers = [];
            this.selectAll = false;
            this.users();
        },
        handleFilter() {
            this.$refs['users-table'].handleFilter(this.filters);
        },
        resetFilter() {
            this.filters = {...FILTER_FORM};
        },
        async handleDelete(id, roles) {
            this.$refs['userDeleteModal'].handleToggleModal(id, roles);
        },
        async handleRestore(id) {
            this.$refs['userRestoreModal'].handleToggleModal(id);
        },
        async users() {
            try {
                const response = await request({
                    method: 'get',
                    url: '/admin/users',
                });
                this.userLists = response.data

            } catch (error) {
                this.notifyError();
            }
        },
        async handleBecomeAdmin(id) {
            if ( confirm('Are you want to assign admin role to this user?') ) {
                {
                    try {
                        const response = await request({
                            method: 'post',
                            url: `admin/users/become/admin/${id}`,

                        });

                        this.notifySuccessWithMsg(`Admin role assigned to user successfully`);
                        this.$refs['users-table'].handleRefreshList();

                    } catch (error) {
                        this.notifyError();
                    }
                }
            }

        },
        isVolunteer(roles) {
            return roles.some(role => role.name === 'volunteer');
        },
    },
    computed: {
        statuses() {
            return [
                {
                    id: "activated",
                    label: this.$t('title.activated')
                },
                {
                    id: "deactivated",
                    label: this.$t('title.deactivated')
                },
                {
                    id: "deleted",
                    label: this.$t('title.deleted')
                }
            ]
        },
        dropdowns() {
            return this.roles()
        },
        columns() {
            return COLUMN_STATE(this);
        },
        ...mapState(['user', 'loader'])
    }
};
</script>
