<template>
    <div>
        <b-modal v-model="isOpenModal" id="userDetailModal" :hide-header="true" size="xl"
                 :hide-footer="true">
            <div class="col-12">
                <h5>{{ $t('title.detail') }}</h5>
                <div class="table-responsive">
                    <table class="table" v-if="item">
                        <thead>
                            <tr>
                                <th>{{ $t('title.role') }}</th>
                                <th>{{ $t('title.type') }}</th>
                                <th>{{ $t('title.email') }}</th>
                                <th>{{ $t('title.registrationDate') }}</th>
                                <th>{{ $t('title.residentialAddress') }}</th>
                                <th>{{ $t('title.currentAddress') }}</th>
                            </tr>
                        </thead>
                        <tbody>
                            <tr>
                                <td>
                                    {{ _.map(item.roles, (i) => i.name).join(", ") }}
                                </td>
                                <td>
                                    {{ ( item.is_phone ) ? 'Phone' : '' }}{{
                                        ( item.is_phone && item.is_wp ) ? ',' : ''
                                    }}{{ ( item.is_wp ) ? 'WhatsApp' : '' }}
                                </td>
                                <td>{{ item.email }}</td>
                                <td>
                                    {{ $global.dateFormat(item.email_verified_at) }}
                                </td>
                                <td>
                                    {{ item.residential_address }}
                                </td>
                                <td>
                                    {{ item.arrival_address }}
                                </td>
                            </tr>
                        </tbody>
                        <tfoot v-if="!item">
                            <tr>
                                <td colspan="6">{{ $t('title.noResultFound') }}</td>
                            </tr>
                        </tfoot>
                    </table>
                </div>
            </div>
            <div class="col-12 modal-footer d-flex mr-2 gap-2">
                <button type="button" class="btn sec-btn m-0" @click="cancelModal">
                    {{ $t('title.close') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>

export default {
    data() {
        return {
            isOpenModal: false,
            item: null
        };
    },
    methods: {
        setItemData(item) {
            this.item = item;
            this.isOpenModal = true;
        },
        cancelModal() {
            this.isOpenModal = false;
        },
    }
};
</script>
