<template>
    <div>
        <b-modal v-model="isOpenModal" id="sendMessageConfirmationModal" :hide-header="true"
                 :hide-footer="true">
            <h6>{{ $t('title.areYouSureWantToDelete') }} </h6>
            <div class="modal-footer d-flex mr-2 gap-2">
                <button type="button" :disabled="loader" class="btn main-btn col m-0"
                        @click="handleSubmit">
                    {{ $t('title.yes') }}
                </button>
                <button type="button" class="btn sec-btn col m-0" @click="cancelModal">
                    {{ $t('title.no') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>


<script>
import {mapState} from "vuex";
import {request} from "@/Util/Request";
import Error from "@/Util/Error";

export default {
    data() {
        return {
            isOpenModal: false,
            deleteUrl: this.url
        }
    },
    methods: {
        cancelModal() {
            this.isOpenModal = false
            this.$emit('refresh');
        },
        openModal(url) {
            this.deleteUrl = url
            this.isOpenModal = true
        },
        async handleSubmit() {
            {
                try {
                    const response = await request({
                        method: 'delete',
                        url: this.deleteUrl,

                    });

                    if ( response ) {
                        this.notifySuccessWithMsg(`${this.$t('title.deletePermissionMsg')}`);
                        this.cancelModal()
                    }
                    this.formErrors = new Error({});

                } catch (error) {
                    const {data} = error;
                    const {errors} = data;

                    if ( errors.message && errors.message[0] ) {
                        this.cancelModal()
                        this.notifyErrorWithMsg(`${errors.message[0]}`);
                    } else {
                        this.notifyError()
                    }
                }
            }
        }
    },
    computed: {
        ...mapState(['loader'])
    }
}
</script>
