<template>
    <div class="side-list bg-blur">
        <div class="list-group-item d-flex justify-content-between gap-2 ">
            <b-button variant="primary" class="col-12 col-md-auto sec-btn small-btn w-100"  v-b-modal.modal-info-user>
                {{ $t('title.info') }}
            </b-button>
        </div>
        <div class="overflow-auto">
            <div class="menu mt-1">
                <ul class="px-0 m-0">
                    <li v-for="user in users" :key="user.index">
                        <b-list-group-item
                            class="list-group-item d-flex justify-content-between gap-2 flex-row align-items-start align-items-md-center"
                        >
                            <div class="d-flex align-items-center">
                                <b-avatar class="mr-2" :size="30" badge badge-variant="success"
                                          v-if="user.status == 'pending'"></b-avatar>
                                <b-dropdown
                                                :id="'user-action-dropdown-status' + user.id"
                                                text=""
                                                class=""
                                                right
                                                v-if="user.is_approved == 1 && user.status == 'assigned'"
                                            >
                                            <template #button-content>
                                                <b-avatar class="mr-2" :size="30" badge badge-variant="warning"
                                                v-if="user.status == 'assigned'"></b-avatar>
                                            </template>
                                            <b-dropdown-item @click="handleRejectModal(user)">{{ $t('title.unassign') }}</b-dropdown-item>
                                </b-dropdown>
                                <div v-else>
                                    <b-avatar class="mr-2" :size="30" badge badge-variant="warning"
                                            v-if="user.status == 'assigned'">
                                    </b-avatar>
                                </div>
                                <b-avatar class="mr-2" :size="30" badge badge-variant="danger"
                                          v-if="user.status == 'reject'"></b-avatar>
                                <b-avatar class="mr-2" :size="30" badge badge-variant="info"
                                          v-if="user.status == 'completed'"></b-avatar>
                                <span class="me-auto"><a href="#" @click.prevent="openUserDetailModal(user)">{{ ( user.user || {} ).full_name }}</a></span>
                            </div>
                            <div class="button-group ms-md-auto d-flex gap-1 align-items-center">
                                <VolunteerDropdown v-if="user.status == 'pending' || user.status == 'reject' || user.status == 'assigned'" :eventId="eventId"  :userRequestDetail="user"  @open-modal="openModal" />
                            </div>
                        </b-list-group-item>
                    </li>
                </ul>
            </div>
        </div>

        <VolunteerAssignWithDetailModal ref="volunteerDetailModal" @assignVolunteer="handleAssignVolunteer"/>
        <UserDetailModel ref="userDetailModal"/>
        <UserInfoModal ref="userInfoModal"/>
        <UserRejectRequestModal ref="userRejectRequestModal"  @refreshUserList="handleRefreshUsersList"/>
    </div>
</template>

<script>
import VolunteerDropdown from "@/components/VolunteerDropdown";
import UserDetailModel from "@/view/pages/manager/events/modal/UserDetailModel";
import VolunteerAssignWithDetailModal from "@/view/pages/manager/events/modal/VolunteerAssignWithDetailModal";
import UserRejectRequestModal from "@/view/pages/manager/events/modal/UserRejectRequestModal";
import UserInfoModal from "@/view/pages/manager/events/modal/UserInfoModal"
import {request} from "@/Util/Request";
import Error from "@/Util/Error";

export default {
    props: ['users', 'eventId'],
    components: {
        VolunteerDropdown,
        VolunteerAssignWithDetailModal,
        UserDetailModel,
        UserInfoModal,
        UserRejectRequestModal
    },
    data() {
        return {
            formErrors: new Error({}),
        };
    },
    methods: {
        openUserDetailModal(user) {
            this.$refs['userDetailModal'].handleToggleModal(user);
        },
        openModal({volunteer, userRequestId}) {
            this.$refs['volunteerDetailModal'].handleToggleModal(volunteer, userRequestId);
        },
        async handleAssignVolunteer({userRequestId, volunteerRequestId}) {
            try {
                const response = await request({
                    method: 'put',
                    url: `/event-user-requests/${userRequestId }`,
                    data: {
                        'volunteer_request_id': volunteerRequestId,
                    }
                });
                if(response) {
                    this.notifySuccessWithMsg(`${ this.$t('title.volunteer') } ${ this.$t('title.assignSuccessMessage') }`);
                    this.handleRefreshUsersList();
                    this.formErrors = new Error({});
                }
            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    if(this.formErrors.has('message')) {
                        this.notifyErrorWithMsg(this.formErrors.first('message'));
                    }
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        handleRefreshUsersList() {
            this.$emit('refreshUsers');
        },
        changeBadgeColor(row, color) {
            row.badge = color; // Dynamically update the badge color
        },
        handleRejectModal(user) {
            this.$refs['userRejectRequestModal'].handleToggleModal(user.id, 'reject');
        },
    },
};
</script>
