<template>
    <div class="main-block request-management">
        <!--        <div class="div col-12 p-0 d-flex align-items-start">-->
        <!--            <h4 class="">{{ $t('title.volunteesrsManagement') }}</h4>-->
        <!--        </div>-->
        <div class="scrollable-block">
            <div class="col-12 row m-0 p-0" style="min-height: 90%">
                <crud-table :columns="columns" :list-url="listUrl" ref="event-user-request-table"
                            v-on:refresh="resetFilter"
                            :filters="filters">
                    <template #filter-slot>
                        <b-col lg="3" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.event')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" @input="handleFilter" v-model="filters.event_id"
                                            class="form-input"
                                            :placeholder="$t('title.select')"
                                            :options="dropdowns.events" />
                            </b-form-group>
                        </b-col>
                        <b-col lg="3" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.status')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" @input="handleFilter" v-model="filters.status"
                                            class="form-input"
                                            :placeholder="$t('title.select')"
                                            :options="dropdowns.statuses" />
                            </b-form-group>
                        </b-col>
                    </template>
                    <template #cell(event)="{item}">
                        {{ ( item.event || {} ).name }}
                    </template>
                    <template #cell(volunteer)="{item}">
                        {{ ( item.volunteer || {} ).full_name }}
                    </template>
                    <template #cell(user)="{item}">
                        {{ ( item.user || {} ).full_name }}
                    </template>
                    <template #cell(status)="{item}">
                        {{ $t('title.' + item.status) }}
                        <span class="text-danger"
                              v-if="item.is_approved == 0 && !_.includes(['pending','closed'],item.status)">
                            ({{ $t('title.waitingForApproval') }})
                        </span>
                    </template>
                    <template #cell(last_updated_at)="{item}">
                        {{ $global.utcDateToLocalDate(item.updated_at) }}
                    </template>
                    <template #cell(actions)="{item}">
                        <b-button v-if="item.is_approved == 1 && item.volunteer  && item.status == 'assigned'"
                                  :title="$t('title.reject')"
                                  variant="outline-primary"
                                  class="mr-1" @click="handleModal(item,'reject');">
                            <b-icon icon="person-x" class="icon-style"></b-icon>
                        </b-button>
                        <b-button v-if="item.is_approved == 1 && item.volunteer  && item.status == 'assigned'"
                                  :title="$t('title.completed')"
                                  variant="outline-primary"
                                  class="mr-1" @click="handleModal(item,'completed');">
                            <b-icon icon="shield-check" class="icon-style"></b-icon>
                        </b-button>
                    </template>
                </crud-table>
            </div>
        </div>

        <b-modal
            id="event-user-request"
            :title="$t('title.confirmation')"
            :centered="true"
            cancel-variant="outline-primary"
            :ok-title="$t('title.yes')"
            :cancel-title="$t('title.no')"
            :hide-footer="true"
        >
            <b-form>
                <div class="row">
                    <div class="col-12">
                        <p v-if="formFields.status == 'reject'">
                            {{ $t('title.areYouSureWantToRejectRequest') }}
                        </p>
                        <p v-else>
                            {{ $t('title.areYouWantToCompleteRequest') }}
                        </p>
                    </div>
                    <div class="col-12 mb-3" v-if="formFields.status == 'reject'">
                        <label for="system_note">{{ $t('title.systemNotes') }}:</label>
                        <b-form-textarea
                            @input="resetErrors"
                            id="textarea"
                            v-model="formFields.system_note"
                            placeholder="Enter something..."
                            rows="3"
                            max-rows="6"
                        ></b-form-textarea>
                        <p v-if="formErrors.has('system_note')" class="text-danger">
                            {{ formErrors.first('system_note') }}
                        </p>
                    </div>
                    <div class="col-12">
                        <hr />
                    </div>
                    <div class="col-12 text-right">
                        <div class="btn-group">
                            <b-button variant="primary" :disabled="isModalSubmit" class="mr-2" @click="onSubmit">{{
                                    $t('title.yes')
                                }}
                            </b-button>
                            <b-button variant="outline-primary" @click="closeModal">{{ $t('title.no') }}</b-button>
                        </div>
                    </div>
                </div>
            </b-form>
        </b-modal>
    </div>
</template>

<script>

import {request} from "@/Util/Request";
import Error from "@/Util/Error";

const FILTER_FORM = {
    event_id: null,
    status: null,
};

const FORM_DATA = {
    status: null,
    id: null,
    system_note: null
};

const COLUMN_STATE = (self) => [
    {key: 'actions', label: self.$t('title.actions')},
    {key: 'status', label: self.$t('title.status'), sortable: true},
    {key: 'event', label: self.$t('title.event'), sortable: true},
    {key: 'user', label: self.$t('title.user'), sortable: true},
    // {key: 'volunteer', label: self.$t('title.volunteer'), sortable: true},
    {key: 'last_updated_at', label: self.$t('title.lastUpdatedAt'), sortable: true},
];

export default {
    data() {
        return {
            operation: '',
            listUrl: '/volunteer/event-user-requests',
            filters: {
                ...FILTER_FORM
            },
            dropdowns: {
                events: [],
                statuses: []
            },
            formFields: {...FORM_DATA},
            formErrors: new Error({}),
            isModalSubmit: false
        };
    },
    mounted() {
        this.handleFilter()
        this.getDropdowns();
    },
    methods: {
        async onSubmit() {
            try {
                this.isModalSubmit = true;
                const response = await request({
                    method: 'patch',
                    url: `/volunteer/event-user-requests/${this.formFields.id}`,
                    data: {
                        status: this.formFields.status,
                        system_note: this.formFields.system_note
                    }
                });

                if ( this.formFields.status == 'reject' ) {
                    this.notifySuccessWithMsg(this.$t('title.rejectRequestSuccessfully'));
                } else {
                    this.notifySuccessWithMsg(this.$t('title.eventHelpRequestCompletedSuccessfully'));
                }

                this.isModalSubmit = false;

                this.$bvModal.hide('event-user-request');

                this.$refs['event-user-request-table'].handleRefreshList();

            } catch (error) {
                this.isModalSubmit = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);

                    if ( this.formErrors.has('error') ) {
                        this.notifyErrorWithMsg(this.formErrors.first('error'));
                    }
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        closeModal() {
            this.$bvModal.hide('event-user-request');
            this.resetErrors();
            this.formFields = {...FORM_DATA};
        },
        handleModal(item, status) {
            this.resetErrors();

            this.formFields = {
                status: status,
                id: item.id,
                system_note: null
            };

            this.$bvModal.show('event-user-request');
        },
        handleFilter() {
            this.$refs['event-user-request-table'].handleFilter(this.filters);
        },
        resetFilter() {
            this.filters = {...FILTER_FORM};
        },
        resetErrors() {
            this.formErrors = new Error({});
        },
        async getDropdowns() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/events`,
                });

                const {events, statuses} = response;

                this.dropdowns.events = events;

                this.dropdowns.statuses = _.filter(statuses, (i) => i.id !== 'pending').map((i) => ( {
                    ...i,
                    label: this.$t('title.' + i.id), // Use a function or predefined translation
                } ));

            } catch (error) {
            }
        },
    },
    computed: {
        columns() {
            return COLUMN_STATE(this);
        }
    }
};
</script>
