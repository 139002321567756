import {request} from "@/Util/Request";
import _ from "lodash";

export default {
    data() {
        return {
            dropdowns: {
                addresses: {
                    arrival_address: [],
                    residential_address: [],
                    origin_address: [],
                    location: [],
                    address: []
                }
            },
        };
    },
    created() {
        this.debouncedSearch = _.debounce(this.search, 2000);
    },
    methods: {
        async onFocus(key) {
            Object.keys(this.dropdowns.addresses).forEach(key => {
                this.dropdowns.addresses[key] = [];
            });
            if ( _.isEmpty(this.dropdowns.addresses[key]) ) {
                this.dropdowns.addresses[key] = [
                    {
                        id: "default",
                        label: this.$t('title.typeToSearch'),
                    }
                ];

                if ( this.formFields && this.formFields[key] ) {
                    await this.search(key)
                }
            }
        },
        handleBlur(event) {
            setTimeout(() => {
                const element = event.target.parentElement;
                const children = Array.from(element.childNodes);

                children.forEach((child) => {
                    if (
                      child.nodeType === 1 &&
                      child.matches('ul.autocomplete-list') &&
                      child.children.length > 0
                    ) {
                        Array.from(child.children).forEach((li) => {
                            li.remove();
                        });
                    }
                });
            }, 500);
        },
        async search(key) {
            console.log(this.formFields[key])
            if ( key ) {
                try {
                    const response = await request({
                        method: 'post',
                        url: `dropdowns/places`,
                        data: {
                            text: this.formFields[key]
                        }
                    });

                    const {results} = response;

                    this.dropdowns.addresses[key] = _.compact(_.map(results, (i) => {
                        const {position, address} = i;

                        // Only include results that have a municipality
                        if ( !address.municipality ) {
                            return null;
                        }

                        const name = _.replace(
                          _.trim(
                            ( address.streetNumber ? address.streetNumber + ' ' : '' ) +
                            ( address.streetName || '' ) ||
                            address.municipalitySubdivision ||
                            address.municipality ||
                            address.countrySubdivision ||
                            address.country
                          ),
                          /,/g,
                          '-'
                        );

                        const state = address.countrySubdivision + ( address.countrySubdivisionCode ? `/${address.countrySubdivisionCode}` : '' );
                        const municipality = address.municipality || '';
                        const country = address.country || '';

                        return {
                            id: `${name}, ${country}, ${state}, ${municipality}, ${position.lat}, ${position.lon}`,
                            label: `${name}, ${country}, ${state}, ${municipality}, ${position.lat}, ${position.lon}`
                        };
                    }));


                } catch (error) {
                    console.log(error)
                    return [];
                }
                return [];
            }
        },
        selectSuggestion(suggestion, key) {
            this.formFields[key] = suggestion.label;
            this.dropdowns.addresses[key] = [];
        },
    }
};
