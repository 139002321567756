<template>
    <div>
        <b-modal v-model="isOpenModal" id="userDeleteModal" :hide-header="true"
                 :hide-footer="true">
            <div class="col-12">
                <p>{{ $t('title.restoreUserMessage') }}</p>
            </div>
            <div class="modal-footer d-flex mr-2 gap-2">
                <button type="button" v-if="totalIntreatmentRequest == 0" :disabled="isSubmit"
                        class="btn main-btn col m-0" @click="handleSubmitRequest">
                    {{ $t('title.yes') }}
                </button>
                <button type="button" v-else :disabled="((formFields.volunteer_id) ? false : true) || isSubmit"
                        class="btn main-btn col m-0" @click="handleSubmitRequest">
                    {{ $t('title.yes') }}
                </button>
                <button type="button" class="btn sec-btn col m-0" @click="cancelModal">
                    {{ $t('title.cancel') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {request} from "@/Util/Request";
import Error from "@/Util/Error";

const FORM_STATE = {
    volunteer_id: null,
    event_volunteer_id: null,
    manager_id: null,
    event_user_request_id: null
};

export default {
    data() {
        return {
            isOpenModal: false,
            statusCounts: [],
            userId: null,
            formErrors: new Error({}),
            volunteers: [],
            managers: [],
            userAssignedEvents: [],
            totalIntreatmentRequest: 0,
            totalManagerActiveEvents: 0,
            totalAssignedEvents: 0,
            formFields: {...FORM_STATE},
            isSubmit: false
        };
    },
    methods: {
        async handleToggleModal(id) {
            this.refreshModal();
            this.userId = id;
            this.isOpenModal = !this.isOpenModal;
        },
        async handleSubmitRequest() {
            try {
                this.isSubmit = true;
                const response = await request({
                    method: 'post',
                    url: `/admin/users/restore/${this.userId}`,
                });
                this.notifySuccessWithMsg(`User ${this.$t('title.restoredSuccessMsg')}`);
                this.$emit('refreshTable');
                setTimeout(() => {
                    this.isSubmit = false;
                    this.cancelModal();
                }, 1000);
            } catch (error) {
                this.isSubmit = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }

        },
        cancelModal() {
            this.isOpenModal = false;
        },
        refreshModal() {
            this.totalIntreatmentRequest = 0;
            this.statusCounts = [];
            this.formErrors = new Error({});
            this.formFields = {...FORM_STATE};
        }
    }
};
</script>
