<template>
    <div>
        <b-modal v-model="isOpenModal" id="userAssignPermissionModal" :hide-header="true"
                 :hide-footer="true">
            <div class="col-12" v-if="permissions.length > 0">
                <table class="table">
                    <thead>
                        <tr>
                            <th>{{ $t('title.region') }}</th>
                            <th>{{ $t('title.country') }}</th>
                            <th></th>
                        </tr>
                    </thead>
                    <tbody>
                        <tr v-for="permission in permissions" :key="permission.id">
                            <td>{{ permission.state }}</td>
                            <td>{{ permission.country }}</td>
                            <td>
                                <b-button variant="outline-primary" @click="deletePermission(permission.id)">
                                    <b-icon icon="trash" class="icon-style"></b-icon>
                                </b-button>
                            </td>
                        </tr>
                    </tbody>
                </table>
            </div>
            <div class="col-12">
                <h5>{{ $t('title.addPermissionToAnotherArea') }}</h5>
                <b-form @submit.prevent="handleSubmitRequest" class="col-12 col-md-12 mx-auto p-0">
                    <div class="mb-3">
                        <label for="type">{{ $t('title.selectCountry') }}</label>
                        <div class="d-flex">
                            <treeselect :multiple="true" v-model="formFields.country_ids" :options="countries"
                                        :placeholder="$t('title.select')"
                                        :clearOnSelect="true"
                                        id="type"></treeselect>
                            <b-button
                                type="button"
                                variant="primary"
                                class="btn sec-btn small-btn btn-primary rounded border-0 ml-2"
                                @click="getStates"
                            >
                                {{ $t('title.search') }}
                            </b-button>
                        </div>
                        <b-form-invalid-feedback
                            :state="!formErrors.has('country_ids')">
                            {{ formErrors.first('country_ids') }}
                        </b-form-invalid-feedback>
                    </div>
                    <div class="mb-3">
                        <label for="state">{{ $t('title.selectRegion') }}</label>
                        <treeselect :multiple="true" v-model="formFields.state_ids" :options="states"
                                    :placeholder="$t('title.select')"
                                    :clearOnSelect="true"
                                    id="state"></treeselect>
                        <b-form-invalid-feedback :state="!formErrors.has('state_ids')">
                            {{ formErrors.first('state_ids') }}
                        </b-form-invalid-feedback>
                    </div>
                    <div class="modal-footer d-flex mr-2 gap-2">
                        <button type="submit" :disabled="(formFields.state_ids.length > 0 ? false : true) || isSubmit"
                                class="btn main-btn col m-0">
                            <i class="fa fa-spin fa-spinner" v-if="isSubmit"></i> {{ $t('title.save') }}
                        </button>
                        <button type="button" class="btn sec-btn col m-0" @click="cancelModal">
                            {{ $t('title.cancel') }}
                        </button>
                    </div>
                </b-form>
            </div>
        </b-modal>

        <DeleteConfirmationModal ref="deleteConfirmationModal" @refresh="refreshDeleteConfirmation" />

    </div>
</template>

<script>
import {request} from "@/Util/Request";
import Error from "@/Util/Error";
import DeleteConfirmationModal from "@/components/DeleteConfirmationModal";

const FORM_STATE = {
    user_id: null,
    state_ids: [],
    country_ids: []
}

export default {
    components: {DeleteConfirmationModal},
    data() {
        return {
            isOpenModal: false,
            userId: null,
            formFields: {...FORM_STATE},
            formErrors: new Error({}),
            isSubmit: false,
            countries: [],
            country_ids: [],
            state_ids: [],
            states: [],
            permissions: []
        };
    },
    methods: {
        refreshDeleteConfirmation() {
            if ( this.formFields.user_id ) {
                this.getRegionPermissions(this.formFields.user_id);
            }
            this.handleRefreshTable()
        },
        async handleToggleModal(id) {
            this.formFields.country_ids = [];
            this.formFields.state_ids = [];
            this.permissions = [];

            this.formFields.user_id = id;
            this.isOpenModal = !this.isOpenModal;
            this.getCountries();
            this.getRegionPermissions(id);
        },
        async getStates() {
            this.states.length = 0;
            if ( this.formFields.country_ids.length > 0 ) {
                try {
                    let queryParams = new URLSearchParams();

                    this.formFields.country_ids.forEach(countryId => {
                        queryParams.append('filters[country_ids][]', countryId);
                    });

                    const response = await request({
                        method: 'get',
                        url: `/states?${queryParams.toString()}`,
                    });

                    let {data} = response;

                    _.map(_.uniqBy(data, 'label'), (i) => {
                        this.states.push({id: i.label, label: i.label})
                    })

                } catch (error) {
                    this.notifyError();
                }
            }
        },
        async getCountries() {
            this.countries = []
            this.states = []

            try {
                const response = await request({
                    method: 'get',
                    url: `countries`,

                });

                let {data} = response;
                this.countries = _.chain(data)
                    .filter(i => i.label && i.label.trim() !== '')
                    .uniqBy('label')
                    .map(i => ( {
                        id: i.label,
                        label: i.label
                    } ))
                    .value();
            } catch (error) {
                this.notifyError();
            }
        },
        async getRegionPermissions(userId) {
            try {
                const response = await request({
                    method: 'get',
                    url: `admin/user/region-permissions?filters[user_id]=${userId}`,
                });

                let {data} = response;
                this.permissions = data;
            } catch (error) {
                this.notifyError();
            }
        },
        async handleSubmitRequest() {
            try {
                this.isSubmit = true;
                const response = await request({
                    method: 'post',
                    url: `admin/user/region-permissions`,
                    data: this.formFields
                });
                if ( response ) {
                    this.notifySuccessWithMsg(`${this.$t('title.updatePermissionMsg')}`);
                    this.$emit('refreshTable');
                    setTimeout(() => {
                        this.isSubmit = false;
                        this.cancelModal();
                    }, 1000);

                }
                this.formErrors = new Error({});

            } catch (error) {
                this.isSubmit = false;
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                }
            }

        },
        async deletePermission(id) {
            this.$refs['deleteConfirmationModal'].openModal(`admin/user/region-permissions/${id}`)
        },
        cancelModal() {
            this.isOpenModal = false;
            this.formErrors = new Error({});
            this.formFields = {...FORM_STATE};
            this.permissions = [];
            this.formFields.country_ids = [];
            this.formFields.state_ids = [];
            this.states = [];
        },
        handleRefreshTable() {
            this.$emit('refreshTable');
        },
    }
};
</script>
