<template>
    <div class="request-management">
        <div class="div col-12 p-0 d-flex align-items-start">
            <h4 class="">{{ $t('title.pendingApplicationUserRequestManagement') }}</h4>
        </div>
        <div class="scrollable-block">
            <div class="col-12 row m-0 p-0" style="min-height: 90%">
                <crud-table :should-show-search-box="false" :columns="columns" :list-url="listUrl"
                            ref="application-table" v-on:refresh="resetFilter">
                    <template #filter-slot>
                        <b-col lg="4" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.category')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" @input="handleFilter" v-model="filters.categories"
                                            class="form-input"
                                            :placeholder="$t('title.select')"
                                            :options="dropdowns.categories" />
                            </b-form-group>
                        </b-col>
                        <b-col lg="4" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.status')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" @input="handleFilter" v-model="filters.statuses"
                                            class="form-input"
                                            :placeholder="$t('title.select')"
                                            :options="dropdowns.statuses" />
                            </b-form-group>
                        </b-col>
                    </template>
                    <template #cell(label_priority)="{item}">
                        <span :class="getPriorityClass(item.label_priority)"
                              v-if="item.expiration_date">

                                {{ getLabel(item.label_priority) }}
                        </span>
                    </template>
                    <template #cell(status)="{item}">
                        {{ $t('title.' + _.camelCase(item.status)) }}
                    </template>
                    <template #cell(description)="{item}">
                        <div class="three-lines" v-b-popover.hover.top="item.description">
                            {{ item.description }}
                        </div>
                    </template>
                    <template #cell(destination_address)="{item}">
                        {{ ( item.origin_address ) ?? ( item.user || {} ).residential_address }}
                    </template>
                    <template #cell(origin_address)="{item}">
                        {{ ( item.destination_address ) ?? ( item.user || {} ).arrival_address }}
                    </template>
                    <template #cell(category)="{item}">
                        {{ ( item.category || {} ).name }}
                    </template>
                    <template #cell(name)="{item}">
                        {{ ( item.user || {} ).full_name }}
                    </template>
                    <template #cell(phone)="{item}">
                        {{ ( item.user || {} ).phone }}
                    </template>
                    <template #cell(email)="{item}">
                        {{ ( item.user || {} ).email }}
                    </template>
                    <template #cell(disability)="{item}">
                        {{ ( item.user.disability || {} ).name }}
                    </template>
                    <template #cell(system_notes)="{item}">
                        {{ ( item || {} ).system_notes }}
                    </template>
                    <template #cell(creation_date)="{item}">
                        {{ $global.utcDateToLocalDate(item.created_at) }}
                    </template>
                    <template #cell(validity)="{item}">
                        {{ item.expiration_date }}
                    </template>
                    <template #cell(actions)="{item}">
                        <b-button
                            v-if="item.status == 'open'"
                            v-b-popover.hover.top="$t('title.volunteers')"
                            variant="outline-primary"
                            class="mr-1" @click="assignVolunteer(item.id)">
                            <b-icon icon="people-fill" class="icon-style"></b-icon>
                        </b-button>
                    </template>
                </crud-table>
            </div>
        </div>
        <AssignFreeVolunteerModal ref="assignFreeVolunteerModal" @refreshTable="refreshTable" />
    </div>
</template>

<script>

import {request} from "@/Util/Request";
import AssignFreeVolunteerModal from "@/view/auth/dashboard/admin/modal/AssignFreeVolunteerModal"

const FILTER_FORM = {
    roles: null,
    statuses: null
};

const COLUMN_STATE = (self) => [
    {key: 'category', label: self.$t('title.category'), sortable: true},
    {key: 'status', label: self.$t('title.status'), sortable: true},
    {key: 'label_priority', label: self.$t('title.urgency'), sortable: true},
    {key: 'description', label: self.$t('title.description'), sortable: true},
    {key: 'validity', label: self.$t('title.validity'), sortable: true},
    {key: 'creation_date', label: self.$t('title.creationDate'), sortable: true},
    {key: 'name', label: self.$t('title.fullName'), sortable: true},
    {key: 'phone', label: self.$t('title.phone'), sortable: true},
    {key: 'email', label: self.$t('title.email'), sortable: true},
    {key: 'disability', label: self.$t('title.disability'), sortable: true},
    {key: 'destination_address', label: self.$t('title.residentialAddress'), sortable: true},
    {key: 'origin_address', label: self.$t('title.currentAddress'), sortable: true},
    {key: 'actions', label: self.$t('title.actions')},
];

export default {
    data() {
        return {
            operation: '',
            listUrl: '/admin/dashboard/pending-applications',
            filters: {
                ...FILTER_FORM
            },
            dropdowns: {
                categories: [],
                statuses: []
            }
        };
    },
    components: {
        AssignFreeVolunteerModal,
    },
    mounted() {
        this.getDropdowns();
    },
    methods: {
        getPriorityClass(priority) {
            return {
                'text-dark': priority == 0,
                'text-info': priority == 1,
                'text-danger': priority == 3,
                'text-warning': priority == 2,
            };
        },
        getLabel(priority) {
            return priority == '1' ? this.$t('title.low') : ( priority == '2' ? this.$t('title.medium') : ( priority == '0' ) ? this.$t('title.expired') : this.$t('title.high') );
        },
        assignVolunteer(applicationId) {
            this.$refs['assignFreeVolunteerModal'].handleToggleModal(applicationId);
        },
        refreshTable() {
            this.$refs['application-table'].handleRefreshList();
        },
        handleFilter() {
            this.$refs['application-table'].handleFilter(this.filters);
        },
        resetFilter() {
            this.filters = {...FILTER_FORM};
        },
        async getDropdowns() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/application`,
                });

                const {categories, statuses} = response;

                this.dropdowns.categories = categories;
                this.dropdowns.statuses = _.map(statuses, (i) => {
                    return {
                        id: i.id,
                        label: this.$t(`title.${_.camelCase(i.label)}`)
                    };
                });

            } catch (error) {
            }
        }
    },
    computed: {
        columns() {
            return COLUMN_STATE(this);
        }
    }
};
</script>
