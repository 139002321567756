<template>
    <b-sidebar id="sidebar-1" :visible="true" aria-hidden="true" no-header :backdrop="false" :class="sidebarClass">
        <b-button
            @click="$emit('toggle-sidebar')"
            variant="link"
            class="d-flex ms-auto p-0 mr-2 shadow-none"
            id="toggleSidebar"
        >
            <div class="animated-hamburger open">
                <span></span><span></span><span></span><span></span>
            </div>
        </b-button>
        <div class="overflow-auto">
            <div class="menu mt-1">
                <ul class="px-0 m-0" v-if="!isUnAuthorized">
                    <li v-if="!$global.hasRole('volunteer')">
                        <router-link :to="{ name:'Dashboard' }" class="menu-item" v-if="!$global.hasRole('admin')">
                            <span class="menu-icon">
                            <b-icon v-if="!$global.hasRole('user')" :icon="`speedometer2`"></b-icon>
                                <b-icon v-else icon="card-checklist"></b-icon>
                            </span>
                            <span class="text">{{
                                    $global.hasRole('user') ? this.$t('title.myHelpRequest') : this.$t('title.dashboard')
                                }}</span>
                        </router-link>
                        <router-link :to="{ name:'AdminDashboard' }" class="menu-item" v-else>
                            <span class="menu-icon">
                             <svg xmlns="http://www.w3.org/2000/svg" width="16"
                                  height="16" fill="currentColor"
                                  class="bi bi-person-raised-hand" viewBox="0 0 16 16">  <path
                                 d="M6 6.207v9.043a.75.75 0 0 0 1.5 0V10.5a.5.5 0 0 1 1 0v4.75a.75.75 0 0 0 1.5 0v-8.5a.25.25 0 1 1 .5 0v2.5a.75.75 0 0 0 1.5 0V6.5a3 3 0 0 0-3-3H6.236a1 1 0 0 1-.447-.106l-.33-.165A.83.83 0 0 1 5 2.488V.75a.75.75 0 0 0-1.5 0v2.083c0 .715.404 1.37 1.044 1.689L5.5 5c.32.32.5.754.5 1.207" />  <path
                                 d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" /></svg>
                            </span>
                            <span class="text">{{ this.$t('title.openHelpRequests') }}</span>
                        </router-link>
                    </li>
                    <li v-for="item in _.filter(menuItems,(i) => !i.roles || (i.roles && _.includes(i.roles, user && user.role)))"
                        :key="item.id" @click="handleLinkClick">
                        <router-link :to="{ name: `${item.id}` }" class="menu-item">
                            <span class="menu-icon">
                            <svg v-if="item.id == 'NewHelpRequest'" xmlns="http://www.w3.org/2000/svg" width="16"
                                 height="16" fill="currentColor"
                                 class="bi bi-person-raised-hand" viewBox="0 0 16 16">  <path
                                d="M6 6.207v9.043a.75.75 0 0 0 1.5 0V10.5a.5.5 0 0 1 1 0v4.75a.75.75 0 0 0 1.5 0v-8.5a.25.25 0 1 1 .5 0v2.5a.75.75 0 0 0 1.5 0V6.5a3 3 0 0 0-3-3H6.236a1 1 0 0 1-.447-.106l-.33-.165A.83.83 0 0 1 5 2.488V.75a.75.75 0 0 0-1.5 0v2.083c0 .715.404 1.37 1.044 1.689L5.5 5c.32.32.5.754.5 1.207" />  <path
                                d="M8 3a1.5 1.5 0 1 0 0-3 1.5 1.5 0 0 0 0 3" /></svg>

                            <b-icon v-if="item.id != 'NewHelpRequest'" :icon="item.icon"></b-icon>
                            </span>
                            <span class="text">{{ item.text }}</span>
                        </router-link>
                    </li>
                    <li v-if="!this.isExpanded && $global.hasRole('admin')">
                        <b-dropdown
                            boundary="window"
                            :popper-opts="{ positionFixed: true, modifiers: { offset: '100%' } }"
                            class="submenu-dropdown"
                        >
                            <template #button-content>
                                <span class="menu-icon">
                                  <b-icon :icon="'gear'"></b-icon>
                                </span>
                            </template>
                            <b-dropdown-item
                                v-for="(item,itemIndex) in submenuItems"
                                :key="itemIndex"
                                :to="{ name: item.id }"
                                class="menu-item-submenu"
                            >
                                <span class="menu-icon">
                                    <b-icon
                                        v-if="item.id == `NewHelpRequest`"
                                        :icon="'gear'" class=""></b-icon>
                                    <b-icon v-else :icon="item.icon"></b-icon>
                                </span>
                                <span class="text">{{ item.text }}</span>
                            </b-dropdown-item>
                        </b-dropdown>
                    </li>
                    <li v-if="this.isExpanded && $global.hasRole('admin')">
                        <b-button
                            v-b-toggle.settings-collapse
                            class="menu-item d-flex align-items-center"
                            variant="link"
                        >
                              <span class="menu-icon">
                                <b-icon :icon="'gear'" class=""></b-icon>
                              </span>
                            <span class="text">{{ this.$t('title.settings') }}</span>
                        </b-button>
                        <b-collapse id="settings-collapse">
                            <ul class="submenu">
                                <li v-for="item in submenuItems" :key="item.id">
                                    <router-link :to="{ name: item.id }" class="menu-item">
                                        <span class="menu-icon">
                                            <b-icon :icon="item.icon"></b-icon>
                                        </span>
                                        <span class="text">{{ item.text }}</span>
                                    </router-link>
                                </li>
                            </ul>
                        </b-collapse>
                    </li>
                </ul>
            </div>
            <div class="my-1">
                <hr class="m-0 opacity-50" />
            </div>
            <div class="menu">
                <ul class="px-0">
                    <li v-for="item in menuItems2" :key="item.id" @click="handleLinkClick">
                        <router-link :to="{ name: `${item.id}` }" class="menu-item">
                            <span class="menu-icon">
                            <b-icon v-if="item.id != 'accessibility-menu'" :icon="item.icon"></b-icon>
                                <i class="fa fa-universal-access" v-if="item.id == 'accessibility-menu'"></i>
                            </span>
                            <span class="text">{{ item.text }}</span>
                        </router-link>
                    </li>
                </ul>
            </div>
        </div>
    </b-sidebar>
</template>

<script>
import {BButton, BSidebar, BIcon} from 'bootstrap-vue';
import {mapGetters, mapState} from "vuex";

export default {
    components: {
        BButton,
        BSidebar,
        BIcon,
    },
    data() {
        return {};
    },
    props: {
        isExpanded: {
            type: Boolean,
            default: true,
        },
    },
    mounted() {
        this.removeAriaHidden();
        const sidebar = document.getElementById('sidebar-1');
        if ( sidebar ) {
            const observer = new MutationObserver(() => {
                this.removeAriaHidden();
            });
            observer.observe(sidebar, {attributes: true, attributeFilter: ['aria-hidden']});
            this.$on('hook:beforeDestroy', () => observer.disconnect());
        }
    },
    computed: {
        menuItems() {
            return [
                {
                    id: 'OpenVolunteering',
                    text: this.$t('title.openVolunteering'),
                    icon: 'envelope-open',
                    roles: ['volunteer']
                },
                {
                    id: 'MyVolunteerBasket',
                    text: this.$t('title.myVolunteerBasket'),
                    icon: 'basket',
                    roles: ['volunteer']
                },
                {
                    id: 'NewHelpRequest',
                    text: this.$t('title.newHelpRequest'),
                    icon: 'question-circle',
                    roles: ['user']
                },
                {
                    id: 'AdminCoverageMap',
                    text: this.$t('title.coverageMap'),
                    icon: 'map',
                    roles: ['admin']
                },
                {
                    id: 'adminOrganization',
                    text: this.$t('title.organizations'),
                    icon: 'peace-fill',
                    roles: ['admin']
                },
                {
                    id: 'AdminUserManagement',
                    text: this.$t('title.userManagement'),
                    icon: 'people-fill',
                    roles: ['admin']
                },
                {
                    id: 'AdminVolunteersManagement',
                    text: this.$t('title.helpRequests'),
                    icon: 'card-list',
                    roles: ['admin']
                },
                {
                    id: 'ManagerUserManagement',
                    text: this.$t('title.userManagement'),
                    icon: 'people-fill',
                    roles: ['manager']
                },
                {
                    id: 'ManagerVolunteersManagement',
                    text: this.$t('title.helpRequests'),
                    icon: 'card-list',
                    roles: ['manager']
                },
                {
                    id: 'managerEvent',
                    text: this.$t('title.events'),
                    icon: 'tag',
                    roles: ['manager']
                },
                {
                    id: 'adminEvent',
                    text: this.$t('title.events'),
                    icon: 'tag',
                    roles: ['admin']
                },
                {
                    id: 'AdminNotificationLog',
                    text: this.$t('title.notificationLog'),
                    icon: 'bell',
                    roles: ['admin']
                },
                {
                    id: 'NotificationLog',
                    text: this.$t('title.notificationLog'),
                    icon: 'bell',
                    roles: ['volunteer', 'manager']
                },
                {
                    id: 'contactUs',
                    text: this.$t('title.contactUs'),
                    icon: 'envelope',
                    roles: ['manager', 'volunteer', 'user']
                },
                {
                    id: 'adminContactUs',
                    text: this.$t('title.contactUs'),
                    icon: 'envelope',
                    roles: ['admin']
                },
                {
                    id: 'AboutUs',
                    text: this.$t('title.aboutUs'),
                    icon: 'people',
                    roles: ['manager', 'volunteer', 'user']
                },
                {
                    id: 'AdminAboutUs',
                    text: this.$t('title.aboutUs'),
                    icon: 'people',
                    roles: ['admin']
                },
            ];
        },
        menuItems2() {
            return [
                {id: 'instructions-menu', text: this.$t('title.instructions'), icon: 'info-circle'},
                {id: 'regulations-menu', text: this.$t('title.regulations'), icon: 'file-earmark-medical'},
                {id: 'accessibility-menu', text: this.$t('title.accessibilityStatement'), icon: 'accessibility-menu'},
            ];
        },
        submenuItems() {
            return [
                {
                    id: 'adminAutoAssign',
                    text: this.$t('title.autoAssign'),
                    icon: 'arrow-repeat',
                    roles: ['admin']
                },
                // {
                //     id: 'adminTasks',
                //     text: this.$t('title.tasks'),
                //     icon: 'list-task',
                //     roles: ['admin']
                // },
                {
                    id: 'adminCategory',
                    text: this.$t('title.categories'),
                    icon: 'grid-3x3-gap',
                    roles: ['admin']
                },
            ];
        },
        sidebarClass() {
            return this.isExpanded
                ? 'custom-sidebar full-width d-flex'
                : 'small-width custom-sidebar d-flex';
        },
        ...mapState(['isUnAuthorized', 'user']),
    },
    methods: {
        removeAriaHidden() {
            const sidebar = document.getElementById('sidebar-1');
            if ( sidebar ) {
                sidebar.removeAttribute('aria-hidden');
            }
        },
        toggleSidebar() {
            this.$emit('toggle-sidebar');
            document.getElementById('sidebar-1').removeAttribute('aria-hidden');
        },
        handleLinkClick() {
            if ( window.innerWidth <= 768 ) {
                this.$emit('toggle-sidebar');
            }
        },
    },

};
</script>
