<template>
    <div>
        <b-button :variant="(volunteerRequestDetail.assigned_user.is_approved) ? 'warning' : 'danger'"
                  class="col-12 col-md-auto common-button small-btn"
                  v-if="volunteerRequestDetail.status == 'assigned'"
                  @click="triggerModal(volunteerRequestDetail.assigned_user)">
                    {{(volunteerRequestDetail.assigned_user.is_approved) ?  $t('title.assigned') : $t('title.pending')}}
        </b-button>
        <b-dropdown
            :id="'volunteer-management-dropdown-' + volunteerRequestDetail.id"
            text=""
            class="volunteer-management-dropdown"
            v-else
        >
            <template #button-content>
                <b-button variant="primary" class="col-12 col-md-auto main-btn small-btn" @click="pendingUserLists">
                    {{$t('title.assign')}}
                </b-button>
            </template>
            <b-dropdown-item v-if="pendingUsers.length == 0">
                {{ $t('title.notFoundUser')}}
            </b-dropdown-item>
            <b-dropdown-item v-for="user in pendingUsers" @click="triggerModal(user)" :key="user.index">
                {{ user.user.full_name }}
            </b-dropdown-item>
        </b-dropdown>
    </div>
</template>

<script>
import { request } from "@/Util/Request";

export default {
    props: {
        eventId: {
            type: Number,
            required: true,
        },
        volunteerRequestDetail: {
            type: Object,
            required: true,
        }
    },
    data() {
        return {
            pendingUsers: []
        };
    },
    mounted() {
        this.pendingUserLists();
    },
    methods: {
        async pendingUserLists() {
            try {
                const response = await request({
                    method: 'get',
                    url: `/event-user-pending-requests?event_id=${ this.eventId }`
                });
                const { data } = response;
                this.pendingUsers = data;
            } catch (error) {
                this.notifyError();
            }
        },
        triggerModal(user) {
            this.$emit('open-modal', { user: user, volunteerRequestId: this.volunteerRequestDetail.id });
        },
    },
};
</script>
