<template>
    <div>
        <b-container>
            <b-row>
                <b-col sm="12" class="text-center mt-5 mb-5">
                    <b-button variant="primary"
                              v-if="!(without_volunteer > 0 || with_event > 0)"
                              :disabled="loading || loader || (without_volunteer > 0) || (with_event > 0)"
                              @click="handleSubmitRequest">
                        <div class="spinner-border spinner-border-sm" role="status" v-if="loading"></div>
                        {{ $t('title.clickHereForEmergency') }}
                    </b-button>
                    <b-card v-if="without_volunteer > 0 || with_event > 0" class="mt-3">
                        <b-card-title>
                            <p class="text-danger">
                                {{
                                    $t('title.requestInProgress', {
                                        time: $global.utcDateToLocalDate(time),
                                        status: ( volunteer ? $t('title.assigned') : $t('title.inProgress') )
                                    })
                                }}
                            </p>
                        </b-card-title>
                        <b-card-body>
                            <p v-if="volunteer && is_approved"><b>{{ $t('title.active') }} {{
                                    $t('title.volunteer')
                                }}: </b>{{
                                    volunteer
                                }}</p>
                            <b-button variant="danger" v-if="without_volunteer || with_event"
                                      @click="recordId ? handleCloseAssignedRequest(recordId) :  handleCloseRequest()"
                                      :disabled="loading || loader">
                                {{ $t('title.closeEmergencyRequest') }}
                            </b-button>
                        </b-card-body>
                    </b-card>
                </b-col>
            </b-row>
        </b-container>
        <!--        <emergency-history v-on:close="handleCloseAssignedRequest" ref="emergency-history" />-->
        <my-help-request v-on:close="handleCloseAssignedRequest" />
    </div>
</template>

<script>
import {request} from "@/Util/Request";
import {mapState, mapActions} from "vuex";
// import EmergencyHistory from "@/view/auth/dashboard/user/emergencyHistory";
import MyHelpRequest from "@/view/auth/dashboard/user/myHelpRequest"

export default {
    components: {
        // EmergencyHistory,
        MyHelpRequest
    },
    data() {
        return {
            without_volunteer: 0,
            with_event: 0,
            assigned_volunteer: 0,
            reject_volunteer: 0,
            time: null,
            loading: false,
            volunteer: null,
            recordId: null,
            is_approved: null
        };
    },
    mounted() {
        this.getDetail();
    },
    methods: {
        ...mapActions(['setLoader']),
        async handleSubmitRequest() {
            this.setLoader(true);
            try {
                const response = await request({
                    method: 'post',
                    url: `/event/requests`,
                });

                if ( response ) {
                    this.notifySuccessWithMsg(this.$t('title.sendSuccessRequestForEmergency'));

                    this.getDetail();
                    this.$refs['emergency-history'].refreshTable();
                }

                this.setLoader(false);
            } catch (error) {
                this.setLoader(false);
                if ( error.status == 422 ) {
                    const {data} = error;
                    if ( data.message ) {
                        this.notifyErrorWithMsg(data.message);
                    }
                }
            }
        }, async handleCloseRequest() {
            this.setLoader(true);

            try {
                const response = await request({
                    method: 'delete',
                    url: `/event/requests/emergency`,
                });

                if ( response ) {
                    await this.getDetail();
                    this.$refs['emergency-history'].closeModal();
                    this.$refs['emergency-history'].refreshTable();

                    this.setLoader(false);

                    this.notifySuccessWithMsg(this.$t('title.closeEmergencyRequestMessage'));
                }

            } catch (error) {
                this.setLoader(false);
            }
        },
        async handleCloseAssignedRequest(id) {
            this.setLoader(true);

            try {
                const response = await request({
                    method: 'delete',
                    url: `/event/requests/emergency/${id}`,
                });

                if ( response ) {
                    await this.getDetail();
                    // this.$refs['emergency-history'].closeModal();
                    // this.$refs['emergency-history'].refreshTable();

                    this.setLoader(false);

                    this.notifySuccessWithMsg(this.$t('title.closeEmergencyRequestMessage'));
                }

            } catch (error) {
                this.setLoader(false);
            }
        }, async getDetail() {
            try {
                const response = await request({
                    method: 'get',
                    url: `/event/requests/emergency`,
                });

                const {data} = response;

                this.without_volunteer = data.without_volunteer;
                this.with_event = data.with_event;
                this.assigned_volunteer = data.assigned_volunteer;
                this.reject_volunteer = data.reject_volunteer;
                this.time = data.time;
                this.volunteer = data.volunteer
                this.recordId = data.record
                this.is_approved = data.is_approved


            } catch (error) {
            }
        }
    },
    computed: {
        ...mapState(['loader'])
    }
};
</script>
