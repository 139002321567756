<template>
    <div class="main-block user-management">
        <div class="div col-12 p-0 d-flex align-items-start">
            <h4 class="">{{ $t('title.userManagement') }}</h4>
        </div>
        <div class="scrollable-block">
            <div class="col-12 row mt-3 m-0 p-0">
                <crud-table :columns="columns" :filters="filters" :list-url="listUrl" ref="users-table"
                            v-on:refresh="resetFilter"
                            v-on:rowClick="onRowClicked">
                    <template #filter-slot>
                        <b-col lg="2" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.status')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" @input="handleFilter" v-model="filters.status"
                                            :placeholder="$t('title.select')"
                                            class="form-input"
                                            :options="statuses" />
                            </b-form-group>
                        </b-col>
                        <b-col lg="2" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.type')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" @input="handleFilter" v-model="filters.roles"
                                            :placeholder="$t('title.select')"
                                            class="form-input"
                                            :options="dropdowns.roles" />
                            </b-form-group>
                        </b-col>
                        <b-col lg="2" class="mt-3 mt-md-0">
                            <b-form-group :label="$t('title.region')" label-for="type-select" class="mb-0">
                                <treeselect :multiple="false" @input="handleFilter" v-model="filters.state"
                                            :placeholder="$t('title.select')"
                                            class="form-input"
                                            :options="dropdowns.states" />
                            </b-form-group>
                        </b-col>
                    </template>
                    <template>
                        <b-button :disabled="selectedUsers.length == 0" variant="outline-primary"
                                  @click="openSendMessageModal">
                            <b-icon icon="cursor-fill"></b-icon>
                            {{ $t('title.sendingMessage') }}
                        </b-button>
                    </template>
                    <template #head(select)>
                        <b-form-checkbox
                            id="checkbox"
                            v-model="selectAll"
                            name="checkbox"
                            @change="selectAllUsers"
                        >
                        </b-form-checkbox>
                    </template>
                    <template #cell(select)="row">
                        <b-form-checkbox
                            v-if="row.item.status == 'Activated'"
                            :id="`checkbox-${row.item.id}`"
                            v-model="selectedUsers"
                            :name="`checkbox-${row.item.id}`"
                            :value="row.item.id"
                        >
                        </b-form-checkbox>
                    </template>
                    <template #cell(roles)="{item}">
                        {{ getRoleNames(item.roles) }}
                    </template>
                    <template #cell(is_wp)="{item}">
                        {{ ( item.is_phone ) ? 'Phone' : '' }}{{
                            ( item.is_phone && item.is_wp ) ? ',' : ''
                        }}{{ ( item.is_wp ) ? 'WhatsApp' : '' }}
                    </template>
                    <template #cell(disability_id)="{item}">
                        {{ ( item.disability || {} ).name }}
                    </template>
                    <template #cell(phone)="{item}">
                        <div dir="ltr">
                            {{ $global.displayPhoneNumber(item.phone) }}
                        </div>
                    </template>
                    <template #cell(email_verified_at)="{item}">
                        {{ $global.dateFormat(item.email_verified_at) }}
                    </template>
                    <template #cell(status)="{item}">
                        <b-button
                            v-if="item.deleted_at == null && user?.id !== item.id"
                            variant="outline-primary"
                            class="mr-1" @click="updateUserStatus(item.id, item.is_active, item.roles)">
                            {{ $t('title.' + _.camelCase(item.status)) }}
                        </b-button>
                        <span v-else :class="(item.status == 'Activated') ? 'text-success' : 'text-danger'"> {{
                                $t('title.' + _.camelCase(item.status))
                            }} </span>
                    </template>
                </crud-table>
            </div>
        </div>
        <SendMessageModal ref="sendMessageModal" @refreshTable="handleRefreshTable" />
        <UpdateUserStatusModal ref="updateUserStatusModal" @refreshTable="handleRefreshTable" />
        <UserDetailModal ref="userDetailModal" />

    </div>
</template>

<script>

import {request} from "@/Util/Request";
import {mapState} from "vuex";
import SendMessageModal from "@/view/pages/manager/users/modal/SendMessageModal";
import UpdateUserStatusModal from "@/view/pages/manager/users/modal/UpdateUserStatusModal";
import UserDetailModal from "@/view/pages/admin/modal/UserDetailModal";

const FILTER_FORM = {
    roles: null,
    user_ids: null,
    state: null,
    status: null
};

const COLUMN_STATE = (self) => [
    {key: 'select', label: '', sortable: false},
    {key: 'full_name', label: self.$t('title.fullName'), sortable: true},
    {key: 'phone', label: self.$t('title.phone'), sortable: true},
    {key: 'disability_id', label: self.$t('title.disability'), sortable: true},
    {
        key: 'is_notification_alert',
        label: self.$t('title.alerts'),
        formatter: (value, key, item) => {
            return value ? self.$t('title.yes') : self.$t('title.no');
        },
        sortable: true,
        sortByFormatted: true,
        filterByFormatted: true,
    },
    {key: 'status', label: self.$t('title.status')},
];

export default {
    data() {
        return {
            operation: '',
            listUrl: '/manager/users',
            filters: {
                ...FILTER_FORM
            },
            selectedUsers: [],
            selectAll: false,
            userLists: []
        };
    },
    async mounted() {
        await this.userStates();
        await this.users();
    },
    created() {
        if ( this.$route.query.user_ids ) {
            this.filters.user_ids = this.$route.query.user_ids;
        }
    },
    components: {
        SendMessageModal,
        UpdateUserStatusModal,
        UserDetailModal
    },
    methods: {
        onRowClicked(item) {
            this.$refs.userDetailModal.setItemData(item)
        },
        async userStates() {
            try {
                const response = await request({
                    method: 'get',
                    url: `manager/user/states`,
                });

                this.dropdowns.states = response.data;
                console.log(this.dropdowns.states);
            } catch (error) {
            }
        },
        openSendMessageModal() {
            this.$refs['sendMessageModal'].handleToggleModal(this.selectedUsers);
        },
        selectAllUsers() {
            if ( this.selectAll ) {
                this.selectedUsers = this.userLists.filter(user => user.status === 'Activated').map(user => user.id); // Select all user IDs
            } else {
                this.selectedUsers = []; // Deselect all users
            }
        },
        async roles() {
            try {
                const response = await request({
                    method: 'post',
                    url: `/dropdowns/roles`,
                });

                this.dropdowns.roles = response.map((role) => ( {
                    id: role.label,
                    label: role.label, // Optional transformation of value
                } ));

            } catch (error) {
                this.notifyError();
            }
        },
        updateUserStatus(id, status, roles) {
            this.$refs['updateUserStatusModal'].handleToggleModal(id, status, roles);
        },
        assignUserPermission(id) {
            this.$refs['userAssignPermissionModal'].handleToggleModal(id);
        },
        assignUserRole(id, roles) {
            this.$refs['userAssignRoleModal'].handleToggleModal(id, roles);
        },
        getRoleNames(roles) {
            return roles.map(role => role.name).join(', ');
        },
        handleRefreshTable() {
            this.$refs['users-table'].handleRefreshList();
            this.selectedUsers = [];
            this.selectAll = false;
            this.users();
        },
        handleFilter() {
            this.$refs['users-table'].handleFilter(this.filters);
        },
        resetFilter() {
            this.filters = {...FILTER_FORM};
        },
        async handleDelete(id, roles) {
            this.$refs['userDeleteModal'].handleToggleModal(id, roles);
        },
        async users() {
            try {
                const response = await request({
                    method: 'get',
                    url: '/manager/users',
                });
                this.userLists = response.data

            } catch (error) {
                this.notifyError();
            }
        },
        async handleBecomeAdmin(id) {
            if ( confirm('Are you want to assign admin role to this user?') ) {
                {
                    try {
                        const response = await request({
                            method: 'post',
                            url: `admin/users/become/admin/${id}`,

                        });

                        this.notifySuccessWithMsg(`Admin role assigned to user successfully`);
                        this.$refs['users-table'].handleRefreshList();

                    } catch (error) {
                        this.notifyError();
                    }
                }
            }

        },
    },
    computed: {
        statuses() {
            return [
                {
                    id: "activated",
                    label: this.$t('title.activated')
                },
                {
                    id: "deactivated",
                    label: this.$t('title.deactivated')
                },
                {
                    id: "deleted",
                    label: this.$t('title.deleted')
                }
            ]
        },
        dropdowns() {
            return this.roles()
        },
        columns() {
            return COLUMN_STATE(this);
        },
        ...mapState(['user'])
    }
};
</script>
