<template>
    <div>
        <b-modal v-model="isOpenModal" id="requestClosedModal"  :hide-header="true"
                 :hide-footer="true">
            <p>{{ $t('title.closeRequestMessage') }}</p>
            <div class="modal-footer d-flex mr-2 gap-2">
                <button type="button" class="btn main-btn col m-0" @click="handleSubmitRequest">{{ $t('title.yes') }}</button>
                <button type="button" class="btn sec-btn col m-0" @click="cancelModal">
                    {{ $t('title.cancel') }}
                </button>
            </div>
        </b-modal>
    </div>
</template>

<script>
import {request} from "@/Util/Request";
import Error from "@/Util/Error";

export default {
    data() {
        return {
            isOpenModal: false,
            applicationId: null,
            formErrors: new Error({}),
        };
    },
    methods: {
        handleToggleModal(id) {
            this.isOpenModal = !this.isOpenModal;
            this.applicationId = id;
        },
        async handleSubmitRequest() {
            try {
                this.cancelModal();
                const response = await request({
                    method: 'post',
                    url: `/admin/application/closed/${ this.applicationId }`,
                    data: {}
                });
                if(response) {
                    this.$emit('refresh');
                    this.formErrors = new Error({});
                    this.notifySuccessWithMsg(`${ this.$t('title.helpRequestClosedMessage') }`);
                }
            } catch (error) {
                if ( error.request && error.request.status && error.request.status === 422 ) {
                    this.formErrors = new Error(JSON.parse(error.request.responseText).errors);
                    return false;
                } else {
                    this.notifyError();
                }
            }
        },
        cancelModal() {
            this.isOpenModal = false;
        }
    }
};
</script>
